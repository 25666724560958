import { FC } from "react";

interface HandMoneyProps {
  className?: string;
}

export const HandMoney: FC<HandMoneyProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="66"
      height="66"
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="33.33" cy="33.33" r="29.37" fill="#DCEDFF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.8451 56.0928C35.546 58.173 38.5415 59.996 42.1567 61.3583C54.1377 57.3862 58.9443 48.2201 60.4707 44.5611L60.2969 44.5324C46.7831 42.2814 40.9877 38.5111 37.0535 28.8448C36.4126 27.4386 35.621 26.4151 34.4381 26.6161C33.203 26.8258 32.5156 27.6009 32.5156 28.8509C32.5156 28.8591 32.5155 28.8696 32.5154 28.8823C32.5122 29.2995 32.4902 32.145 34.6626 36.0087C33.8667 35.8625 33.1069 35.6399 32.3968 35.3041L32.8451 56.0928ZM31.1122 28.9866C31.6873 28.3445 31.9579 27.8824 32.0691 27.6574C32.0691 27.6574 32.6871 26.5335 32.2915 25.4537L30.6899 27.72L31.1122 28.9866Z"
        fill="#FFC8E9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.0857 25.823L40.5412 7.53905C41.2777 6.53692 42.9894 6.33636 44.087 7.06294L53.1012 13.0319C54.1988 13.7585 54.2664 15.1375 53.5294 16.1395L40.7159 34.01C39.9788 35.012 38.4921 35.2354 37.3947 34.5083L28.3805 28.5393C27.2835 27.8129 26.9906 26.4115 27.7271 25.4094"
        fill="black"
        fillOpacity="0.1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.4944 24.3169L40.3665 4.42139C41.1257 3.33096 42.8498 3.08278 43.9389 3.84216L52.8834 10.0806C53.9725 10.84 54.0141 12.3199 53.2543 13.4101L40.0353 32.8493C39.2754 33.9396 37.7771 34.2081 36.6881 33.4481L27.7436 27.2096C26.6551 26.4504 26.3876 24.9509 27.1469 23.8604"
        fill="#48CAD4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.5376 23.4454L40.7376 5.92912C41.3829 5.00225 42.6564 4.77459 43.5821 5.42007L51.185 10.7228C52.1107 11.3683 52.3381 12.6428 51.6922 13.5696L39.4927 31.086C38.8469 32.0127 37.5733 32.2409 36.6477 31.5949L29.0448 26.2922C28.1196 25.6469 27.8922 24.3723 28.5376 23.4454Z"
        fill="#8AE7F2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.6314 14.6355C42.3363 23.2228 52.2037 12.8003 51.5558 13.7258L39.6115 31.1173C38.9637 32.0427 37.6838 32.2689 36.7523 31.6217L29.1015 26.3089C28.1705 25.6623 27.94 24.3878 28.5873 23.4621C28.5873 23.4621 32.6277 17.4998 34.6314 14.6355Z"
        fill="#A2EBE2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.9978 21.4943C36.1323 20.194 35.6737 17.6268 36.974 15.7614C38.2743 13.8953 40.841 13.4366 42.7069 14.7375C44.5729 16.0379 45.0311 18.6044 43.7307 20.4704C42.4303 22.3364 39.8633 22.7945 37.9978 21.4943Z"
        fill="#48CAD4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.4954 11.5815L44.5065 10.131C44.8164 9.68616 45.4287 9.57682 45.8734 9.8872L45.9688 9.95333C46.4136 10.2637 46.5231 10.8755 46.2132 11.3204L45.202 12.7708C44.8917 13.2156 44.2799 13.325 43.835 13.0152L43.7392 12.9484C43.2945 12.638 43.1855 12.0264 43.4954 11.5815Z"
        fill="#48CAD4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.2049 28.4783L27.6753 5.85244C27.9193 4.61276 29.389 3.73103 30.6939 3.969L41.4112 5.9251C42.716 6.16307 43.3621 7.43109 43.1174 8.67087L39.0548 30.6484C38.8101 31.8882 37.5546 32.7003 36.2496 32.4617L25.5323 30.5056C24.2281 30.2675 23.368 29.0698 23.6121 27.8301"
        fill="black"
        fillOpacity="0.1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.0296 27.3028L26.1951 2.99431C26.4225 1.66243 27.8831 0.730983 29.1942 1.0032L39.9625 3.24069C41.2735 3.51291 41.9386 4.88621 41.7107 6.21819L37.9449 29.8335C37.7169 31.1655 36.4698 32.0246 35.1586 31.7517L24.3904 29.5142C23.0799 29.2419 22.2013 27.9416 22.4287 26.6098"
        fill="#48CAD4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.5158 25.6567L27.1713 4.26044C27.3645 3.12834 28.4246 2.39868 29.539 2.63007L38.6921 4.53194C39.8065 4.76332 40.5533 5.86855 40.3595 7.00074L36.7046 28.3969C36.5108 29.5291 35.4508 30.2593 34.3363 30.0274L25.1833 28.1255C24.0694 27.894 23.3226 26.7888 23.5158 25.6567Z"
        fill="#8AE7F2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.3854 15.1454C36.0235 20.0914 40.4982 6.07079 40.3021 7.20258L36.826 28.378C36.6299 29.5098 35.5633 30.2407 34.4429 30.01L25.2421 28.1181C24.1224 27.8878 23.3728 26.7839 23.5683 25.6521C23.5683 25.6521 24.7797 18.6477 25.3854 15.1454Z"
        fill="#A2EBE2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.2809 19.9919C29.0355 19.5261 27.5307 17.3003 27.9209 15.0212C28.3109 12.7415 30.4476 11.271 32.6938 11.7373C34.9398 12.2031 36.4439 14.4285 36.0538 16.7081C35.6637 18.9877 33.5264 20.4578 31.2809 19.9919Z"
        fill="#48CAD4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.0722 8.45318L32.3758 6.68106C32.4686 6.13764 32.9784 5.78691 33.5139 5.89834L33.6286 5.92182C34.1641 6.03325 34.5228 6.5636 34.43 7.10702L34.1265 8.87914C34.0331 9.42265 33.5239 9.77329 32.9885 9.66242L32.8731 9.63846C32.3376 9.52703 31.9794 8.9966 32.0722 8.45318Z"
        fill="#48CAD4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.275 32.6431L17.7482 10.2083C17.3898 8.9795 18.2613 7.48419 19.5048 7.07208L29.7188 3.68876C30.9624 3.27665 32.1073 4.10417 32.4652 5.33335L39.048 26.9912C39.4059 28.2204 38.6885 29.5506 37.4446 29.9622L27.2305 33.3455C25.9875 33.7572 24.6889 33.0953 24.3306 31.8665"
        fill="black"
        fillOpacity="0.1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.6472 31.0227L15.1466 8.35456C14.7313 7.05109 15.5721 5.51552 16.8367 5.13115L27.2246 1.97574C28.4892 1.59137 29.6992 2.50417 30.114 3.80803L37.7084 26.7925C38.1233 28.0964 37.4348 29.4646 36.1697 29.8485L25.7819 33.0039C24.5177 33.3878 23.1559 32.6429 22.7405 31.3394"
        fill="#48CAD4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5776 9.02212C16.2246 7.91417 16.8101 6.7516 17.8851 6.42488L26.7147 3.74279C27.7897 3.41607 28.9473 4.04929 29.2999 5.15757L35.9695 26.0986C36.3221 27.2069 35.7369 28.3699 34.6616 28.6962L25.8319 31.3783C24.7574 31.7047 23.5998 31.0714 23.2468 29.9635L16.5776 9.02212Z"
        fill="#8AE7F2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0346 19.6403C31.5556 18.9764 28.9925 4.25707 29.3429 5.36612L36.0663 26.0235C36.4167 27.1326 35.8261 28.2993 34.7463 28.6295L25.8796 31.3434C24.8006 31.6737 23.6411 31.043 23.2903 29.9342C23.2903 29.9342 21.1198 23.0716 20.0346 19.6403Z"
        fill="#A2EBE2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.1808 18.3134C21.4708 16.082 22.651 13.7389 24.8176 13.0803C26.9838 12.4213 29.315 13.6961 30.0251 15.9276C30.7351 18.159 29.5541 20.502 27.3883 21.1607C25.2225 21.8193 22.8905 20.5444 22.1808 18.3134Z"
        fill="#48CAD4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.7662 10.4329L22.2145 8.69809C22.0451 8.16623 22.3267 7.60731 22.8434 7.45054L22.9539 7.41662C23.4705 7.25985 24.0264 7.5635 24.1957 8.09536L24.7474 9.83019C24.9164 10.3624 24.6352 10.921 24.1189 11.0782L24.0076 11.112C23.491 11.2688 22.9356 10.9648 22.7662 10.4329Z"
        fill="#48CAD4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.856 26.4313L27.3043 24.6965C27.1346 24.1642 27.4162 23.6061 27.9328 23.4493L28.0437 23.415C28.5604 23.2583 29.1158 23.5623 29.2852 24.0941L29.8368 25.8289C30.0062 26.3608 29.725 26.9194 29.2084 27.0762L29.0971 27.11C28.5808 27.2672 28.0254 26.9632 27.856 26.4313Z"
        fill="#48CAD4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60.297 44.5324C46.7832 42.2814 46.1497 49.845 42.2155 40.1786C41.5745 38.7724 40.783 37.7489 39.6 37.9499C38.365 38.1596 37.6776 38.9348 37.6776 40.1847C37.6776 40.4601 32.4238 32.0267 34.6627 36.0086C32.1498 35.547 29.9977 34.3245 28.6264 31.1798C31.052 29.4 31.8553 28.09 32.0691 27.6574C32.0691 27.6574 33.249 25.5117 31.3047 24.3014C31.1413 24.2154 30.9821 24.164 30.8249 24.1274C31.2705 23.2255 31.4098 22.4519 31.4608 22.2407C31.7553 21.0264 30.9637 19.7917 29.7466 19.4975C28.5284 19.2044 27.2403 19.9327 26.9443 21.1465C26.4228 23.2891 25.1316 27.1907 23.8889 28.5353C23.6761 28.7664 23.5016 28.9959 23.3439 29.2239C21.497 30.4632 20.5957 32.4562 20.9764 34.548C20.6917 34.8574 20.4401 35.2065 20.2339 35.602C19.2448 37.5014 19.7179 39.2226 20.3701 40.4451C20.3916 40.4848 20.4125 40.524 20.4344 40.5627C19.8766 43.9721 24.4273 48.4258 24.4273 48.4258C29.6165 53.5147 34.495 58.471 42.1567 61.3583C54.1378 57.3861 58.9444 48.2201 60.4708 44.561"
        fill="#FFC8E9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.7216 36.0211C34.8121 36.0109 34.8938 36.0761 34.9039 36.1666L34.9469 36.5083L35.0078 36.9403L35.0811 37.4134C35.2328 38.3497 35.4217 39.2859 35.65 40.1586C35.9072 41.1417 36.1985 41.981 36.5241 42.6307C36.565 42.7121 36.532 42.8113 36.4506 42.8521C36.3691 42.8929 36.27 42.86 36.2291 42.7785C35.8916 42.1052 35.5932 41.2456 35.3308 40.2421C35.0995 39.3581 34.9086 38.4121 34.7554 37.4662L34.6813 36.9884L34.5969 36.376L34.576 36.2034C34.5658 36.1129 34.631 36.0312 34.7216 36.0211Z"
        fill="#003CE5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.9869 34.6348C20.5378 32.3782 21.5759 30.2137 23.6963 28.9861C27.161 26.9798 28.0388 25.5802 28.047 25.5665C28.0123 25.6235 28 25.654 28 25.654C28 25.654 29.503 23.3521 31.3045 24.3013C33.2494 25.5115 32.0695 27.6572 32.0695 27.6572C31.7903 28.2201 30.3037 30.4116 25.7515 33.0485"
        fill="#FFC8E9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.3766 31.1475C28.4501 31.0936 28.5533 31.1093 28.6073 31.1828C28.6613 31.2562 28.6455 31.3595 28.5721 31.4134C27.7646 32.0069 26.8542 32.6007 25.8344 33.1913C25.7555 33.237 25.6546 33.2101 25.6089 33.1312C25.5632 33.0524 25.5902 32.9514 25.669 32.9058C26.6786 32.3211 27.579 31.7338 28.3766 31.1475Z"
        fill="#003CE5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.7084 23.9097C30.798 23.9262 30.8573 24.0123 30.8407 24.1019C30.8242 24.1915 30.7382 24.2507 30.6485 24.2342C29.8643 24.0895 29.3004 24.3773 28.6775 25.0841L28.5294 25.2588L28.0143 25.8973L27.8184 26.1321L27.6238 26.3548C27.2339 26.7897 26.8324 27.1655 26.3063 27.559L26.0324 27.7576C25.9848 27.7911 25.9362 27.8248 25.8866 27.8587L25.5756 28.0657C25.4673 28.136 25.3543 28.2076 25.2359 28.281L24.8645 28.5067L24.4581 28.745L24.0136 28.9979C23.9362 29.0414 23.8571 29.0856 23.7761 29.1305C23.6965 29.1748 23.596 29.1461 23.5518 29.0664C23.5075 28.9867 23.5363 28.8863 23.6159 28.842L24.0735 28.5851L24.4915 28.344L24.8731 28.1167L25.2214 27.9011L25.5397 27.695C25.9453 27.4256 26.279 27.1759 26.5745 26.9231L26.7894 26.7326C26.8242 26.7007 26.8585 26.6686 26.8925 26.6363L27.0917 26.4401C27.1243 26.407 27.1566 26.3735 27.1887 26.3397L27.3795 26.133L27.5684 25.9167C27.5999 25.8797 27.6315 25.8422 27.6632 25.8042L27.9372 25.4695L28.3135 25.0018C29.0497 24.1195 29.7346 23.73 30.7084 23.9097Z"
        fill="#003CE5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.1988 39.6244C30.6724 39.4799 31.419 39.4274 31.9053 38.6564C32.5718 37.5983 32.4238 36.2252 31.319 35.5101C30.5341 35.0022 26.5325 32.3898 23.2582 33.2046C21.9236 33.5369 20.8774 34.3665 20.2339 35.6022L24.0471 39.4626L30.1988 39.6244Z"
        fill="#FFC8E9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.2182 33.0445C23.8149 32.896 24.4547 32.8524 25.1314 32.9042C26.4624 33.0062 27.8783 33.4661 29.3163 34.1731C29.7746 34.3985 30.2126 34.6381 30.6332 34.888L31.0121 35.1184L31.2637 35.2781C31.3092 35.3073 31.3571 35.3383 31.4085 35.3716C32.5572 36.115 32.7871 37.4949 32.0471 38.7407C31.6189 39.461 30.9156 39.7894 30.1987 39.7894C30.1076 39.7894 30.0337 39.7155 30.0337 39.6244C30.0337 39.5333 30.1076 39.4594 30.1987 39.4594C30.8076 39.4594 31.4005 39.1825 31.7634 38.5721C32.4173 37.4713 32.2205 36.2902 31.2292 35.6486L30.9577 35.4742L30.7199 35.3256L30.4647 35.1717C30.0512 34.9261 29.6207 34.6905 29.1707 34.4693C27.7672 33.7792 26.389 33.3315 25.1062 33.2333C24.4632 33.184 23.8585 33.2252 23.298 33.3647C22.554 33.5503 21.9021 33.8954 21.3626 34.3872C21.2952 34.4485 21.1909 34.4437 21.1295 34.3764C21.0681 34.309 21.0729 34.2047 21.1403 34.1433C21.7206 33.6143 22.4216 33.2432 23.2182 33.0445Z"
        fill="#003CE5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.4546 40.4002C20.6648 39.3635 21.3023 38.5441 22.25 38.0932C24.523 37.0106 28.2061 38.5873 30.1985 39.6246C31.3095 40.2017 31.7402 41.5687 31.161 42.6767C30.5817 43.7842 29.4927 44.1857 28.1015 43.6371C27.1589 43.1841 26.0658 42.6971 25.533 42.5617C25.0002 42.4268 24.5179 43.1938 25.1359 43.6147"
        fill="#FFC8E9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.179 37.9443C24.1186 37.0207 27.0644 37.808 30.275 39.4782C31.4666 40.0975 31.9291 41.5645 31.3072 42.7532C30.6958 43.9231 29.5597 44.3296 28.1935 43.8477L28.0463 43.7927C27.7509 43.6879 27.4449 43.5667 27.1118 43.4248L25.7478 42.8226L25.5281 42.7308C25.0867 42.564 24.7888 43.1787 25.2287 43.4784C25.3041 43.5297 25.3235 43.6323 25.2722 43.7076C25.2209 43.7829 25.1183 43.8024 25.043 43.7511C24.2865 43.2359 24.8302 42.1142 25.6447 42.4221L25.871 42.5165L27.2411 43.1212C27.4866 43.2258 27.7166 43.3187 27.9402 43.4026L28.162 43.4837C29.447 43.9907 30.4624 43.6572 31.0147 42.6003C31.552 41.5734 31.1525 40.3062 30.1228 39.771C27.0033 38.1482 24.1306 37.3805 22.3208 38.2422C21.4452 38.659 20.8493 39.4047 20.6338 40.3529C20.6136 40.4417 20.5252 40.4974 20.4363 40.4772C20.3475 40.457 20.2918 40.3686 20.312 40.2797C20.5499 39.2331 21.212 38.4045 22.179 37.9443Z"
        fill="#003CE5"
      />
    </svg>
  );
};

export default HandMoney;
