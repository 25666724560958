import React, { useEffect, useRef, useState } from "react";
import { ThumbsDownBlackIcon } from "../../assets/ThumbsDownBlackIcon";
import { ThumbsDownRedIcon } from "../../assets/ThumbsDownRedIcon";
import { ThumbsUpBlackIcon } from "../../assets/ThumbsUpBlackIcon";
import { ThumbsUpGreenIcon } from "../../assets/ThumbsUpGreenIcon";
import { submitFeedbackEllmental } from "../../services/FeedbackService";
import { Source } from "../../services/PipelineService";
import {
  sendUserFeedback,
  sendUserFeedbackSelected,
} from "../../utils/analyticsEvents";
import { getBrowserInfo } from "../../utils/browserInfo";
import CustomChecklistItem from "../atoms/CustomChecklistItem";
import RoundedButton from "../atoms/RoundedButton";

interface UserFeedbackProps {
  duration: number;
  textResponse: string;
  sources: Source[];
  requestId: string;
  contextId: string;
  query: string;
  isOpen: boolean;
  onClose: () => void;
  selectedFeedback: "angry" | "happy" | null;
  setSelectedFeedback: React.Dispatch<
    React.SetStateAction<"angry" | "happy" | null>
  >;
  onFeedbackChange: (feedback: "angry" | "happy" | null) => void;
  setIsFeedbackSent: React.Dispatch<React.SetStateAction<boolean>>;
  isFeedbackSent: boolean;
}

const UserFeedback: React.FC<UserFeedbackProps> = ({
  duration,
  textResponse,
  sources,
  requestId,
  contextId,
  query,
  isOpen,
  onClose,
  selectedFeedback,
  setSelectedFeedback,
  onFeedbackChange,
  setIsFeedbackSent,
  isFeedbackSent,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [feedbackText, setFeedbackText] = useState("");
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const panelRef = useRef<HTMLDivElement>(null);

  const closePanel = () => {
    setSelectedFeedback(null);
    onClose();
  };

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        panelRef.current &&
        !panelRef.current.contains(event.target as Node)
      ) {
        closePanel();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, onClose]);

  const handleFeedbackClick = (feedback: "angry" | "happy") => {
    onFeedbackChange(feedback);
    setSelectedOptions([]);
    setIsFeedbackSent(false);
    sendUserFeedbackSelected(requestId, query, feedback, contextId);
  };

  const handleRecommendationToggle = (label: string) => {
    setSelectedOptions((prev) =>
      prev.includes(label)
        ? prev.filter((item) => item !== label)
        : [...prev, label],
    );
  };

  const isSubmitDisabled =
    !selectedFeedback ||
    (selectedOptions.length === 0 && feedbackText.trim() === "");

  const recommendationLabels = {
    angry: [
      "Información incorrecta",
      "No era lo que buscaba",
      "Falta información relevante",
      "Es dificil de entender",
      "Falta ser más específico",
    ],
    neutral: [
      "Información incorrecta",
      "No era lo que buscaba",
      "Falta información relevante",
      "Es dificil de entender",
      "Falta ser más específico",
    ],
    happy: [
      "Información precisa y completa",
      "Fácil de entender",
      "Útil y aplicable",
      "Respuesta rápida",
      "Buena estructura",
    ],
  };

  const handleSubmitFeedback = async () => {
    const browserInfo = getBrowserInfo();

    const userMetadata = {
      selectedOptions,
      feedbackCategory: selectedFeedback,
      duration,
      textResponse,
      sources: JSON.stringify(sources),
      numberOfSources: sources.length,
      datetime: new Date().toISOString(),
      browserInfo: JSON.stringify(browserInfo),
      comment: feedbackText,
      requestId,
      query,
    };

    await submitFeedbackEllmental(feedbackText, userMetadata, requestId);

    sendUserFeedback(
      requestId,
      query,
      selectedFeedback,
      selectedOptions,
      feedbackText,
      contextId,
    );
    setIsFeedbackSent(true);
    closePanel();
  };

  return (
    <>
      <div
        className={`fixed inset-0 bg-black transition-opacity duration-300 ${
          isOpen ? "bg-opacity-50 z-40" : "bg-opacity-0 -z-10"
        }`}
        onClick={closePanel}
      ></div>

      <div
        className={`fixed bg-white shadow-lg duration-300 ease-in-out z-50
          ${
            isSmallScreen
              ? "inset-x-0 bottom-0 h-[90%] rounded-t-3xl"
              : "inset-y-0 right-0 w-[440px]"
          } 
          ${
            isOpen
              ? isSmallScreen
                ? "translate-y-0"
                : "translate-x-0"
              : isSmallScreen
              ? "translate-y-full"
              : "translate-x-full"
          }`}
        style={{ visibility: isOpen ? "visible" : "hidden" }}
        ref={panelRef}
      >
        <div className="p-4 h-full overflow-y-auto text-center pt-20">
          <button
            onClick={closePanel}
            className="absolute top-6 right-6 text-gray-500"
          >
            <i className="pi pi-times text-3xl text-BCP-orange" />
          </button>
          <div>
            <h2 className="font-semibold mb-5 text-BCP-dark-blue">
              ¿Resolvimos tu consulta?
            </h2>
            <div className="flex space-x-4 mb-6 mx-auto w-fit">
              {selectedFeedback === "happy" ? (
                <div className="flex gap-2">
                  <button
                    className="rounded-full py-2 px-4 bg-BCP-light-green flex font-semibold group"
                    onClick={() => handleFeedbackClick("happy")}
                  >
                    <ThumbsUpGreenIcon className="w-6 h-6 mr-2" />
                    <p className="text-[#37A500]">Sí</p>
                  </button>
                  <button
                    className="rounded-full p-2 px-4 bg-[#F6F6F7] hover:bg-[#FFF2F5] flex font-semibold group"
                    onClick={() => handleFeedbackClick("angry")}
                  >
                    <ThumbsDownBlackIcon className="w-6 h-6 mr-2 group-hover:hidden" />
                    <ThumbsDownRedIcon className="w-6 h-6 mr-2 hidden group-hover:block" />
                    <p className="text-[#264B78] group-hover:text-BCP-error">
                      No
                    </p>
                  </button>
                </div>
              ) : (
                <div className="flex gap-3">
                  <button
                    className="rounded-full py-2 px-4 bg-[#F6F6F7] hover:bg-BCP-light-green flex font-semibold group"
                    onClick={() => handleFeedbackClick("happy")}
                  >
                    <ThumbsUpBlackIcon className="w-6 h-6 mr-2 group-hover:hidden" />
                    <ThumbsUpGreenIcon className="w-6 h-6 mr-2 hidden group-hover:block" />
                    <p className="text-[#264B78] group-hover:text-[#37A500]">
                      Sí
                    </p>
                  </button>
                  <button
                    className="rounded-full p-2 px-4 bg-[#FFF2F5] flex font-semibold group"
                    onClick={() => handleFeedbackClick("angry")}
                  >
                    <ThumbsDownRedIcon className="w-6 h-6 mr-2" />
                    <p className="text-BCP-error">No</p>
                  </button>
                </div>
              )}
            </div>
          </div>

          {selectedFeedback && !isFeedbackSent && (
            <div className="">
              <h3 className="font-semibold mb-5 text-BCP-dark-blue">
                Tu opinión nos importa. ¿Qué{" "}
                {selectedFeedback === "happy" ? "te gustó?" : "no te gustó?"}
              </h3>
              <div className="flex flex-wrap gap-3 mb-6">
                {recommendationLabels[selectedFeedback].map((label) => (
                  <CustomChecklistItem
                    key={label}
                    label={label}
                    isSelected={selectedOptions.includes(label)}
                    onClick={() => handleRecommendationToggle(label)}
                  />
                ))}
              </div>
              <div className="mb-4">
                <textarea
                  placeholder="Tienes alguna sugerencia para mejorar?"
                  value={feedbackText}
                  onChange={(e) =>
                    setFeedbackText(e.target.value.slice(0, 500))
                  }
                  maxLength={500}
                  className="w-full h-24 mb-1 border mx-1 text-BCP-dark-gray border-BCP-dark-gray rounded-lg p-3 resize-none outline-BCP-dark-gray"
                />
                <div className="text-right text-sm text-gray-500">
                  {feedbackText.length}/500
                </div>
              </div>
              <div className="flex justify-center pb-12 max-w-[340px] mx-auto">
                <RoundedButton
                  label="Enviar"
                  onClick={handleSubmitFeedback}
                  disabled={isSubmitDisabled}
                  customClass="h-[40px]"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default UserFeedback;
