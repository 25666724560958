import { FC } from "react";

interface CloseDrawerIconProps {
  className?: string;
}

export const CloseDrawerIcon: FC<CloseDrawerIconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="c/ic_close">
        <path
          id="color&#240;&#159;&#142;&#168;"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.0113 7.10145C9.19765 6.4688 8.02117 6.52632 7.2735 7.27399L7.10096 7.46938C6.46831 8.28302 6.52583 9.4595 7.2735 10.2072L13.0645 15.9998L7.2735 21.7925C6.46352 22.6025 6.46352 23.9157 7.2735 24.7257C8.08347 25.5357 9.39671 25.5357 10.2067 24.7257L15.9993 18.9347L21.792 24.7257L21.9874 24.8982C22.801 25.5309 23.9775 25.4734 24.7252 24.7257L24.8977 24.5303C25.5304 23.7167 25.4729 22.5402 24.7252 21.7925L18.9342 15.9998L24.7252 10.2072C25.5352 9.39719 25.5352 8.08396 24.7252 7.27399C23.9152 6.46401 22.602 6.46401 21.792 7.27399L15.9993 13.065L10.2067 7.27399L10.0113 7.10145Z"
          fill="#FF7800"
        />
      </g>
    </svg>
  );
};

export default CloseDrawerIcon;
