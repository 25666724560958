import React from "react";

type SearchHeaderProps = {
  children?: React.ReactNode;
};

const SearchHeader: React.FC<SearchHeaderProps> = ({ children }) => {
  return (
    <h1
      className={`text-BCP-dark-blue mt-2 text-left md:text-[32px] text-[24px] md:leading-[48px] leading-[32px] font-semibold`}
    >
      {children}
    </h1>
  );
};

export default SearchHeader;
