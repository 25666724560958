import React from "react";

interface CustomChecklistItemProps {
  label: string;
  isSelected: boolean;
  onClick: () => void;
}

const CustomChecklistItem: React.FC<CustomChecklistItemProps> = ({
  label,
  isSelected,
  onClick,
}) => {
  return (
    <button
      className={`flex py-2 px-4 rounded-full ${
        isSelected ? "bg-BCP-blue-selected-bg" : "bg-light-gray"
      } `}
      onClick={onClick}
    >
      <span
        className={`font-semibold ${
          isSelected ? "text-BCP-blue-selected-text" : "text-BCP-dark-gray"
        }`}
      >
        {label}
      </span>
    </button>
  );
};

export default CustomChecklistItem;
