import { FC } from "react";

interface ThumbsDownRedIconProps {
  className?: string;
}

export const ThumbsDownRedIcon: FC<ThumbsDownRedIconProps> = ({
  className,
}) => {
  return (
    <div className={className}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Back arrow">
          <path
            id="color&#240;&#159;&#142;&#168;"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 3C2.44772 3 2 3.44772 2 4V12C2 12.5523 2.44772 13 3 13H7C9 14.3333 10 17.3333 10 19C10 21 11.5 22 13 22C14.5 22 16 21 16 19V15H21C21.5523 15 22 14.5523 22 14V3C22 2.44772 21.5523 2 21 2H10.6401C10.5471 2 10.4547 2.01295 10.3653 2.03848L7 3H3ZM13 20C12.368 20 12 19.6729 12 19C12 16.8841 10.9436 13.5341 9 11.9842V4.5086L10.7801 4H20V13H16C14.8954 13 14 13.8954 14 15V19C14 19.6729 13.632 20 13 20Z"
            fill="#E30425"
          />
        </g>
      </svg>
    </div>
  );
};
