import React from "react";

interface RelatedQuestionsProps {
  relatedQuestions: string[];
  onQuestionClick: (question: string) => void;
}

//TODO: IMPLEMENTS THE RELATED QUESTIONS
const RelatedQuestions: React.FC<RelatedQuestionsProps> = ({
  relatedQuestions,
  onQuestionClick,
}) => {
  return (
    <div>
      {relatedQuestions && relatedQuestions.length > 0 && (
        <h2 className="text-xl mb-3 font-semibold py-2">
          Preguntas relacionadas
        </h2>
      )}
      <ul className="space-y-4 text-sm font-semibold text-BCP-normal-text">
        {relatedQuestions.map((question, index) => (
          <li
            key={index}
            className="flex items-center justify-between border-b border-BCP-gray-border pb-3 hover:underline cursor-pointer"
          >
            <button
              onClick={() => onQuestionClick(question)}
              className="flex-grow text-left"
            >
              {question}
            </button>
            <i className="pi pi-arrow-right text-sm" />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RelatedQuestions;
