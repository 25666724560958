import React, { useState } from "react";
import { CloseIcon } from "../assets/CloseIcon";
import { ThumbsDownBlackIcon } from "../assets/ThumbsDownBlackIcon";
import { ThumbsDownRedIcon } from "../assets/ThumbsDownRedIcon";
import { ThumbsUpBlackIcon } from "../assets/ThumbsUpBlackIcon";
import { ThumbsUpGreenIcon } from "../assets/ThumbsUpGreenIcon";
import CustomAlert from "../components/atoms/CustomAlert";
import SearchHeader from "../components/atoms/SearchHeader";
import RelatedQuestions from "../components/molecules/RelatedQuestions";
import SearchBar from "../components/molecules/SearchBar";
import ResponseContent from "../components/organisms/ResponseContent";
import UserFeedback from "../components/organisms/UserFeedback";
import { useSearch } from "../context/SearchContext";
import { Source } from "../services/PipelineService";
import {
  sendSourceLinkClick,
  sendUserFeedbackSelected,
} from "../utils/analyticsEvents";
const ResponsePage: React.FC = () => {
  const {
    handleSearch,
    currentSearchTerm,
    textResponse,
    isLoading,
    sources,
    banners,
    actions,
    tutorials,
    relatedQueries,
    duration,
    requestId,
    contextId,
    resetSearch,
  } = useSearch();

  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
  const [suggestionsEnabled, setSuggestionsEnabled] = useState(false);
  const [selectedFeedback, setSelectedFeedback] = useState<
    "angry" | "happy" | null
  >(null);
  const [isFeedbackSent, setIsFeedbackSent] = useState(false);

  const handleFeedbackClick = (feedback: "angry" | "happy") => {
    setSelectedFeedback(feedback);
    setIsFeedbackOpen(true);
    sendUserFeedbackSelected(requestId, currentSearchTerm, feedback, contextId);
  };

  const handleFeedbackClose = () => {
    setIsFeedbackOpen(false);
  };

  const handleFeedbackChange = (feedback: "angry" | "happy" | null) => {
    setSelectedFeedback(feedback);
  };

  const removeSpecificParams = (url: URL, setRfidValue: string): string => {
    const paramsToRemove = [
      "utm_source",
      "utm_medium",
      "utm_campaign",
      "utm_content",
      "pcid",
      "rfid",
    ];

    paramsToRemove.forEach((param) => {
      url.searchParams.delete(param);
    });

    url.searchParams.set("rfid", setRfidValue);
    url.search = url.search.replace(/%3A/g, ":");

    return url.toString();
  };

  const MoreInformationSection: React.FC = () => {
    const uniqueSources = sources.filter(
      (source, index, self) =>
        index === self.findIndex((e) => e.url === source.url),
    );

    if (uniqueSources.length === 0) return null;

    return (
      <div className="mt-8">
        <h2 className="text-xl font-semibold mb-4">Más información en</h2>

        <div className="grid gap-4 grid-cols-1 md:grid-cols-2">
          {uniqueSources.slice(0, 5).map((source: Source, index: number) => {
            const url = new URL(source.url);
            const cleanedUrl = removeSpecificParams(
              url,
              "buscador:fuente:pilotoIA",
            );

            const handleSourceClick = () => {
              sendSourceLinkClick(
                currentSearchTerm,
                requestId,
                cleanedUrl,
                source.title,
                contextId,
              );
            };

            return (
              <div
                key={index}
                className="bg-white border rounded-md p-4 shadow hover:shadow-lg transition-shadow"
              >
                <a
                  href={cleanedUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={handleSourceClick}
                >
                  {source.label && (
                    <div className="rounded px-2 py-1 bg-BCP-blue-selected-text mb-2 w-fit">
                      <p className="text-sm text-white">{source.label}</p>
                    </div>
                  )}

                  <h3 className="text-sm text-BCP-normal-text font-semibold">
                    {source.title}
                  </h3>
                </a>
              </div>
            );
          })}
        </div>

        {!isLoading && isFeedbackSent && (
          <div className="w-full flex mt-6 md:block hidden">
            <CustomAlert message="¡Gracias por ayudarnos a construir una mejor experiencia!" />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="flex flex-col w-full min-h-screen items-center">
      <div className="md:flex w-full max-w-[1200px] px-6 gap-2 mt-16 mb-44 md:mb-16">
        <div className="w-full md:w-1/2 flex flex-col">
          <div>
            <div className="text-BCP-dark-gray text-[16px] font-[400]">
              Resultados de búsqueda para
            </div>

            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <SearchHeader>"{currentSearchTerm}"</SearchHeader>
          </div>

          <div className="md:max-w-[600px] mt-8">
            <SearchBar
              queryDisplayMode="responsePage"
              isInternalSearch={true}
              onMoreQuestionsClick={() => {}}
              onSuggestionsEnabledChange={(enabled) => {
                setSuggestionsEnabled(enabled);
              }}
              isLoading={isLoading}
              onProductosPreaprobadosClick={() => {}}
            />
          </div>

          <ResponseContent
            textResponse={textResponse}
            banners={banners}
            actions={actions}
            tutorials={tutorials}
            query={currentSearchTerm}
            requestId={requestId}
            contextId={contextId}
            suggestionsEnabled={suggestionsEnabled}
          />

          {isLoading && textResponse.length === 0 && (
            <div className="flex justify-center items-center max-w-[4rem] mt-8 md:my-12 mx-auto">
              <img src="simpleSpinner.gif" alt="loading" />
            </div>
          )}

          {!isLoading && isFeedbackSent && (
            <div className="w-full flex justify-start mt-6 md:hidden block">
              <CustomAlert message="¡Gracias por ayudarnos a construir una mejor experiencia!" />
            </div>
          )}
        </div>

        <aside
          className={`${
            suggestionsEnabled ? "blur-sm" : ""
          } md:block md:w-1/2 text-BCP-dark-blue md:ml-10 md:mt-6`}
        >
          <button
            className="fixed md:relative top-4 right-4 md:flex ml-auto mr-0"
            onClick={() => {
              resetSearch();
            }}
          >
            <CloseIcon className="w-8 h-8" />
          </button>
          <div className="mt-16">
            <RelatedQuestions
              relatedQuestions={relatedQueries}
              onQuestionClick={(question) => {
                handleSearch(question, "suggestion");
              }}
            />
          </div>
          <MoreInformationSection />
          {textResponse.length > 0 && !isLoading && !isFeedbackSent && (
            <div className="flex flex-col pt-4 mt-8">
              <h3 className="text-xl mb-4 font-semibold">
                ¿Resolvimos tu consulta?
              </h3>
              <div className="flex space-x-3">
                <button
                  className="rounded-full py-2 px-4 bg-[#F6F6F7] hover:bg-BCP-light-green flex font-semibold group"
                  onClick={() => handleFeedbackClick("happy")}
                >
                  <ThumbsUpBlackIcon className="w-6 h-6 mr-2 group-hover:hidden" />
                  <ThumbsUpGreenIcon className="w-6 h-6 mr-2 hidden group-hover:block" />
                  <p className="text-[#264B78] group-hover:text-[#37A500]">
                    Sí
                  </p>
                </button>
                <button
                  className="rounded-full p-2 px-4 bg-[#F6F6F7] hover:bg-[#FFF2F5] flex font-semibold group"
                  onClick={() => handleFeedbackClick("angry")}
                >
                  <ThumbsDownBlackIcon className="w-6 h-6 mr-2 group-hover:hidden" />
                  <ThumbsDownRedIcon className="w-6 h-6 mr-2 hidden group-hover:block" />
                  <p className="text-[#264B78] group-hover:text-BCP-error">
                    No
                  </p>
                </button>
              </div>
            </div>
          )}
        </aside>
      </div>

      <UserFeedback
        duration={duration}
        textResponse={textResponse}
        sources={sources}
        requestId={requestId}
        contextId={contextId}
        query={currentSearchTerm}
        isOpen={isFeedbackOpen}
        onClose={handleFeedbackClose}
        selectedFeedback={selectedFeedback}
        setSelectedFeedback={setSelectedFeedback}
        onFeedbackChange={handleFeedbackChange}
        isFeedbackSent={isFeedbackSent}
        setIsFeedbackSent={setIsFeedbackSent}
      />
    </div>
  );
};

export default ResponsePage;
