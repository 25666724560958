import Cookies from "js-cookie";
import React, { useEffect, useRef, useState } from "react";
import Joyride, { Step } from "react-joyride";
import { useSearch } from "../context/SearchContext";
import ResponsePage from "./ResponsePage";
import SearchPage from "./SearchPage";
import Logo from "../assets/Logo";

interface State {
  run: boolean;
  steps: Step[];
}

const MainPage: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { hasSearched, isLoading, setWasSeen, resetSearch } = useSearch();

  const tutorialContent = (index: number) => {
    switch (index) {
      case 0:
        return (
          <div>
            <p className="font-bold text-left">
              Conoce tus productos preaprobados
            </p>
            <p className="text-sm mt-2 text-left">
              ¡Es rápido y solo te tomará unos segundos!
            </p>
          </div>
        );
      case 1:
        return (
          <div>
            <p className="font-bold text-left">Preguntas sugeridas para ti</p>
            <p className="text-sm mt-2 text-left">
              ¡Lleva tu búsqueda al siguiente nivel!
            </p>
          </div>
        );
      case 2:
        return (
          <div>
            <p className="font-bold text-left">
              Todo listo para iniciar tu búsqueda
            </p>
            <p className="text-sm mt-2 text-left">
              ¡Resuelve tus dudas al instante!
            </p>
          </div>
        );
    }
  };

  const [tutorialStep, setTutorialStep] = useState(0);

  const waitForSelector = (selector: string, callback: () => void) => {
    const observer = new MutationObserver(() => {
      const element = document.querySelector(selector);
      if (element) {
        callback();
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  };

  const getSpotlightStyle = (selector: string) => {
    const element = document.querySelector(selector);
    if (!element) return {};

    const rect = element.getBoundingClientRect();
    return {
      top: rect.top,
      left: rect.left,
      width: rect.width,
      height: rect.height,
    };
  };

  const addExtraSpotlightStyle = () => {
    waitForSelector(".react-joyride__overlay", () => {
      const overlay = document.querySelector(".react-joyride__overlay");
      const spotlight = document.querySelector(".react-joyride__spotlight");

      if (!overlay || !spotlight) return;

      const extra1 = getSpotlightStyle(
        "#button-piggy-bank",
      ) as React.CSSProperties;
      const extra2 = getSpotlightStyle("#button-bill") as React.CSSProperties;
      const extra3 = getSpotlightStyle(
        "#button-credit-card",
      ) as React.CSSProperties;
      const newSpotlights: React.CSSProperties[] = [extra1, extra2, extra3];

      // copy spotlight styles
      const spotlightStyles = (spotlight as HTMLElement).style.cssText;

      newSpotlights.forEach((rect) => {
        // create a copy of the spotlight element
        const spotlightCopy = spotlight.cloneNode(true) as HTMLElement;
        spotlightCopy.style.cssText = spotlightStyles;
        // Remove position-related properties from the original styles
        const cleanedStyles = spotlightStyles.replace(
          /(?:top|left|right|bottom|transform):[^;]+;/g,
          "",
        );

        // Apply cleaned styles and extra1 properties
        spotlightCopy.style.cssText = `
          ${cleanedStyles}
          top: ${rect.top}px;
          left: ${rect.left}px;
          width: ${rect.width}px;
          height: ${rect.height}px;
        `;

        overlay.appendChild(spotlightCopy);
      });
    });
  };

  const removeExtraSpotlightStyles = () => {
    const overlay = document.querySelector(".react-joyride__overlay");
    if (!overlay) return;
    overlay.innerHTML = "";
  };

  useEffect(() => {
    if (tutorialStep === 1) {
      addExtraSpotlightStyle();
    } else {
      removeExtraSpotlightStyles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tutorialStep]);

  const progressComponent = (index: number) => {
    let color0 = "bg-[#001f5a]";
    let color1 = "bg-[#001f5a]";
    let color2 = "bg-[#001f5a]";
    switch (index) {
      case 0:
        color0 = "bg-white";
        color1 = "bg-[#001f5a]";
        color2 = "bg-[#001f5a]";
        break;
      case 1:
        color0 = "bg-white";
        color1 = "bg-white";
        color2 = "bg-[#001f5a]";
        break;
      case 2:
        color0 = "bg-white";
        color1 = "bg-white";
        color2 = "bg-white";
        break;
    }
    return (
      <div className="flex space-x-3">
        <div className={`rounded-full w-2 h-2 ${color0}`} />
        <div className={`rounded-full w-2 h-2 ${color1}`} />
        <div className={`rounded-full w-2 h-2 ${color2}`} />
      </div>
    );
  };

  const [{ run, steps }, setState] = useState<State>({
    run: !Cookies.get("tutorialShown"),
    steps: [
      {
        content: tutorialContent(0),
        placement: "left",
        locale: { skip: progressComponent(0) },
        disableBeacon: true,
        target: "#button-dashboard-card",
        styles: {
          spotlight: {
            borderRadius: "18px",
            transform: "scaleX(0.955) scaleY(0.9) translateY(1px)",
          },
        },
      },
      {
        content: tutorialContent(1),
        placement: "right",
        locale: { skip: progressComponent(1) },
        disableBeacon: true,
        target: "#button-compass",
        styles: {
          spotlight: {
            borderRadius: "18px",
            transform: "scaleX(0.915) scaleY(0.90) translateY(1px)",
          },
        },
      },
      {
        content: tutorialContent(2),
        placement: "right",
        locale: { skip: progressComponent(2) },
        disableBeacon: true,
        target: "#searchBarRef",
        styles: {
          spotlight: {
            borderRadius: "9999px",
            transform: "scaleX(0.97) scaleY(0.75) translateY(1px)",
          },
        },
      },
    ],
  });

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setWasSeen(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 },
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [setWasSeen]);

  const handleJoyrideCallback = (data: any) => {
    if (data.action === "close") {
      setState({ run: false, steps: steps });
      Cookies.set("tutorialShown", "true", { expires: 365 });
    } else if (data.action === "prev" && data.lifecycle === "complete") {
      setState({ run: true, steps: steps });
      setTutorialStep(tutorialStep - 1);
    } else if (data.action === "next" && data.lifecycle === "complete") {
      if (tutorialStep === steps.length - 1) {
        setState({ run: false, steps: steps });
      } else {
        setState({ run: true, steps: steps });
        setTutorialStep(tutorialStep + 1);
      }
    } else if (data.action === "stop") {
      Cookies.set("tutorialShown", "true", { expires: 365 });
    }
  };

  return (
    <div
      className={`${
        hasSearched || isLoading
          ? "bg-white"
          : "bg-gradient-to-b from-white to-[#BAD9FF]"
      } flex font-flexo flex-col min-h-screen justify-between w-full`}
    >
      <button
        className="mt-4 mx-auto pl-6 w-full max-w-[1200px]"
        onClick={() => {
          resetSearch();
        }}
      >
        <Logo />
      </button>
      <div ref={containerRef}>
        {hasSearched || isLoading ? <ResponsePage /> : <SearchPage />}
      </div>
      <Joyride
        callback={handleJoyrideCallback}
        run={run}
        steps={steps}
        showProgress
        showSkipButton
        stepIndex={tutorialStep}
        continuous={true}
        locale={{
          nextLabelWithProgress: "Siguiente",
          last: "Comenzar",
          back: "Atrás",
        }}
        styles={{
          options: {
            arrowColor: "#002A8D",
            backgroundColor: "#002A8D",
            overlayColor: "rgba(0, 0, 0, 0.60)",
            primaryColor: "#001f5a",
            textColor: "white",
            width: 340,
            zIndex: 1000,
          },
        }}
      />
    </div>
  );
};

export default MainPage;
