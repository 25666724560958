import { FC } from "react";

interface AtmIconProps {
  className?: string;
}

export const AtmIcon: FC<AtmIconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 2.5H21C21.552 2.5 22 2.948 22 3.5V13.5C22 14.052 21.552 14.5 21 14.5H17V21.5C17 22.052 16.552 22.5 16 22.5H8C7.447 22.5 7 22.052 7 21.5V14.5H3C2.447 14.5 2 14.052 2 13.5V3.5C2 2.948 2.447 2.5 3 2.5ZM14.9996 8.4999H8.9996V20.4999H14.9996V8.4999ZM20 4.5H4V12.5H7V8.5L5.9996 8.4999V6.4999H17.9996V8.4999L17 8.5V12.5H20V4.5ZM10 11.5C10 10.396 10.896 9.5 12 9.5C13.104 9.5 14 10.396 14 11.5C14 12.604 13.104 13.5 12 13.5C10.896 13.5 10 12.604 10 11.5ZM12 14.4999C12.55 14.4999 13 14.9399 13 15.4999V18.4999C13 19.0599 12.55 19.4999 12 19.4999C11.449 19.4999 11 19.0599 11 18.4999V15.4999C11 14.9399 11.449 14.4999 12 14.4999ZM5.9996 6.4999V8.4999C5.44732 8.4999 4.9996 8.05219 4.9996 7.4999C4.9996 6.94762 5.44732 6.4999 5.9996 6.4999ZM17.9996 6.4999C18.5519 6.4999 18.9996 6.94762 18.9996 7.4999C18.9996 8.05219 18.5519 8.4999 17.9996 8.4999V6.4999Z"
        fill="#0A47F0"
      />
    </svg>
  );
};

export default AtmIcon;
