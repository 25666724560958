import React, { useEffect, useRef, useState } from "react";
import AngleRight from "../../assets/AngleRight";
import CloseIcon from "../../assets/CloseIcon";
import { PoweredByAIFindr } from "../../assets/PoweredByAIFindr";
import recommendations from "../../assets/recommendations.json";
import SearchIcon from "../../assets/SearchIcon";
import { SearchSubmitButton } from "../../assets/SearchSubmitButton";
import { useSearch } from "../../context/SearchContext";
import CustomAlert from "../atoms/CustomAlert";
import SuggestedQueries from "./SuggestedQueries";

interface SearchBarProps {
  queryDisplayMode: "hideSuggestedQueries" | "responsePage" | "searchPage";
  isInternalSearch: boolean;
  isLoading?: boolean;
  onMoreQuestionsClick: () => void;
  onProductosPreaprobadosClick: () => void;
  onSuggestionsEnabledChange?: (enabled: boolean) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({
  queryDisplayMode,
  isInternalSearch,
  isLoading = false,
  onMoreQuestionsClick,
  onSuggestionsEnabledChange,
  onProductosPreaprobadosClick,
}) => {
  const { handleSearch, contextReceived, currentSearchTerm } = useSearch();
  const [search, setSearch] = useState("");
  const [isActive, setIsActive] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const suggestedQueriesRef = useRef<HTMLDivElement>(null);
  const placeholderText = isInternalSearch
    ? "¿En qué más te puedo ayudar?"
    : "Buscar o seleccionar pregunta";
  const [suggestions, setSuggestions] = useState<string[]>([]);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const searchType = isInternalSearch ? "internal" : "initial";
    handleSearch(search, searchType);

    setSuggestions([]);
    setIsActive(false);
  };

  const handleQueryClick = (query: string) => {
    handleSearch(query, "suggestion");
    handleReset();
  };

  const handleReset = () => {
    setSearch("");
    setSuggestions([]);
    setIsActive(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearch(value);

    if (value) {
      const exampleSuggestions = recommendations.labels;
      const normalizedValue = value
        .normalize("NFD")
        .replace(/\p{Diacritic}/gu, "");
      setSuggestions(
        exampleSuggestions
          .filter((suggestion) => {
            const normalizedSuggestion = suggestion
              .normalize("NFD")
              .replace(/\p{Diacritic}/gu, "");
            return normalizedSuggestion
              .toLowerCase()
              .includes(normalizedValue.toLowerCase());
          })
          .slice(0, 6),
      );
    } else {
      setSuggestions([]);
    }
  };

  useEffect(() => {
    if (currentSearchTerm) {
      setSearch(currentSearchTerm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (onSuggestionsEnabledChange) {
      onSuggestionsEnabledChange(suggestions.length > 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suggestions]);

  const suggestionsEnabled = suggestions.length > 0;

  const handleSuggestionClick = (suggestion: string) => {
    setSearch(suggestion);
    setSuggestions([]);
    handleQueryClick(suggestion);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        queryDisplayMode === "responsePage" &&
        inputRef.current &&
        !inputRef.current.contains(event.target as Node) &&
        suggestedQueriesRef.current &&
        !suggestedQueriesRef.current.contains(event.target as Node)
      ) {
        setIsActive(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [queryDisplayMode]);

  // const requestContext = () => {
  //   sendContextRequest(contextId);
  //   setContextRequestSent(true);
  // };

  // const contextBanner = (
  //   <div className="bg-BCP-light-blue flex mt-4 space-x-8 justify-center items-center max-w-[800px] mx-auto md:px-8  rounded-[16px]">
  //     <UserPhoneImg />
  //     <div className="space-y-2 md:space-x-8 md:items-center flex flex-col md:flex-row py-4">
  //       <div>
  //         <h3 className="font-bold text-[16px]">Personaliza tus búsquedas</h3>
  //         <h4 className="text-[12px] md:text-[14px]">
  //           Y te daré más detalle sobre tus productos preaprobados
  //         </h4>
  //       </div>
  //       <span className="md:w-[130px] w-[100px] ">
  //         <RoundedButton
  //           onClick={() => requestContext()}
  //           label="Probar ahora"
  //           customClass="text-[12px] md:text-[14px] h-[32px] md:h-[30px]"
  //         />
  //       </span>
  //     </div>
  //   </div>
  // );

  const contextConfirmAlert = (
    <div className="max-w-[330px] hidden">
      <CustomAlert message="¡Todo listo! Empieza tu búsqueda personalizada." />
    </div>
  );

  const renderSearchPageComponents = () => {
    if (queryDisplayMode !== "searchPage") return null;

    return (
      <div className={`${suggestionsEnabled ? "blur-sm" : ""}`}>
        <SuggestedQueries
          onQueryClick={handleQueryClick}
          onMoreQuestionsClick={onMoreQuestionsClick}
          onProductosPreaprobadosClick={onProductosPreaprobadosClick}
        />

        {/* {!contextReceived && contextBanner} */}
        {contextReceived && contextConfirmAlert}
      </div>
    );
  };

  const renderSuggestedQueries = () => {
    if (queryDisplayMode === "hideSuggestedQueries") {
      return null;
    }

    return <></>;
  };

  return (
    <div
      className={`
        ${
          queryDisplayMode === "responsePage"
            ? "fixed md:relative z-20 bottom-0 left-0 right-0 px-6 pb-4 pt-6 md:py-0 md:px-0 rounded-t-3xl md:rounded-none bg-[#DCEBFE] md:bg-white"
            : ""
        } md:relative space-y-2 transition-all duration-300 ease-in-out
        ${
          isActive && queryDisplayMode === "responsePage"
            ? "-translate-y-0"
            : ""
        }
      `}
      ref={wrapperRef}
    >
      <div className="flex justify-center items-center w-full">
        <div className="justify-center items-center w-full space-y-5">
          <form
            onSubmit={handleSubmit}
            className="w-full flex items-center justify-between"
          >
            <div id="searchBarRef" className="flex-grow">
              <span className="w-full relative block">
                <div
                  style={{
                    boxShadow: suggestionsEnabled
                      ? "1px -1px 4px rgba(0,0,0,0.05)"
                      : "",
                  }}
                  className={`w-full p-[3px] bg-white ${
                    suggestionsEnabled
                      ? `${
                          queryDisplayMode !== "searchPage"
                            ? "md:rounded-t-3xl md:rounded-b-none rounded-b-3xl"
                            : "rounded-t-3xl"
                        }`
                      : "rounded-full shadow-lg"
                  }`}
                >
                  <input
                    ref={inputRef}
                    type="text"
                    placeholder={placeholderText}
                    value={search}
                    onChange={handleInputChange}
                    onFocus={() => setIsActive(true)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        handleSubmit(e);
                      }
                    }}
                    className="w-full h-12 font-flexo rounded-full pl-16 pr-10 p-2.5 text-BCP-dark-gray text-md bg-white outline-none"
                    disabled={isLoading}
                  />
                </div>
                <span className="absolute inset-y-0 pt-2 left-1 flex items-center pl-4 pb-1">
                  <SearchIcon className="w-8 h-8" color="#0a47f0" />
                </span>
                {search && (
                  <button
                    onClick={handleReset}
                    className="absolute inset-y-0 right-1 flex items-center mr-4"
                    aria-label="Reset search"
                    disabled={isLoading}
                  >
                    <CloseIcon className="w-6 h-6" />
                  </button>
                )}
              </span>
            </div>
            <button
              className={`w-[54px] h-[54px] pr-[0.1rem] pt-[0.1rem] ml-3 flex-shrink-0 rounded-full bg-gradient-to-tr from-[#0026C9] to-[#E21732] ${
                suggestionsEnabled ? "block" : "hidden"
              }`}
              type="submit"
              disabled={isLoading}
            >
              <SearchSubmitButton className="w-6 h-6 m-auto" />
            </button>
          </form>
          {suggestionsEnabled && (
            <div
              style={{
                boxShadow:
                  window.innerWidth < 768
                    ? "1px -1px 4px rgba(0,0,0,0.05)"
                    : "",
              }}
              className={`absolute z-10 bg-white !-mt-1 
                ${
                  suggestionsEnabled && queryDisplayMode === "searchPage"
                    ? "right-[86px] md:right-[66px]"
                    : "right-0"
                }
                ${
                  suggestionsEnabled && queryDisplayMode === "responsePage"
                    ? "right-[90px] md:right-[66px]"
                    : "right-0"
                } md:left-0 
                ${
                  queryDisplayMode !== "searchPage"
                    ? "right-6 left-6 bottom-[6.5rem] md:bottom-auto rounded-t-3xl md:rounded-b-3xl md:rounded-t-none md:shadow-lg"
                    : "right-5 left-5 rounded-b-3xl shadow-lg"
                }`}
            >
              {suggestions.map((suggestion, index) => (
                <button
                  key={`suggestion-${index}`}
                  className={`flex-grow px-[3px] py-[0.8rem] w-full relative hover:bg-[#EBF4FF] group ${
                    index === suggestions.length - 1 &&
                    queryDisplayMode === "searchPage" &&
                    "rounded-b-3xl"
                  } ${
                    index === suggestions.length - 1 &&
                    queryDisplayMode === "responsePage" &&
                    "md:rounded-b-3xl rounded-b-none"
                  } ${
                    index === 0 &&
                    queryDisplayMode === "responsePage" &&
                    "rounded-t-3xl md:rounded-t-none"
                  }`}
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  <p
                    className={`font-flexo ml-16 mr-10 text-BCP-gray bg-white outline-none text-left group-hover:bg-[#EBF4FF] ${
                      index === suggestions.length - 1 &&
                      queryDisplayMode === "searchPage" &&
                      "rounded-b-3xl"
                    } ${
                      index === suggestions.length - 1 &&
                      queryDisplayMode === "responsePage" &&
                      "md:rounded-b-3xl rounded-b-none"
                    } ${
                      index === 0 &&
                      queryDisplayMode === "responsePage" &&
                      "rounded-t-3xl md:rounded-t-none"
                    }`}
                  >
                    {(() => {
                      const searchLower =
                        search
                          ?.normalize("NFD")
                          .replace(/\p{Diacritic}/gu, "")
                          .toLowerCase() || "";
                      const suggestionLower = suggestion
                        .normalize("NFD")
                        .replace(/\p{Diacritic}/gu, "")
                        .toLowerCase();
                      const index = suggestionLower.indexOf(searchLower);

                      if (index === -1 || !search) {
                        return suggestion;
                      }

                      return (
                        <>
                          {suggestion.slice(0, index)}
                          <strong>
                            {suggestion.slice(index, index + search.length)}
                          </strong>
                          {suggestion.slice(index + search.length)}
                        </>
                      );
                    })()}
                  </p>
                  <span className="absolute inset-y-0 pt-1 left-1 flex items-center pl-4 pb-1">
                    <SearchIcon className="w-6 h-6" color="#4D5B70" />
                  </span>
                  <AngleRight className="absolute right-4 top-[1.1rem] w-5 h-5" />
                  {index !== suggestions.length - 1 && (
                    <div className="absolute bottom-0 left-5 right-5 h-[1px] bg-[#E5E7EB]" />
                  )}
                </button>
              ))}
            </div>
          )}
          <div
            className={`${
              suggestionsEnabled ? "blur-sm" : ""
            } flex justify-center mt-2`}
          >
            <PoweredByAIFindr width="124" height="14" />
          </div>
          {renderSearchPageComponents()}
        </div>
      </div>

      {renderSuggestedQueries()}
    </div>
  );
};

export default SearchBar;
