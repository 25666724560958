import React, { useEffect } from "react";
import recommendations from "../../assets/recommendations.json";
import { useSearch } from "../../context/SearchContext";
import { logException } from "../../utils/logger";
import RecommendationsDashboardButton from "../atoms/RecommendationsDashboardButton";

const DEFAULT_RECOMMENDATIONS = {
  n: 3,
  randomSelectionEnabled: false,
  labels: [
    "¿Cómo abrir mi primera cuenta de ahorro en BCP?",
    "¿Cómo cambiar soles y dólares?",
    "He perdido mi tarjeta de crédito, ¿qué debo hacer?",
  ],
};

interface SuggestedQueriesProps {
  onQueryClick: (query: string) => void;
  onMoreQuestionsClick: () => void;
  onProductosPreaprobadosClick: () => void;
}

const SuggestedQueries: React.FC<SuggestedQueriesProps> = ({
  onQueryClick,
  onMoreQuestionsClick,
  onProductosPreaprobadosClick,
}) => {
  const { suggestedQueries, setSuggestedQueries } = useSearch();

  const processAndSetSuggestions = (data: {
    n: number;
    labels: string[];
    randomSelectionEnabled: boolean;
  }) => {
    const { n, labels, randomSelectionEnabled } = data;

    if (!n || !Array.isArray(labels)) {
      const errorMessage =
        "Invalid data structure in JSON with suggested queries.";
      logException(errorMessage);
      throw new Error(errorMessage);
    }

    let selectedLabels = labels;

    if (randomSelectionEnabled) {
      const shuffledLabels = [...labels].sort(() => 0.5 - Math.random());
      selectedLabels = shuffledLabels.slice(0, n);
    } else {
      selectedLabels = labels.slice(0, n);
    }

    setSuggestedQueries(selectedLabels);
  };

  useEffect(() => {
    try {
      processAndSetSuggestions(recommendations);
    } catch (error) {
      console.error("Error loading recommendations from JSON:", error);
      processAndSetSuggestions(DEFAULT_RECOMMENDATIONS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="space-y-4">
      <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
        <RecommendationsDashboardButton
          format="large"
          icon="dashboard-card"
          iconBgColor="#FFF6E6"
          label="Quiero conocer mis productos preaprobados"
          onClick={() => onProductosPreaprobadosClick()}
        />
        {/* todo: remove suggested queries */}
        {suggestedQueries[0] && (
          <RecommendationsDashboardButton
            format="small"
            icon="bill"
            iconBgColor="#E8FCF9"
            label={"¿Qué necesito para solicitar un préstamo?"}
            onClick={() =>
              onQueryClick("¿Qué necesito para solicitar un préstamo?")
            }
          />
        )}
        {suggestedQueries[1] && (
          <RecommendationsDashboardButton
            format="small"
            icon="piggy-bank"
            iconBgColor="#FFF6E6"
            label={"¿Cómo abrir mi primera cuenta en BCP?"}
            onClick={() =>
              onQueryClick("¿Cómo abrir mi primera cuenta en BCP?")
            }
          />
        )}
        {suggestedQueries[2] && (
          <RecommendationsDashboardButton
            format="small"
            icon="credit-card"
            iconBgColor="#ECE4FC"
            label={"¿Qué hacer si perdí mi tarjeta de crédito?"}
            onClick={() =>
              onQueryClick("¿Qué hacer si perdí mi tarjeta de crédito?")
            }
          />
        )}
        <RecommendationsDashboardButton
          format="small"
          icon="compass"
          iconBgColor="white"
          label={"Explora más preguntas"}
          onClick={() => onMoreQuestionsClick()}
        />
      </div>
    </div>
  );
};

export default SuggestedQueries;
