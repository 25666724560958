import { FC } from "react";

interface PlusIconProps {
  className?: string;
}

export const PlusIcon: FC<PlusIconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="p/ic_plus">
        <path
          id="color&#240;&#159;&#142;&#168;"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19 11H13V5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5V11H5C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13H11V19C11 19.5523 11.4477 20 12 20C12.5523 20 13 19.5523 13 19V13H19C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11Z"
          fill="#264B78"
        />
      </g>
    </svg>
  );
};

export default PlusIcon;
