import { FC } from "react";

interface HandCardProps {
  className?: string;
}

export const HandCard: FC<HandCardProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="66"
      height="66"
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_16182_5208)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M57.723 48.2315C57.4752 48.6303 57.2197 49.0253 56.9564 49.4125C56.6118 49.9159 56.2517 50.4077 55.8723 50.8879C55.0437 51.9489 54.1416 52.9479 53.1775 53.8811C50.0647 56.8899 46.282 59.2093 42.0734 60.5956C41.0358 60.9364 39.9672 61.2229 38.8792 61.4436C36.9782 61.8347 35.0113 62.04 32.9942 62.04C29.467 62.04 26.087 61.4127 22.9625 60.2587C16.8141 57.9935 11.6415 53.7069 8.25372 48.2044C5.53189 43.7861 3.95996 38.5741 3.95996 33.0019C3.95996 16.9591 16.9613 3.95996 32.998 3.95996C46.5995 3.95996 58.0172 13.3115 61.1727 25.9428C61.2617 26.3029 61.3469 26.6669 61.4244 27.0309C61.827 28.9593 62.04 30.9535 62.04 33.0019C62.04 38.5896 60.4642 43.8055 57.7268 48.2354L57.723 48.2315Z"
          fill="#8882F5"
        />
        <mask
          id="mask0_16182_5208"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="3"
          y="3"
          width="60"
          height="60"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M57.723 48.2315C57.4752 48.6303 57.2197 49.0253 56.9564 49.4125C56.6118 49.9159 56.2517 50.4077 55.8723 50.8879C55.0437 51.9489 54.1416 52.9479 53.1775 53.8811C50.0647 56.8899 46.282 59.2093 42.0734 60.5956C41.0358 60.9364 39.9672 61.2229 38.8792 61.4436C36.9782 61.8347 35.0113 62.04 32.9942 62.04C29.467 62.04 26.087 61.4127 22.9625 60.2587C16.8141 57.9935 11.6415 53.7069 8.25372 48.2044C5.53189 43.7861 3.95996 38.5741 3.95996 33.0019C3.95996 16.9591 16.9613 3.95996 32.998 3.95996C46.5995 3.95996 58.0172 13.3115 61.1727 25.9428C61.2617 26.3029 61.3469 26.6669 61.4244 27.0309C61.827 28.9593 62.04 30.9535 62.04 33.0019C62.04 38.5896 60.4642 43.8055 57.7268 48.2354L57.723 48.2315Z"
            fill="#8882F5"
          />
        </mask>
        <g mask="url(#mask0_16182_5208)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M57.695 61.186C43.2695 52.6602 40.4684 47.5026 40.1346 35.2369C40.0079 33.4216 39.5455 31.9541 38.112 31.64C36.6155 31.3118 35.4999 31.844 34.9637 33.2012C34.8455 33.5001 33.4966 36.608 34.338 41.9391C31.6742 40.3069 29.7475 38.0111 29.5348 33.9795C33.0611 33.1387 34.538 32.0779 34.9671 31.7044C34.9671 31.7044 37.2315 29.9057 35.5362 27.7167C35.3871 27.5498 35.2278 27.4223 35.0646 27.3117C35.9589 26.5332 36.4494 25.7559 36.5981 25.5495C37.4545 24.3636 37.0827 22.6668 35.8227 21.7996C34.561 20.9331 32.7814 21.1441 31.9236 22.3289C30.4103 24.4205 27.2659 28.0756 25.2737 28.9763C24.9322 29.1314 24.635 29.302 24.3576 29.4786C21.7223 29.993 19.8408 31.7513 19.3771 34.1938C18.9199 34.4017 18.4836 34.6675 18.0791 35.004C16.1378 36.6212 15.9382 38.703 16.1565 40.3238C16.1639 40.3765 16.1709 40.4285 16.1793 40.4803C14.0813 43.9311 17.3535 50.8147 17.3535 50.8147C21.0803 58.6753 23.5311 62.4296 27.6632 68.5551C41.1692 73.8918 51.6925 68.8281 51.6925 68.8281"
            fill="#FFC8E9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M34.5213 41.7432C34.6303 41.7727 34.6952 41.8839 34.6663 41.9913L34.5626 42.3986L34.4392 42.9165L34.3115 43.4867C34.0666 44.6181 33.8646 45.7664 33.7366 46.8606C33.5922 48.0931 33.5517 49.1782 33.6358 50.0636C33.6463 50.1746 33.564 50.2721 33.4516 50.2814C33.3395 50.2906 33.2399 50.208 33.2294 50.0971C33.1422 49.1793 33.1837 48.068 33.3311 46.8099C33.4608 45.7016 33.6649 44.5413 33.9124 43.3982L34.0412 42.8223L34.2185 42.0891L34.2718 41.8838C34.3006 41.7763 34.4123 41.7134 34.5213 41.7432Z"
            fill="#003CE5"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.0166 34.5765C20.4914 31.9042 22.5907 29.9904 25.4956 29.5825C30.2422 28.9153 31.8308 27.7693 31.8459 27.7577C31.7823 27.8049 31.7554 27.8329 31.7554 27.8329C31.7554 27.8329 34.4346 25.9742 36.0396 27.8114C37.692 29.9981 35.442 31.8285 35.442 31.8285C34.8858 32.3221 32.2728 34.067 26.036 34.9445"
            fill="#FFC8E9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29.7972 34.0216C29.9028 33.9949 30.0116 34.0579 30.0402 34.1622C30.0687 34.2666 30.0064 34.3728 29.9008 34.3995C28.7401 34.693 27.4639 34.9414 26.0668 35.1378C25.9588 35.1531 25.8574 35.0788 25.8404 34.9723C25.8235 34.8656 25.8974 34.7668 26.0055 34.7517C27.3885 34.5572 28.6507 34.3115 29.7972 34.0216Z"
            fill="#003CE5"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M35.5422 27.1189C35.6354 27.1765 35.6643 27.2971 35.607 27.388C35.5496 27.479 35.4277 27.5058 35.3346 27.4481C34.5196 26.9426 33.7639 27.0088 32.7606 27.5082L32.5192 27.634L31.6661 28.1062L31.3451 28.277L31.0309 28.435C30.4062 28.7393 29.7941 28.9737 29.0348 29.1725L28.6422 29.2691C28.5744 29.2847 28.5056 29.3002 28.4353 29.3155L27.9975 29.4048C27.846 29.434 27.6883 29.4626 27.5241 29.4906L27.0106 29.5738L26.4527 29.6553L25.8457 29.736C25.7401 29.7494 25.6324 29.7629 25.5225 29.7764C25.414 29.7896 25.3141 29.7137 25.2991 29.6068C25.2841 29.4999 25.3598 29.4025 25.4681 29.3892L26.0915 29.3098L26.6637 29.2304L27.1892 29.1501L27.6725 29.0676L28.1179 28.9825C28.6886 28.8665 29.1702 28.7404 29.6103 28.594L29.9334 28.4803C29.9862 28.4607 30.0385 28.4406 30.0905 28.4203L30.3985 28.2933C30.4493 28.2714 30.5 28.249 30.5505 28.2261L30.8535 28.0839L31.1587 27.9303C31.2099 27.9036 31.2616 27.8765 31.3135 27.8489L31.7651 27.6032L32.3888 27.2568C33.595 26.6153 34.5302 26.4912 35.5422 27.1189Z"
            fill="#003CE5"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M28.1646 44.1199C28.7573 44.1708 29.6156 44.4436 30.4939 43.8133C31.6982 42.9482 32.1273 41.3776 31.2004 40.1051C30.542 39.2012 27.1952 34.5679 23.1778 34.013C21.5403 33.7872 20.0101 34.2339 18.7546 35.304L21.3501 41.222L28.1646 44.1199Z"
            fill="#FFC8E9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.2025 33.8198C23.9346 33.921 24.6694 34.1561 25.4045 34.5122C26.8498 35.2125 28.2353 36.3429 29.5383 37.7538C29.9537 38.2036 30.3399 38.6599 30.7025 39.1199L31.0268 39.5401L31.2391 39.8264C31.2774 39.8784 31.3175 39.9335 31.3608 39.9928C32.3243 41.3157 31.9838 42.93 30.6161 43.9684C29.8248 44.5687 28.8955 44.6176 28.0931 44.3006C27.9911 44.2603 27.9405 44.1467 27.98 44.0468C28.0195 43.9469 28.1341 43.8986 28.236 43.9388C28.9175 44.2081 29.7011 44.1668 30.3715 43.6581C31.5802 42.7405 31.8716 41.3588 31.0401 40.2172L30.8118 39.9059L30.6099 39.6378L30.3911 39.3563C30.0346 38.9042 29.655 38.4556 29.2471 38.0141C27.9753 36.637 26.6268 35.5366 25.2337 34.8618C24.5353 34.5234 23.8407 34.3011 23.1531 34.2061C22.24 34.0807 21.3609 34.1706 20.5442 34.4711C20.4422 34.5086 20.3275 34.4572 20.2879 34.3562C20.2484 34.2553 20.299 34.143 20.401 34.1055C21.2796 33.7822 22.2249 33.6854 23.2025 33.8198Z"
            fill="#003CE5"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.9235 40.6613C17.6079 39.6178 18.6762 39.0014 19.9322 38.9262C22.945 38.7447 26.3839 42.1019 28.1644 44.1199C29.1578 45.244 29.0478 46.933 27.9196 47.8914C26.7916 48.8492 25.3989 48.8078 24.0795 47.5912C23.2208 46.6778 22.2085 45.6606 21.6708 45.2765C21.133 44.893 20.261 45.5205 20.7702 46.2552"
            fill="#FFC8E9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.9172 38.7315C22.4879 38.5769 25.4437 40.7427 28.3136 43.9933C29.3788 45.1993 29.261 47.0118 28.0501 48.0398C26.8591 49.0519 25.4115 48.9951 24.0913 47.8627L23.9504 47.7372C23.6652 47.4918 23.3753 47.2237 23.0638 46.9207L21.7982 45.6575L21.5921 45.4598C21.1704 45.0816 20.5706 45.6238 20.9333 46.1468C20.9954 46.2363 20.9727 46.3575 20.8827 46.4173C20.7925 46.4772 20.6693 46.4533 20.6072 46.3637C19.9837 45.4643 21.0781 44.4751 21.8563 45.173L22.0687 45.3763L23.3401 46.6451C23.5695 46.8684 23.7866 47.072 24.0007 47.2628L24.2138 47.4498C25.4322 48.5738 26.713 48.6572 27.7891 47.7429C28.8351 46.8548 28.9368 45.289 28.0162 44.247C25.228 41.0887 22.3456 38.9765 19.9469 39.1208C18.7863 39.1906 17.7964 39.7445 17.1446 40.6885C17.0835 40.7771 16.9604 40.7989 16.8697 40.7376C16.7791 40.6761 16.7551 40.5545 16.8161 40.4661C17.5357 39.424 18.6356 38.8085 19.9172 38.7315Z"
            fill="#003CE5"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M47.4887 36.5955C47.4978 36.4972 47.555 35.6188 46.619 35.5192C46.4615 35.5024 46.3476 35.361 46.3643 35.2035C46.3811 35.046 46.5223 34.9315 46.6798 34.9482C47.6146 35.0477 47.7444 34.1792 47.7565 34.0782C47.7738 33.9213 47.9146 33.8075 48.0719 33.8243C48.2281 33.8409 48.3412 33.9795 48.3274 34.1348L48.3271 34.1381C48.3173 34.2401 48.2614 35.1165 49.196 35.216C49.3535 35.2328 49.468 35.3745 49.4512 35.532C49.4345 35.6895 49.2928 35.8037 49.1352 35.7869C48.1901 35.6864 48.0697 36.5567 48.0591 36.6562C48.0424 36.8137 47.9009 36.9279 47.7434 36.9112C47.5859 36.8944 47.4719 36.753 47.4887 36.5955Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.9887 32.6966C11.9941 32.6379 12.0283 32.1139 11.47 32.0545C11.376 32.0445 11.308 31.9602 11.318 31.8662C11.328 31.7723 11.4123 31.704 11.5062 31.714C12.0639 31.7733 12.1413 31.2552 12.1485 31.195C12.1588 31.1014 12.2428 31.0335 12.3366 31.0435C12.4298 31.0534 12.4973 31.1361 12.489 31.2288L12.4888 31.2307C12.483 31.2916 12.4497 31.8144 13.0071 31.8737C13.1011 31.8837 13.1694 31.9682 13.1594 32.0622C13.1494 32.1561 13.0649 32.2242 12.9709 32.2142C12.4071 32.1543 12.3353 32.6734 12.329 32.7328C12.319 32.8267 12.2346 32.8949 12.1407 32.8849C12.0467 32.8749 11.9787 32.7905 11.9887 32.6966Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M46.9687 13.631C46.9741 13.5724 47.0082 13.0484 46.45 12.989C46.356 12.979 46.288 12.8946 46.298 12.8007C46.308 12.7067 46.3923 12.6384 46.4862 12.6484C47.0438 12.7078 47.1213 12.1897 47.1285 12.1294C47.1388 12.0358 47.2228 11.968 47.3166 11.978C47.4097 11.9879 47.4773 12.0706 47.469 12.1632L47.4688 12.1652C47.463 12.226 47.4296 12.7488 47.9871 12.8081C48.0811 12.8181 48.1494 12.9027 48.1394 12.9966C48.1294 13.0906 48.0448 13.1587 47.9509 13.1487C47.3871 13.0887 47.3153 13.6079 47.309 13.6672C47.299 13.7612 47.2146 13.8293 47.1206 13.8193C47.0267 13.8093 46.9587 13.725 46.9687 13.631Z"
            fill="white"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.9876 41.3932L14.4741 14.9981C13.9162 14.1061 14.182 12.9298 15.0683 12.3705L30.5157 2.62435C31.4018 2.06538 32.5724 2.3352 33.1307 3.22747L49.644 29.6219C50.2021 30.5142 49.9363 31.6908 49.0502 32.2497L33.6026 41.996C32.7165 42.5552 31.5458 42.2851 30.9876 41.3932Z"
          fill="#3749EF"
        />
        <mask
          id="mask1_16182_5208"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="14"
          y="2"
          width="37"
          height="41"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M50.1081 29.3307L33.569 2.93068C33.0186 2.05216 31.8644 1.78671 30.9914 2.33814L15.4515 12.1566C14.5786 12.7083 14.317 13.8677 14.8678 14.7465L31.4071 41.1461C31.9576 42.0252 33.1115 42.2905 33.9844 41.7387L49.5244 31.9206C50.1995 31.4941 50.5086 30.7043 50.3596 29.9615C50.316 29.7439 50.2328 29.5299 50.1081 29.3307Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask1_16182_5208)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.335 46.9961L10.9166 12.8085L33.0022 -1.14545L54.4206 33.0421L32.335 46.9961Z"
            fill="#005CFF"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.4803 21.2488L28.106 17.4586C27.9163 17.1559 28.0061 16.7569 28.3066 16.5672L29.7954 15.6267C30.0959 15.437 30.493 15.5282 30.6826 15.8306L33.0569 19.6208C33.2463 19.9233 33.1564 20.3223 32.856 20.512L31.3672 21.4528C31.0671 21.6424 30.6696 21.551 30.4803 21.2488Z"
          fill="#0B7EFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.4251 14.5964L24.0508 10.8063C23.8611 10.5035 23.951 10.1046 24.2514 9.91486L25.7402 8.97431C26.0407 8.78461 26.4378 8.87583 26.6274 9.17827L29.0018 12.9684C29.1911 13.2709 29.1013 13.6699 28.8008 13.8596L27.3121 14.8005C27.0119 14.9901 26.6144 14.8986 26.4251 14.5964Z"
          fill="#0B7EFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.7025 27.9882L32.3278 24.198C32.1384 23.8952 32.2283 23.4963 32.5288 23.3066L34.0176 22.3663C34.318 22.1763 34.7151 22.2675 34.9045 22.57L37.2791 26.3602C37.4684 26.6627 37.3786 27.0616 37.0781 27.2513L35.5894 28.1922C35.2892 28.3818 34.8918 28.2907 34.7025 27.9882Z"
          fill="#0B7EFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.9246 34.7278L36.5503 30.9376C36.3606 30.6352 36.4505 30.2362 36.7509 30.0462L38.2398 29.1059C38.5402 28.9159 38.9373 29.0072 39.1269 29.3096L41.5013 33.1001C41.6906 33.4023 41.6008 33.8013 41.3003 33.9913L39.8116 34.9318C39.5114 35.1214 39.114 35.0303 38.9246 34.7278Z"
          fill="#0B7EFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.8264 21.8051L18.5084 16.5088C18.2586 16.1105 18.3773 15.5846 18.7733 15.3343L19.9705 14.5777C20.3665 14.3274 20.8899 14.4479 21.1397 14.8463L24.4577 20.1425C24.7072 20.5412 24.5888 21.0671 24.1931 21.3173L22.9954 22.074C22.5996 22.3239 22.0762 22.2037 21.8264 21.8051Z"
          fill="#0B7EFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.0166 34.5765C20.4914 31.9042 22.5907 29.9904 25.4956 29.5825C30.2422 28.9153 31.8308 27.7693 31.8459 27.7577C31.7823 27.8049 31.7554 27.8329 31.7554 27.8329C31.7554 27.8329 34.4346 25.9742 36.0396 27.8114C37.692 29.9981 35.442 31.8285 35.442 31.8285C34.8858 32.3221 32.2728 34.067 26.036 34.9445"
          fill="#FFC8E9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.7972 34.0216C29.9028 33.9949 30.0116 34.0579 30.0402 34.1622C30.0687 34.2666 30.0064 34.3728 29.9008 34.3995C28.7401 34.693 27.4639 34.9414 26.0668 35.1378C25.9588 35.1531 25.8574 35.0788 25.8404 34.9723C25.8235 34.8656 25.8974 34.7668 26.0055 34.7517C27.3885 34.5572 28.6507 34.3115 29.7972 34.0216Z"
          fill="#003CE5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.5422 27.1187C35.6354 27.1764 35.6643 27.297 35.607 27.3878C35.5496 27.4788 35.4277 27.5056 35.3346 27.448C34.5196 26.9425 33.7639 27.0087 32.7606 27.5081L32.5192 27.6339L31.6661 28.1061L31.3451 28.2769L31.0309 28.4349C30.4062 28.7392 29.7941 28.9736 29.0348 29.1724L28.6422 29.269C28.5744 29.2846 28.5056 29.3001 28.4353 29.3153L27.9975 29.4046C27.846 29.4339 27.6883 29.4624 27.5241 29.4905L27.0106 29.5737L26.4527 29.6552L25.8457 29.7359C25.7401 29.7493 25.6324 29.7627 25.5225 29.7763C25.414 29.7895 25.3141 29.7136 25.2991 29.6067C25.2841 29.4997 25.3598 29.4023 25.4681 29.3891L26.0915 29.3097L26.6637 29.2303L27.1892 29.15L27.6725 29.0675L28.1179 28.9824C28.6886 28.8664 29.1702 28.7403 29.6103 28.5939L29.9334 28.4801C29.9862 28.4606 30.0385 28.4404 30.0905 28.4202L30.3985 28.2932C30.4493 28.2713 30.5 28.2489 30.5505 28.226L30.8535 28.0837L31.1587 27.9301C31.2099 27.9035 31.2616 27.8764 31.3135 27.8488L31.7651 27.6031L32.3888 27.2567C33.595 26.6152 34.5302 26.491 35.5422 27.1187Z"
          fill="#003CE5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.1646 44.1199C28.7573 44.1708 29.6156 44.4436 30.4939 43.8133C31.6982 42.9482 32.1273 41.3776 31.2004 40.1051C30.542 39.2012 27.1952 34.5679 23.1778 34.013C21.5403 33.7872 20.0101 34.2339 18.7546 35.304L21.3501 41.222L28.1646 44.1199Z"
          fill="#FFC8E9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.2027 33.8198C23.9349 33.921 24.6697 34.1561 25.4047 34.5122C26.8501 35.2125 28.2355 36.3429 29.5385 37.7538C29.9539 38.2036 30.3401 38.6599 30.7027 39.1199L31.027 39.5401L31.2394 39.8264C31.2776 39.8784 31.3178 39.9335 31.361 39.9928C32.3246 41.3157 31.9841 42.93 30.6163 43.9684C29.8251 44.5687 28.8957 44.6176 28.0934 44.3006C27.9914 44.2603 27.9408 44.1467 27.9802 44.0468C28.0197 43.9469 28.1343 43.8986 28.2363 43.9388C28.9177 44.2081 29.7013 44.1668 30.3718 43.6581C31.5805 42.7405 31.8719 41.3588 31.0404 40.2172L30.812 39.9059L30.6102 39.6378L30.3913 39.3563C30.0348 38.9042 29.6552 38.4556 29.2473 38.0141C27.9755 36.637 26.627 35.5366 25.234 34.8618C24.5356 34.5234 23.841 34.3011 23.1533 34.2061C22.2403 34.0807 21.3612 34.1706 20.5444 34.4711C20.4424 34.5086 20.3277 34.4572 20.2882 34.3562C20.2486 34.2553 20.2992 34.143 20.4012 34.1055C21.2798 33.7822 22.2251 33.6854 23.2027 33.8198Z"
          fill="#003CE5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.9235 40.6612C17.6079 39.6176 18.6762 39.0013 19.9322 38.926C22.945 38.7446 26.3839 42.1018 28.1644 44.1198C29.1578 45.2439 29.0478 46.9329 27.9196 47.8913C26.7916 48.8491 25.3989 48.8077 24.0795 47.5911C23.2208 46.6777 22.2085 45.6604 21.6708 45.2764C21.133 44.8929 20.261 45.5204 20.7702 46.255"
          fill="#FFC8E9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.9172 38.7315C22.4879 38.5769 25.4437 40.7427 28.3136 43.9933C29.3788 45.1993 29.261 47.0118 28.0501 48.0398C26.8591 49.0519 25.4115 48.9951 24.0913 47.8627L23.9504 47.7372C23.6652 47.4918 23.3753 47.2237 23.0638 46.9207L21.7982 45.6575L21.5921 45.4598C21.1704 45.0816 20.5706 45.6238 20.9333 46.1468C20.9954 46.2363 20.9727 46.3575 20.8827 46.4173C20.7925 46.4772 20.6693 46.4533 20.6072 46.3637C19.9837 45.4643 21.0781 44.4751 21.8563 45.173L22.0687 45.3763L23.3401 46.6451C23.5695 46.8684 23.7866 47.072 24.0007 47.2628L24.2138 47.4498C25.4322 48.5738 26.713 48.6572 27.7891 47.7429C28.8351 46.8548 28.9368 45.289 28.0162 44.247C25.228 41.0887 22.3456 38.9765 19.9469 39.1208C18.7863 39.1906 17.7964 39.7445 17.1446 40.6885C17.0835 40.7771 16.9604 40.7989 16.8697 40.7376C16.7791 40.6761 16.7551 40.5545 16.8161 40.4661C17.5357 39.424 18.6356 38.8085 19.9172 38.7315Z"
          fill="#003CE5"
        />
      </g>
      <defs>
        <clipPath id="clip0_16182_5208">
          <rect width="66" height="66" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HandCard;
