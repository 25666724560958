import { FC } from "react";

interface ThumbsUpGreenIconProps {
  className?: string;
}

export const ThumbsUpGreenIcon: FC<ThumbsUpGreenIconProps> = ({
  className,
}) => {
  return (
    <div className={className}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Back arrow">
          <path
            id="color&#240;&#159;&#142;&#168;"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 21C2.44772 21 2 20.5523 2 20V12C2 11.4477 2.44772 11 3 11H7C9 9.66667 10 6.66667 10 5C10 3 11.5 2 13 2C14.5 2 16 3 16 5V9H21C21.5523 9 22 9.44772 22 10V21C22 21.5523 21.5523 22 21 22H10.6401C10.5471 22 10.4547 21.987 10.3653 21.9615L7 21H3ZM13 4C12.368 4 12 4.32714 12 5C12 7.11588 10.9436 10.4659 9 12.0158V19.4914L10.7801 20H20V11H16C14.8954 11 14 10.1046 14 9V5C14 4.32714 13.632 4 13 4Z"
            fill="#37A500"
          />
        </g>
      </svg>
    </div>
  );
};
