import { FC } from "react";

interface LockIconProps {
  className?: string;
}

export const LockIcon: FC<LockIconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="l/ic_lock">
        <path
          id="color&#240;&#159;&#142;&#168;"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 2.66669C19.6734 2.66669 22.6666 5.65627 22.6666 9.33335V12H25.3333C26.0697 12 26.6666 12.597 26.6666 13.3334V28C26.6666 28.7364 26.0697 29.3334 25.3333 29.3334H6.66665C5.93027 29.3334 5.33331 28.7364 5.33331 28V13.3334C5.33331 12.597 5.93027 12 6.66665 12H9.33331V9.33335C9.33331 5.65544 12.3249 2.66669 16 2.66669ZM24 14.6667H7.99998V26.6667H24V14.6667ZM16 18C16.7364 18 17.3333 18.597 17.3333 19.3334V22C17.3333 22.7364 16.7364 23.3334 16 23.3334C15.2636 23.3334 14.6666 22.7364 14.6666 22V19.3334C14.6666 18.597 15.2636 18 16 18ZM16 5.33335C13.7894 5.33335 12 7.12336 12 9.33335V12H20V9.33335C20 7.12277 18.211 5.33335 16 5.33335Z"
          fill="#0A47F0"
        />
      </g>
    </svg>
  );
};

export default LockIcon;
