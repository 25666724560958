import React, { useEffect } from "react";

interface FloatingLabelInputProps {
  value: string;
  onChange: (value: string) => void;
  label: string;
  id: string;
  isError?: boolean;
  maxLength?: number;
}

export const FloatingLabelInput: React.FC<FloatingLabelInputProps> = ({
  value,
  onChange,
  label,
  id,
  maxLength,
  isError,
}) => {
  useEffect(() => {
    console.log("isError", isError);
  }, [isError]);

  return (
    <div className="relative">
      <input
        id={id}
        type="text"
        value={value}
        maxLength={maxLength}
        onChange={(e) => onChange(e.target.value)}
        className={`block px-4 pb-2.5 pt-4 w-full text-BCP-normal-text bg-white border focus:border-2 ${
          isError
            ? "border-BCP-error focus:border-BCP-error"
            : "border-BCP-gray-border focus:border-BCP-blue-selected-text"
        } rounded-lg appearance-none focus:outline-none focus:ring-0 peer focus:pb-[9px] focus:pt-[15px] focus:px-[15px]`}
        placeholder=" "
      />
      <label
        htmlFor={id}
        className={`absolute text-sm duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1  ${
          isError
            ? "text-BCP-error peer-focus:text-BCP-error"
            : "text-BCP-dark-gray peer-focus:text-BCP-blue-selected-text"
        }`}
      >
        {label}
      </label>
    </div>
  );
};
