import { Product } from "../context/SearchContext";
import {
  AnalyticsPayload,
  submitAnalytics,
} from "../services/AnalyticsService";
import { AnswerComponent } from "../services/AnswerComponentsService";
import { Source } from "../services/PipelineService";

export const sendInitialSearchClick = (
  query: string,
  requestId: string,
  contextId: string,
  suggestedQueries: string[],
  agentId: string,
) => {
  const payload: AnalyticsPayload = {
    action: "search:initial",
    requestId,
    contextId,
    query,
    description: "Click en botón buscar inicial",
    datetime: new Date().toISOString(),
    suggestedQueries,
    agentId: agentId,
  };
  parent.postMessage(payload, "*");
  submitAnalytics(payload);
};

export const sendInternalSearchClick = (
  query: string,
  requestId: string,
  contextId: string,
  suggestedQueries: string[],
  agentId: string,
) => {
  const payload: AnalyticsPayload = {
    action: "search:internal",
    requestId,
    contextId,
    query,
    description: "Click en botón buscar interno",
    datetime: new Date().toISOString(),
    suggestedQueries,
    agentId: agentId,
  };
  parent.postMessage(payload, "*");
  submitAnalytics(payload);
};

export const sendSearchRefresh = (
  query: string,
  requestId: string,
  contextId: string,
) => {
  const payload: AnalyticsPayload = {
    action: "search:refresh",
    requestId,
    contextId,
    query,
    description: "Click en refresh de AI",
    datetime: new Date().toISOString(),
  };
  parent.postMessage(payload, "*");
  submitAnalytics(payload);
};

export const sendSuggestionClick = (
  query: string,
  requestId: string,
  contextId: string,
  suggestedQueries: string[],
  agentId: string,
) => {
  const payload: AnalyticsPayload = {
    action: "search:suggestion",
    requestId,
    contextId,
    query,
    description: "Click en sugerencia de búsqueda",
    datetime: new Date().toISOString(),
    suggestedQueries,
    agentId: agentId,
  };
  parent.postMessage(payload, "*");
  submitAnalytics(payload);
};

export const sendSearchResponse = (
  query: string,
  requestId: string,
  textResponse: string,
  sources: Source[],
  duration: number,
  contextId: string,
  products: Product[],
  agentId: string,
  answerComponents: AnswerComponent[],
) => {
  const payload: AnalyticsPayload = {
    action: "search:response",
    requestId,
    contextId,
    query,
    textResponse,
    sources,
    duration,
    description: "Respuesta final de búsqueda generada",
    datetime: new Date().toISOString(),
    products,
    agentId: agentId,
    answerComponents,
  };
  parent.postMessage(payload, "*");
  submitAnalytics(payload);
};

export const sendUserFeedback = (
  requestId: string,
  query: string,
  feedbackCategory: string | null,
  selectedOptions: string[],
  comment: string,
  contextId: string,
) => {
  const payload: AnalyticsPayload = {
    action: "feedback:submit",
    requestId,
    contextId,
    query,
    feedbackCategory,
    selectedOptions,
    comment,
    description: "Feedback del usuario enviado",
    datetime: new Date().toISOString(),
  };
  parent.postMessage(payload, "*");
  submitAnalytics(payload);
};

export const sendUserFeedbackSelected = (
  requestId: string,
  query: string,
  feedbackCategory: string | null,
  contextId: string,
) => {
  const payload: AnalyticsPayload = {
    action: "feedback:select",
    requestId,
    contextId,
    query,
    feedbackCategory,
    description: "Usuario selecciona categoría de feedback",
    datetime: new Date().toISOString(),
  };

  submitAnalytics(payload);
};

export const sendResponseLinkClick = (
  query: string,
  requestId: string,
  href: string,
  hrefText: string,
  contextId: string,
) => {
  const payload: AnalyticsPayload = {
    action: "response:linkClick",
    requestId,
    contextId,
    query,
    href,
    hrefText,
    description: "Click en enlace de respuesta",
    datetime: new Date().toISOString(),
  };
  parent.postMessage(payload, "*");
  submitAnalytics(payload);
};

export const sendSourceLinkClick = (
  query: string,
  requestId: string,
  href: string,
  hrefText: string,
  contextId: string,
) => {
  const payload: AnalyticsPayload = {
    action: "source:linkClick",
    requestId,
    contextId,
    query,
    href,
    hrefText,
    description: "Click en enlace de fuente",
    datetime: new Date().toISOString(),
  };
  parent.postMessage(payload, "*");
  submitAnalytics(payload);
};

export const sendSearchOpened = (contextId: string) => {
  const payload: AnalyticsPayload = {
    action: "search:open",
    contextId,
    description: "Buscador abierto",
    datetime: new Date().toISOString(),
  };

  submitAnalytics(payload);
};

export const sendSuggestedQueries = (
  contextId: string,
  suggestedQueries: string[],
) => {
  const payload: AnalyticsPayload = {
    action: "search:suggestedQueries",
    contextId,
    description: "Sugerencias de búsqueda mostradas",
    datetime: new Date().toISOString(),
    suggestedQueries,
  };

  submitAnalytics(payload);
};
