import { FC } from "react";

interface HandCardArrowUpProps {
  className?: string;
}

export const HandCardArrowUp: FC<HandCardArrowUpProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="66"
      height="66"
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_16182_5034)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M57.723 48.2315C57.4752 48.6303 57.2197 49.0253 56.9564 49.4125C56.6118 49.9159 56.2517 50.4077 55.8723 50.8879C55.0437 51.9489 54.1416 52.9479 53.1775 53.8811C50.0647 56.8899 46.282 59.2093 42.0734 60.5956C41.0358 60.9364 39.9672 61.2229 38.8792 61.4436C36.9782 61.8347 35.0113 62.04 32.9942 62.04C29.467 62.04 26.087 61.4127 22.9625 60.2587C16.8141 57.9935 11.6415 53.7069 8.25372 48.2044C5.53189 43.7861 3.95996 38.5741 3.95996 33.0019C3.95996 16.9591 16.9613 3.95996 32.998 3.95996C46.5995 3.95996 58.0172 13.3115 61.1727 25.9428C61.2617 26.3029 61.3469 26.6669 61.4244 27.0309C61.827 28.9593 62.04 30.9535 62.04 33.0019C62.04 38.5896 60.4642 43.8055 57.7268 48.2354L57.723 48.2315Z"
          fill="#8882F5"
        />
        <mask
          id="mask0_16182_5034"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="3"
          y="3"
          width="60"
          height="60"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M57.723 48.2315C57.4752 48.6303 57.2197 49.0253 56.9564 49.4125C56.6118 49.9159 56.2517 50.4077 55.8723 50.8879C55.0437 51.9489 54.1416 52.9479 53.1775 53.8811C50.0647 56.8899 46.282 59.2093 42.0734 60.5956C41.0358 60.9364 39.9672 61.2229 38.8792 61.4436C36.9782 61.8347 35.0113 62.04 32.9942 62.04C29.467 62.04 26.087 61.4127 22.9625 60.2587C16.8141 57.9935 11.6415 53.7069 8.25372 48.2044C5.53189 43.7861 3.95996 38.5741 3.95996 33.0019C3.95996 16.9591 16.9613 3.95996 32.998 3.95996C46.5995 3.95996 58.0172 13.3115 61.1727 25.9428C61.2617 26.3029 61.3469 26.6669 61.4244 27.0309C61.827 28.9593 62.04 30.9535 62.04 33.0019C62.04 38.5896 60.4642 43.8055 57.7268 48.2354L57.723 48.2315Z"
            fill="#8882F5"
          />
        </mask>
        <g mask="url(#mask0_16182_5034)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M57.695 61.1859C43.2695 52.6601 40.4684 47.5024 40.1346 35.2367C40.0079 33.4214 39.5455 31.954 38.112 31.6399C36.6155 31.3117 35.4999 31.8439 34.9637 33.201C34.8455 33.5 33.4966 36.6079 34.338 41.9389C31.6742 40.3068 29.7475 38.011 29.5348 33.9794C33.0611 33.1386 34.538 32.0778 34.9671 31.7043C34.9671 31.7043 37.2315 29.9056 35.5362 27.7166C35.3871 27.5496 35.2278 27.4222 35.0646 27.3116C35.9589 26.533 36.4494 25.7558 36.5981 25.5494C37.4545 24.3635 37.0827 22.6666 35.8227 21.7995C34.561 20.933 32.7814 21.144 31.9236 22.3287C30.4103 24.4204 27.2659 28.0755 25.2737 28.9762C24.9322 29.1313 24.635 29.3018 24.3576 29.4785C21.7223 29.9929 19.8408 31.7512 19.3771 34.1937C18.9199 34.4015 18.4836 34.6674 18.0791 35.0039C16.1378 36.6211 15.9382 38.7028 16.1565 40.3237C16.1639 40.3764 16.1709 40.4284 16.1793 40.4802C14.0813 43.931 17.3535 50.8146 17.3535 50.8146C21.0803 58.6752 23.5311 62.4295 27.6632 68.5549C41.1692 73.8917 51.6925 68.828 51.6925 68.828"
            fill="#FFC8E9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M34.5213 41.743C34.6303 41.7726 34.6952 41.8838 34.6663 41.9912L34.5626 42.3985L34.4392 42.9164L34.3115 43.4865C34.0666 44.618 33.8646 45.7663 33.7366 46.8605C33.5922 48.093 33.5517 49.1781 33.6358 50.0634C33.6463 50.1745 33.564 50.272 33.4516 50.2812C33.3395 50.2905 33.2399 50.2078 33.2294 50.0969C33.1422 49.1792 33.1837 48.0679 33.3311 46.8097C33.4608 45.7015 33.6649 44.5412 33.9124 43.398L34.0412 42.8222L34.2185 42.0889L34.2718 41.8836C34.3006 41.7762 34.4123 41.7133 34.5213 41.743Z"
            fill="#003CE5"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.0166 34.5764C20.4914 31.9041 22.5907 29.9902 25.4956 29.5823C30.2422 28.9152 31.8308 27.7692 31.8459 27.7576C31.7823 27.8047 31.7554 27.8328 31.7554 27.8328C31.7554 27.8328 34.4346 25.9741 36.0396 27.8113C37.692 29.998 35.442 31.8284 35.442 31.8284C34.8858 32.322 32.2728 34.0669 26.036 34.9444"
            fill="#FFC8E9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29.7972 34.0216C29.9028 33.9949 30.0116 34.0579 30.0402 34.1622C30.0687 34.2666 30.0064 34.3728 29.9008 34.3995C28.7401 34.693 27.4639 34.9414 26.0668 35.1378C25.9588 35.1531 25.8574 35.0788 25.8404 34.9723C25.8235 34.8656 25.8974 34.7668 26.0055 34.7517C27.3885 34.5572 28.6507 34.3115 29.7972 34.0216Z"
            fill="#003CE5"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M35.5422 27.1187C35.6354 27.1764 35.6643 27.297 35.607 27.3878C35.5496 27.4788 35.4277 27.5056 35.3346 27.448C34.5196 26.9425 33.7639 27.0087 32.7606 27.5081L32.5192 27.6339L31.6661 28.1061L31.3451 28.2769L31.0309 28.4349C30.4062 28.7392 29.7941 28.9736 29.0348 29.1724L28.6422 29.269C28.5744 29.2846 28.5056 29.3001 28.4353 29.3153L27.9975 29.4046C27.846 29.4339 27.6883 29.4624 27.5241 29.4905L27.0106 29.5737L26.4527 29.6552L25.8457 29.7359C25.7401 29.7493 25.6324 29.7627 25.5225 29.7763C25.414 29.7895 25.3141 29.7136 25.2991 29.6067C25.2841 29.4997 25.3598 29.4023 25.4681 29.3891L26.0915 29.3097L26.6637 29.2303L27.1892 29.15L27.6725 29.0675L28.1179 28.9824C28.6886 28.8664 29.1702 28.7403 29.6103 28.5939L29.9334 28.4801C29.9862 28.4606 30.0385 28.4404 30.0905 28.4202L30.3985 28.2932C30.4493 28.2713 30.5 28.2489 30.5505 28.226L30.8535 28.0837L31.1587 27.9301C31.2099 27.9035 31.2616 27.8764 31.3135 27.8488L31.7651 27.6031L32.3888 27.2567C33.595 26.6152 34.5302 26.491 35.5422 27.1187Z"
            fill="#003CE5"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M28.1646 44.1196C28.7573 44.1705 29.6156 44.4433 30.4939 43.8131C31.6982 42.948 32.1273 41.3773 31.2004 40.1049C30.542 39.201 27.1952 34.5677 23.1778 34.0128C21.5403 33.787 20.0101 34.2337 18.7546 35.3038L21.3501 41.2218L28.1646 44.1196Z"
            fill="#FFC8E9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.2025 33.8198C23.9346 33.921 24.6694 34.1561 25.4045 34.5122C26.8498 35.2125 28.2353 36.3429 29.5383 37.7538C29.9537 38.2036 30.3399 38.6599 30.7025 39.1199L31.0268 39.5401L31.2391 39.8264C31.2774 39.8784 31.3175 39.9335 31.3608 39.9928C32.3243 41.3157 31.9838 42.93 30.6161 43.9684C29.8248 44.5687 28.8955 44.6176 28.0931 44.3006C27.9911 44.2603 27.9405 44.1467 27.98 44.0468C28.0195 43.9469 28.1341 43.8986 28.236 43.9388C28.9175 44.2081 29.7011 44.1668 30.3715 43.6581C31.5802 42.7405 31.8716 41.3588 31.0401 40.2172L30.8118 39.9059L30.6099 39.6378L30.3911 39.3563C30.0346 38.9042 29.655 38.4556 29.2471 38.0141C27.9753 36.637 26.6268 35.5366 25.2337 34.8618C24.5353 34.5234 23.8407 34.3011 23.1531 34.2061C22.24 34.0807 21.3609 34.1706 20.5442 34.4711C20.4422 34.5086 20.3275 34.4572 20.2879 34.3562C20.2484 34.2553 20.299 34.143 20.401 34.1055C21.2796 33.7822 22.2249 33.6854 23.2025 33.8198Z"
            fill="#003CE5"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.9235 40.6612C17.6079 39.6176 18.6762 39.0013 19.9322 38.926C22.945 38.7446 26.3839 42.1018 28.1644 44.1198C29.1578 45.2439 29.0478 46.9329 27.9196 47.8913C26.7916 48.8491 25.3989 48.8077 24.0795 47.5911C23.2208 46.6777 22.2085 45.6604 21.6708 45.2764C21.133 44.8929 20.261 45.5204 20.7702 46.255"
            fill="#FFC8E9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.9172 38.7313C22.4879 38.5766 25.4437 40.7425 28.3136 43.9931C29.3788 45.199 29.261 47.0116 28.0501 48.0396C26.8591 49.0517 25.4115 48.9948 24.0913 47.8625L23.9504 47.737C23.6652 47.4915 23.3753 47.2235 23.0638 46.9205L21.7982 45.6572L21.5921 45.4596C21.1704 45.0813 20.5706 45.6235 20.9333 46.1465C20.9954 46.2361 20.9727 46.3573 20.8827 46.4171C20.7925 46.477 20.6693 46.453 20.6072 46.3635C19.9837 45.4641 21.0781 44.4748 21.8563 45.1727L22.0687 45.3761L23.3401 46.6449C23.5695 46.8681 23.7866 47.0717 24.0007 47.2626L24.2138 47.4495C25.4322 48.5736 26.713 48.657 27.7891 47.7427C28.8351 46.8546 28.9368 45.2888 28.0162 44.2468C25.228 41.0884 22.3456 38.9763 19.9469 39.1206C18.7863 39.1903 17.7964 39.7443 17.1446 40.6883C17.0835 40.7768 16.9604 40.7987 16.8697 40.7373C16.7791 40.6758 16.7551 40.5543 16.8161 40.4659C17.5357 39.4237 18.6356 38.8083 19.9172 38.7313Z"
            fill="#003CE5"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M52.1085 47.1554C52.1176 47.0571 52.1748 46.1787 51.2389 46.0791C51.0814 46.0623 50.9674 45.9209 50.9842 45.7634C51.0009 45.6059 51.1422 45.4914 51.2997 45.5082C52.2345 45.6076 52.3643 44.7391 52.3764 44.6381C52.3937 44.4812 52.5345 44.3675 52.6918 44.3842C52.8479 44.4008 52.9611 44.5394 52.9473 44.6948L52.947 44.698C52.9372 44.8001 52.8813 45.6765 53.8159 45.7759C53.9734 45.7927 54.0879 45.9344 54.0711 46.0919C54.0543 46.2494 53.9126 46.3636 53.7551 46.3469C52.81 46.2463 52.6896 47.1166 52.679 47.2162C52.6622 47.3737 52.5208 47.4879 52.3633 47.4711C52.2057 47.4544 52.0918 47.3129 52.1085 47.1554Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.9887 32.6963C11.9941 32.6377 12.0283 32.1137 11.47 32.0543C11.376 32.0443 11.308 31.9599 11.318 31.866C11.328 31.772 11.4123 31.7037 11.5062 31.7137C12.0639 31.7731 12.1413 31.255 12.1485 31.1947C12.1588 31.1011 12.2428 31.0333 12.3366 31.0433C12.4298 31.0532 12.4973 31.1359 12.489 31.2285L12.4888 31.2305C12.483 31.2913 12.4497 31.8141 13.0071 31.8734C13.1011 31.8834 13.1694 31.968 13.1594 32.0619C13.1494 32.1559 13.0649 32.224 12.9709 32.214C12.4071 32.154 12.3353 32.6732 12.329 32.7325C12.319 32.8265 12.2346 32.8946 12.1407 32.8846C12.0467 32.8746 11.9787 32.7903 11.9887 32.6963Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M46.9687 13.6309C46.9741 13.5722 47.0082 13.0483 46.45 12.9889C46.356 12.9789 46.288 12.8945 46.298 12.8006C46.308 12.7066 46.3923 12.6383 46.4862 12.6483C47.0438 12.7076 47.1213 12.1896 47.1285 12.1293C47.1388 12.0357 47.2228 11.9679 47.3166 11.9778C47.4097 11.9878 47.4773 12.0704 47.469 12.1631L47.4688 12.165C47.463 12.2259 47.4296 12.7487 47.9871 12.808C48.0811 12.818 48.1494 12.9026 48.1394 12.9965C48.1294 13.0905 48.0448 13.1586 47.9509 13.1486C47.3871 13.0886 47.3153 13.6077 47.309 13.6671C47.299 13.7611 47.2146 13.8292 47.1206 13.8192C47.0267 13.8092 46.9587 13.7248 46.9687 13.6309Z"
            fill="white"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.9876 41.3932L14.4741 14.9981C13.9162 14.1061 14.182 12.9298 15.0683 12.3705L30.5157 2.62435C31.4018 2.06538 32.5724 2.3352 33.1307 3.22747L49.644 29.6219C50.2021 30.5142 49.9363 31.6908 49.0502 32.2497L33.6026 41.996C32.7165 42.5552 31.5458 42.2851 30.9876 41.3932Z"
          fill="#3749EF"
        />
        <mask
          id="mask1_16182_5034"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="14"
          y="2"
          width="37"
          height="41"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M50.1081 29.3307L33.569 2.93068C33.0186 2.05216 31.8644 1.78671 30.9914 2.33814L15.4515 12.1566C14.5786 12.7083 14.317 13.8677 14.8678 14.7465L31.4071 41.1461C31.9576 42.0252 33.1115 42.2905 33.9844 41.7387L49.5244 31.9206C50.1995 31.4941 50.5086 30.7043 50.3596 29.9615C50.316 29.7439 50.2328 29.5299 50.1081 29.3307Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask1_16182_5034)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.335 46.9961L10.9166 12.8085L33.0022 -1.14545L54.4206 33.0421L32.335 46.9961Z"
            fill="#005CFF"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.4803 21.2487L28.106 17.4585C27.9163 17.1558 28.0061 16.7568 28.3066 16.5671L29.7954 15.6265C30.0959 15.4368 30.493 15.5281 30.6826 15.8305L33.0569 19.6207C33.2463 19.9232 33.1564 20.3221 32.856 20.5118L31.3672 21.4527C31.0671 21.6423 30.6696 21.5509 30.4803 21.2487Z"
          fill="#0B7EFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.4251 14.5963L24.0508 10.8061C23.8611 10.5034 23.951 10.1044 24.2514 9.91473L25.7402 8.97419C26.0407 8.78449 26.4378 8.87571 26.6274 9.17815L29.0018 12.9683C29.1911 13.2708 29.1013 13.6698 28.8008 13.8595L27.3121 14.8003C27.0119 14.99 26.6144 14.8985 26.4251 14.5963Z"
          fill="#0B7EFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.7025 27.9883L32.3278 24.1982C32.1384 23.8954 32.2283 23.4964 32.5288 23.3067L34.0176 22.3664C34.318 22.1764 34.7151 22.2677 34.9045 22.5702L37.2791 26.3603C37.4684 26.6628 37.3786 27.0618 37.0781 27.2515L35.5894 28.1923C35.2892 28.3819 34.8918 28.2908 34.7025 27.9883Z"
          fill="#0B7EFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.9246 34.7278L36.5503 30.9376C36.3606 30.6352 36.4505 30.2362 36.7509 30.0462L38.2398 29.1059C38.5402 28.9159 38.9373 29.0072 39.1269 29.3096L41.5013 33.1001C41.6906 33.4023 41.6008 33.8013 41.3003 33.9913L39.8116 34.9318C39.5114 35.1214 39.114 35.0303 38.9246 34.7278Z"
          fill="#0B7EFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.8264 21.805L18.5084 16.5087C18.2586 16.1104 18.3773 15.5845 18.7733 15.3342L19.9705 14.5776C20.3665 14.3273 20.8899 14.4478 21.1397 14.8461L24.4577 20.1424C24.7072 20.5411 24.5888 21.067 24.1931 21.3172L22.9954 22.0739C22.5996 22.3238 22.0762 22.2036 21.8264 21.805Z"
          fill="#0B7EFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.0166 34.5766C20.4914 31.9043 22.5907 29.9905 25.4956 29.5826C30.2422 28.9154 31.8308 27.7694 31.8459 27.7579C31.7823 27.805 31.7554 27.8331 31.7554 27.8331C31.7554 27.8331 34.4346 25.9743 36.0396 27.8115C37.692 29.9982 35.442 31.8286 35.442 31.8286C34.8858 32.3223 32.2728 34.0671 26.036 34.9446"
          fill="#FFC8E9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.7972 34.0216C29.9028 33.9949 30.0116 34.0579 30.0402 34.1622C30.0687 34.2666 30.0064 34.3728 29.9008 34.3995C28.7401 34.693 27.4639 34.9414 26.0668 35.1378C25.9588 35.1531 25.8574 35.0788 25.8404 34.9723C25.8235 34.8656 25.8974 34.7668 26.0055 34.7517C27.3885 34.5572 28.6507 34.3115 29.7972 34.0216Z"
          fill="#003CE5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.5422 27.1187C35.6354 27.1764 35.6643 27.297 35.607 27.3878C35.5496 27.4788 35.4277 27.5056 35.3346 27.448C34.5196 26.9425 33.7639 27.0087 32.7606 27.5081L32.5192 27.6339L31.6661 28.1061L31.3451 28.2769L31.0309 28.4349C30.4062 28.7392 29.7941 28.9736 29.0348 29.1724L28.6422 29.269C28.5744 29.2846 28.5056 29.3001 28.4353 29.3153L27.9975 29.4046C27.846 29.4339 27.6883 29.4624 27.5241 29.4905L27.0106 29.5737L26.4527 29.6552L25.8457 29.7359C25.7401 29.7493 25.6324 29.7627 25.5225 29.7763C25.414 29.7895 25.3141 29.7136 25.2991 29.6067C25.2841 29.4997 25.3598 29.4023 25.4681 29.3891L26.0915 29.3097L26.6637 29.2303L27.1892 29.15L27.6725 29.0675L28.1179 28.9824C28.6886 28.8664 29.1702 28.7403 29.6103 28.5939L29.9334 28.4801C29.9862 28.4606 30.0385 28.4404 30.0905 28.4202L30.3985 28.2932C30.4493 28.2713 30.5 28.2489 30.5505 28.226L30.8535 28.0837L31.1587 27.9301C31.2099 27.9035 31.2616 27.8764 31.3135 27.8488L31.7651 27.6031L32.3888 27.2567C33.595 26.6152 34.5302 26.491 35.5422 27.1187Z"
          fill="#003CE5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.1646 44.1199C28.7573 44.1708 29.6156 44.4436 30.4939 43.8133C31.6982 42.9482 32.1273 41.3776 31.2004 40.1051C30.542 39.2012 27.1952 34.5679 23.1778 34.013C21.5403 33.7872 20.0101 34.2339 18.7546 35.304L21.3501 41.222L28.1646 44.1199Z"
          fill="#FFC8E9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.2027 33.8201C23.9349 33.9212 24.6697 34.1563 25.4047 34.5124C26.8501 35.2128 28.2355 36.3431 29.5385 37.7541C29.9539 38.2038 30.3401 38.6601 30.7027 39.1201L31.027 39.5403L31.2394 39.8267C31.2776 39.8787 31.3178 39.9337 31.361 39.993C32.3246 41.3159 31.9841 42.9303 30.6163 43.9687C29.8251 44.569 28.8957 44.6178 28.0934 44.3008C27.9914 44.2605 27.9408 44.1469 27.9802 44.0471C28.0197 43.9471 28.1343 43.8988 28.2363 43.9391C28.9177 44.2084 29.7013 44.1671 30.3718 43.6584C31.5805 42.7408 31.8719 41.3591 31.0404 40.2174L30.812 39.9061L30.6102 39.6381L30.3913 39.3565C30.0348 38.9044 29.6552 38.4558 29.2473 38.0143C27.9755 36.6372 26.627 35.5369 25.234 34.862C24.5356 34.5236 23.841 34.3013 23.1533 34.2064C22.2403 34.0809 21.3612 34.1708 20.5444 34.4713C20.4424 34.5089 20.3277 34.4574 20.2882 34.3564C20.2486 34.2555 20.2992 34.1433 20.4012 34.1057C21.2798 33.7825 22.2251 33.6856 23.2027 33.8201Z"
          fill="#003CE5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.9235 40.6614C17.6079 39.6179 18.6762 39.0015 19.9322 38.9263C22.945 38.7448 26.3839 42.102 28.1644 44.12C29.1578 45.2441 29.0478 46.9331 27.9196 47.8915C26.7916 48.8494 25.3989 48.8079 24.0795 47.5913C23.2208 46.678 22.2085 45.6607 21.6708 45.2766C21.133 44.8931 20.261 45.5206 20.7702 46.2553"
          fill="#FFC8E9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.9172 38.7315C22.4879 38.5769 25.4437 40.7427 28.3136 43.9933C29.3788 45.1993 29.261 47.0118 28.0501 48.0398C26.8591 49.0519 25.4115 48.9951 24.0913 47.8627L23.9504 47.7372C23.6652 47.4918 23.3753 47.2237 23.0638 46.9207L21.7982 45.6575L21.5921 45.4598C21.1704 45.0816 20.5706 45.6238 20.9333 46.1468C20.9954 46.2363 20.9727 46.3575 20.8827 46.4173C20.7925 46.4772 20.6693 46.4533 20.6072 46.3637C19.9837 45.4643 21.0781 44.4751 21.8563 45.173L22.0687 45.3763L23.3401 46.6451C23.5695 46.8684 23.7866 47.072 24.0007 47.2628L24.2138 47.4498C25.4322 48.5738 26.713 48.6572 27.7891 47.7429C28.8351 46.8548 28.9368 45.289 28.0162 44.247C25.228 41.0887 22.3456 38.9765 19.9469 39.1208C18.7863 39.1906 17.7964 39.7445 17.1446 40.6885C17.0835 40.7771 16.9604 40.7989 16.8697 40.7376C16.7791 40.6761 16.7551 40.5545 16.8161 40.4661C17.5357 39.424 18.6356 38.8085 19.9172 38.7315Z"
          fill="#003CE5"
        />
        <path
          d="M49.8824 39.045C54.8322 39.045 58.8448 35.0324 58.8448 30.0826C58.8448 25.1327 54.8322 21.1201 49.8824 21.1201C44.9325 21.1201 40.9199 25.1327 40.9199 30.0826C40.9199 35.0324 44.9325 39.045 49.8824 39.045Z"
          fill="#6AC90F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49.2799 34.3639L49.2813 27.8419L47.2516 29.8716C47.0108 30.1125 46.6214 30.1125 46.3806 29.8716C46.1397 29.6308 46.1397 29.2408 46.3806 29.0006L49.4606 25.9206C49.7014 25.6798 50.0907 25.6798 50.3316 25.9206L53.4116 28.9981C53.5317 29.1183 53.5921 29.276 53.5921 29.4337C53.5921 29.5913 53.5317 29.749 53.4116 29.8692C53.1713 30.11 52.7814 30.11 52.5405 29.8698L50.5133 27.8438L50.5119 34.3639C50.5119 34.7046 50.2366 34.9799 49.8959 34.9799C49.5553 34.9799 49.2799 34.7046 49.2799 34.3639Z"
          fill="white"
          stroke="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_16182_5034">
          <rect width="66" height="66" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HandCardArrowUp;
