import React from "react";
import { FloatingLabelInput } from "../atoms/FloatingLabelInput";

interface DniInputProps {
  value: string;
  error: string | undefined;
  onChange: (value: string) => void;
}

export const DniInput: React.FC<DniInputProps> = ({
  value,
  onChange,
  error,
}) => {
  const handleChange = (newValue: string) => {
    // Only allow numbers and letters
    if (/^[a-zA-Z0-9]{0,9}$/.test(newValue)) {
      onChange(newValue);
    }
  };

  return (
    <>
      <FloatingLabelInput
        id="dni-input"
        label="DNI"
        value={value}
        isError={error !== undefined}
        onChange={handleChange}
        maxLength={9}
      />
      {error && <span className="text-red-500 text-sm mt-1 ml-4">{error}</span>}
    </>
  );
};
