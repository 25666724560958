import { FC } from "react";

interface SearchSubmitButtonProps {
  className?: string;
}

export const SearchSubmitButton: FC<SearchSubmitButtonProps> = ({
  className,
}) => {
  return (
    <div className={className}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Large">
          <path
            id="color&#240;&#159;&#142;&#168;"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.6968 2.30331C21.4269 2.03275 21.0296 1.93342 20.6642 2.04509L2.73878 7.53992C2.31661 7.66755 2.02208 8.0493 2.00565 8.49015C1.96068 8.9193 2.18716 9.33119 2.57357 9.52301L10.5244 13.5099L14.5101 21.4629C14.6935 21.7982 15.047 22.0048 15.4291 22H15.5117C15.9342 21.9683 16.2944 21.6816 16.4203 21.277L21.9549 3.33617C22.0666 2.97062 21.9673 2.57329 21.6968 2.30331Z"
            fill="white"
          />
        </g>
      </svg>
    </div>
  );
};
