import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import AtmIcon from "../../assets/AtmIcon";
import BillIcon from "../../assets/BillIcon";
import CreditCardIcon from "../../assets/CreditCardIcon";
import DashboardCardImage from "../../assets/DashboardCardImage";
import DesktopIcon from "../../assets/DesktopIcon";
import HandCard from "../../assets/HandCard";
import HandCardArrowUp from "../../assets/HandCardArrowUp";
import HandCardMoney from "../../assets/HandCardMoney";
import HandCardPlus from "../../assets/HandCardPlus";
import HandDocumentMoneyPurple from "../../assets/HandDocumentMoneyPurple";
import HandDocumentMoneyWhite from "../../assets/HandDocumentMoneyWhite";
import HandKeyhole from "../../assets/HandKeyhole";
import HandMoney from "../../assets/HandMoney";
import HandSuitcaseMoney from "../../assets/HandSuitcaseMoney";
import HandWalletMoney from "../../assets/HandWalletMoney";
import LockIcon from "../../assets/LockIcon";
import MoneyBagIcon from "../../assets/MoneyBagIcon";
import OfficeIcon from "../../assets/OfficeIcon";
import PhoneIcon from "../../assets/PhoneIcon";
import PiggyBankIcon from "../../assets/PiggyBankIcon";
import QuestionIcon from "../../assets/QuestionIcon";
import WhatsappIcon from "../../assets/WhatsappIcon";
import Yape from "../../assets/Yape";
import { useSearch } from "../../context/SearchContext";
import { Banner } from "../../services/PipelineService";
import { sendResponseLinkClick } from "../../utils/analyticsEvents";
import { sendContextRequest } from "../../utils/contextEvents";
import { BannerComponent } from "../molecules/Banner";
import { ActionButton } from "../../services/AnswerComponentsService";
import { Tutorial } from "../../services/AnswerComponentsService";

interface ResponseContentProps {
  textResponse: string;
  banners: Banner[];
  actions: ActionButton[];
  tutorials: Tutorial[];
  query: string;
  requestId: string;
  contextId: string;
  suggestionsEnabled: boolean;
}

const removeSpecificParams = (url: URL, setRfidValue: string): string => {
  const paramsToRemove = [
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_content",
    "pcid",
    "rfid",
  ];

  paramsToRemove.forEach((param) => {
    url.searchParams.delete(param);
  });

  url.searchParams.set("rfid", setRfidValue);

  url.search = url.search.replace(/%3A/g, ":");

  return url.toString();
};

const ResponseContent: React.FC<ResponseContentProps> = ({
  textResponse,
  banners,
  actions,
  tutorials,
  query,
  requestId,
  contextId,
  suggestionsEnabled,
}) => {
  const { products, handleSearch } = useSearch();

  const getIcon = (icon?: string) => {
    if (!icon) return null;
    switch (icon) {
      case "atm":
        return <AtmIcon />;
      case "bank-note":
        return <BillIcon color="#0A47F0" />;
      case "bcp-office":
        return <OfficeIcon />;
      case "card":
        return <CreditCardIcon color="#0A47F0" />;
      case "dashboard-card":
        return <DashboardCardImage />;
      case "hand-card":
        return <HandCard />;
      case "hand-card-arrow-up":
        return <HandCardArrowUp />;
      case "hand-card-money":
        return <HandCardMoney />;
      case "hand-card-plus":
        return <HandCardPlus />;
      case "hand-document-money-purple":
        return <HandDocumentMoneyPurple />;
      case "hand-document-money-white":
        return <HandDocumentMoneyWhite />;
      case "hand-keyhole":
        return <HandKeyhole />;
      case "hand-money":
        return <HandMoney />;
      case "hand-suitcase-money":
        return <HandSuitcaseMoney />;
      case "hand-wallet-money":
        return <HandWalletMoney />;
      case "lock":
        return <LockIcon />;
      case "money-bag":
        return <MoneyBagIcon />;
      case "monitor":
        return <DesktopIcon />;
      case "phone-arrow":
        return <PhoneIcon />;
      case "piggy-bank":
        return <PiggyBankIcon color="#0A47F0" />;
      case "productos-preaprobados":
        return <DashboardCardImage />;
      case "question-mark":
        return <QuestionIcon />;
      case "whatsapp":
        return <WhatsappIcon />;
      case "yape":
        return <Yape />;
    }
  };

  const getIconColorBackground = (icon?: string) => {
    if (!icon) return null;
    switch (icon) {
      case "whatsapp":
        return "bg-[#37A500]";
      case "productos-preaprobados":
        return "bg-white";
      default:
        return "bg-[#EBF4FF]";
    }
  };

  const actionButton = (action: ActionButton) => {
    const buttonContent = () => {
      return (
        <>
          <div
            className={`ml-0 mr-4 my-auto ${
              action.icon === "productos-preaprobados"
                ? "w-16 h-16"
                : "w-12 h-12"
            } min-w-[3rem] min-h-[3rem] ${getIconColorBackground(
              action.icon,
            )} rounded-full flex items-center justify-center`}
          >
            {getIcon(action.icon)}
          </div>
          <div className="flex flex-col">
            <span className="text-start my-auto ml-0 mr-auto text-BCP-normal-text font-semibold">
              {action.title}
            </span>
            {action.description && (
              <span className="text-start mr-auto mt-2 text-BCP-normal-text text-sm">
                {action.description}
              </span>
            )}
          </div>
        </>
      );
    };

    if (action.icon === "productos-preaprobados") {
      return (
        <button
          className={`inline-flex p-4 rounded-2xl border-white border-2 bg-white hover:bg-[#EBF4FF] shadow-md shadow-[#527094]/20 !outline-none`}
          onClick={() => {
            if (products.length > 0) {
              handleSearch(
                "Quiero conocer mis productos preaprobados",
                "suggestion",
              );
            } else {
              sendContextRequest(contextId);
            }
          }}
        >
          {buttonContent()}
        </button>
      );
    } else {
      return (
        <a
          className={`inline-flex p-4 rounded-2xl border-white border-2 bg-white hover:bg-[#EBF4FF] shadow-md shadow-[#527094]/20 !outline-none`}
          href={action.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {buttonContent()}
        </a>
      );
    }
  };

  const TutorialSection: React.FC<{ tutorial: Tutorial }> = ({ tutorial }) => {
    const [activeSection, setActiveSection] = useState(0);
    const [tutorialStep, setTutorialStep] = useState(0);

    useEffect(() => {
      setTutorialStep(0);
    }, [activeSection]);

    if (!tutorial.steps || tutorial.steps.length === 0) return null;

    const currentSection = tutorial.steps[activeSection];
    const currentTutorial = currentSection.steps[tutorialStep];

    if (!currentTutorial) return null;

    return (
      <div className="flex flex-col mt-5 p-5 rounded-lg bg-gradient-to-b to-white from-[#BAD9FF]">
        <div className="flex justify-center mb-4">
          {tutorial.steps.map((section, index) => (
            <button
              key={index}
              onClick={() => setActiveSection(index)}
              className={`px-4 py-2 ${
                activeSection === index ? "border-b-2 border-BCP-orange" : ""
              }  text-BCP-normal-text border-b-2 font-medium mx-1`}
            >
              {section.section}
            </button>
          ))}
        </div>

        <p className="text-BCP-normal-text text-center font-bold mb-1">
          Paso {currentTutorial.position}
        </p>
        <p className="text-BCP-normal-text text-center text-sm">
          {currentTutorial.text}
        </p>
        <div className="flex my-auto">
          <button
            onClick={() => setTutorialStep(tutorialStep - 1)}
            className={`bg-BCP-orange text-white p-2 rounded-full ml-0 mr-auto my-auto h-10 w-10 pt-[0.7rem] ${
              tutorialStep > 0 ? "visible" : "invisible"
            }`}
          >
            <i className="m-auto h-4 w-4 pi pi-chevron-left"></i>
          </button>
          <img
            src={currentTutorial.image}
            alt="Tutorial"
            className="w-96 h-96 object-contain"
          />
          <button
            onClick={() => setTutorialStep(tutorialStep + 1)}
            className={`bg-BCP-orange text-white p-2 rounded-full mr-0 ml-auto my-auto h-10 w-10 pt-[0.7rem] ${
              tutorialStep < currentSection.steps.length - 1
                ? "visible"
                : "invisible"
            }`}
          >
            <i className="m-auto h-4 w-4 pi pi-chevron-right"></i>
          </button>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`flex w-full justify-left md:mt-9 ${
        suggestionsEnabled ? "blur-sm" : ""
      }`}
    >
      <div className="w-full" style={{ overflowWrap: "break-word" }}>
        <ReactMarkdown
          className="prose mx-auto text-BCP-normal-text leading-[24px]"
          components={{
            a: ({ href, ...props }) => {
              let modifiedHref = href;

              if (href) {
                const url = new URL(href);
                removeSpecificParams(url, "buscador:respuesta:pilotoIA");
                modifiedHref = url.toString();
              }

              return (
                <a
                  {...props}
                  href={modifiedHref}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-BCP-orange font-semibold no-underline"
                  onClick={() =>
                    sendResponseLinkClick(
                      query,
                      requestId,
                      modifiedHref!,
                      props.children?.toString() || "",
                      contextId,
                    )
                  }
                >
                  {props.children}
                </a>
              );
            },
          }}
        >
          {textResponse}
        </ReactMarkdown>

        {banners && banners.length > 0 && (
          <div className="space-y-4 mt-4">
            {banners.map((banner, index) => (
              <BannerComponent key={index} banner={banner} />
            ))}
          </div>
        )}

        {actions && actions.length > 0 && (
          <div className="space-y-4 mt-4">
            {actions.map((action) => actionButton(action))}
          </div>
        )}

        {tutorials && tutorials.length > 0 && (
          <TutorialSection tutorial={tutorials[0] as any} />
        )}

        <div className={`text-sm mt-10 text-BCP-dark-gray space-x-1 flex`}>
          <span className="flex space-x-2">
            <a
              href="https://aifindr.ai/comofuncionaaifindr"
              target="_blank"
              rel="noopener noreferrer"
              className="pt-0.5"
            >
              <i className="pi pi-info-circle hover:text-BCP-gray-border transition-colors" />
            </a>
            <span>Respuestas creadas con IA. Comprueba si hay errores.</span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ResponseContent;
