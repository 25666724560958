import BillIcon from "../../assets/BillIcon";
import CompassIcon from "../../assets/CompassIcon";
import CreditCardIcon from "../../assets/CreditCardIcon";
import DashboardCardImage from "../../assets/DashboardCardImage";
import PiggyBankIcon from "../../assets/PiggyBankIcon";

interface RecommendationsDashboardButtonProps {
  format: "large" | "small";
  icon: "piggy-bank" | "credit-card" | "compass" | "bill" | "dashboard-card";
  iconBgColor: string;
  label: string;
  onClick: () => void;
}

const RecommendationsDashboardButton: React.FC<
  RecommendationsDashboardButtonProps
> = ({ format, icon, iconBgColor, label, onClick }) => {
  const getIcon = () => {
    switch (icon) {
      case "piggy-bank":
        return <PiggyBankIcon />;
      case "credit-card":
        return <CreditCardIcon />;
      case "compass":
        return <CompassIcon />;
      case "bill":
        return <BillIcon />;
      case "dashboard-card":
        return <DashboardCardImage />;
    }
  };

  const smallButton = () => {
    return (
      <button
        onClick={onClick}
        id={`button-${icon}`}
        className={`col-span-1 flex flex-col items-center p-4 rounded-2xl border-white border-2 bg-white hover:bg-[#EBF4FF] shadow-md shadow-[#527094]/20 transition-colors w-full`}
      >
        <div
          className={`ml-0 mr-auto w-12 h-12 rounded-full flex items-center justify-center mb-3`}
          style={{ backgroundColor: iconBgColor }}
        >
          {getIcon()}
        </div>
        <span className="text-start my-auto ml-0 mr-auto text-BCP-normal-text font-semibold">
          {label}
        </span>
      </button>
    );
  };

  const largeButton = () => {
    return (
      <button
        onClick={onClick}
        id={`button-${icon}`}
        className={`col-span-2 flex items-center px-5 py-4 rounded-2xl border-white border-2 bg-white hover:bg-[#EBF4FF] shadow-md shadow-[#527094]/20 transition-colors w-full`}
      >
        {getIcon()}
        <span className="text-start my-auto ml-5 mr-auto text-BCP-normal-text font-semibold">
          {label}
        </span>
      </button>
    );
  };

  return format === "small" ? smallButton() : largeButton();
};

export default RecommendationsDashboardButton;
