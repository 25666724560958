import { FC } from "react";

interface PhoneIconProps {
  className?: string;
}

export const PhoneIcon: FC<PhoneIconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="s/ic_smartphone_incoming_call">
        <path
          id="color&#240;&#159;&#142;&#168;"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.0042 3.50141C20.5541 3.50141 21 3.95814 21 4.52152V20.4805C21 21.0439 20.5541 21.5006 20.0042 21.5006H10C9.45001 21.5006 9.00418 21.0439 9.00418 20.4805V16.4455L9.00192 16.4073L9.00106 16.3687C9.00106 16.3429 9.00231 16.3174 9.00418 16.292V14.5247C9.00418 13.9613 9.45001 13.5045 10 13.5045C10.55 13.5045 10.9959 13.9613 10.9959 14.5247V15.5024H19.0083V5.50156H15.0062C14.4562 5.50156 14.0103 5.08491 14.0103 4.52152C14.0103 3.95814 14.4562 3.50141 15.0062 3.50141H20.0042ZM19.0083 17.4991H10.9982V19.4999L14.9847 19.4978L14.8855 19.4928C14.4264 19.4395 14.0622 19.0752 14.0089 18.6161L14.0022 18.4995C14.0022 17.9472 14.4499 17.4995 15.0022 17.4995C15.5544 17.4995 16.0022 17.9472 16.0022 18.4995C16.0022 19.0123 15.6161 19.435 15.1188 19.4928L15.0187 19.4978L19.0083 19.4999V17.4991ZM9.7034 11.2062C9.31444 11.5939 8.68624 11.5939 8.29728 11.2062C8.10849 11.0184 8.00229 10.7628 8.00229 10.4962C8.00229 10.2296 8.10849 9.97398 8.29728 9.78621L9.60357 8.50476L3.99725 8.50555C3.44648 8.50555 3 8.05784 3 7.50555C3 6.95327 3.44648 6.50555 3.99725 6.50555L9.5936 6.50476L8.29783 5.21411C8.04487 4.96046 7.94608 4.59074 8.03867 4.24424C8.13126 3.89773 8.40116 3.62708 8.74671 3.53424C9.09226 3.44139 9.46096 3.54046 9.71392 3.79411L12.705 6.79476C12.8938 6.98253 13 7.23812 13 7.50476C13 7.7714 12.8938 8.02699 12.705 8.21476L9.7034 11.2062Z"
          fill="#0A47F0"
        />
      </g>
    </svg>
  );
};

export default PhoneIcon;
