import { FC } from "react";

interface ShieldIconProps {
  className?: string;
}

export const ShieldIcon: FC<ShieldIconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="s/ic_shield_insurance">
        <path
          id="color&#240;&#159;&#142;&#168;"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.3685 21.6572L12.2674 21.9635C12.0924 22.0122 11.9076 22.0122 11.7326 21.9635L10.6304 21.6572C6.70507 20.5646 4 17.2921 4 13.0947C4 9.52494 4 7.32957 4 6.50863C4 6.00769 4.40574 5.50674 5 5.50674C6.72801 5.50674 9.30164 4.54254 11.2561 2.3348C11.4401 2.12686 11.6881 2.01526 12 2C12.3159 2.01665 12.5642 2.12825 12.745 2.3348C14.7146 4.58524 17.2566 5.50674 19 5.50674C19.6324 5.50674 20 6.05069 20 6.50863C20 7.22964 20 9.42501 20 13.0947C20 17.2921 17.2939 20.5646 13.3685 21.6572ZM18 13.0947V7.44493C15.9533 7.1814 13.7692 6.13187 11.9965 4.44352C10.226 6.11045 8.02725 7.17226 6 7.44233V13.0947C6 16.2977 8.01228 18.8488 11.165 19.7263L11.9998 19.9584L12.8333 19.7265C15.987 18.8487 18 16.2974 18 13.0947ZM9.08576 12.0059C8.69263 11.618 8.05948 11.6222 7.67158 12.0154C7.28368 12.4085 7.28793 13.0417 7.68107 13.4296L9.99418 15.7118C10.3875 16.0999 11.0211 16.0954 11.4089 15.7018L16.329 10.7075C16.7166 10.3141 16.7118 9.68096 16.3184 9.29337C15.9249 8.90578 15.2918 8.91052 14.9042 9.30395L10.6865 13.5853L9.08576 12.0059Z"
          fill="#0A47F0"
        />
      </g>
    </svg>
  );
};

export default ShieldIcon;
