import { useState } from "react";
import BillIcon from "../../assets/BillIcon";
import CloseDrawerIcon from "../../assets/CloseDrawerIcon";
import CompassIcon from "../../assets/CompassIcon";
import CreditCardIcon from "../../assets/CreditCardIcon";
import DashboardCardImage from "../../assets/DashboardCardImage";
import PiggyBankIcon from "../../assets/PiggyBankIcon";
import PlusIcon from "../../assets/PlusIcon";
import ShieldIcon from "../../assets/ShieldIcon";
import { DniInput } from "./DniInput";

interface DrawerContentProps {
  type: "questions" | "user_products";
  onOptionClick: (option: string) => void;
  onClose: () => void;
}

export const DrawerContent: React.FC<DrawerContentProps> = ({
  type,
  onOptionClick,
  onClose,
}) => {
  const [idNumber, setIdNumber] = useState("");
  const [idNumberError, setIdNumberError] = useState<string | undefined>(
    undefined,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const validateIdNumber = (number: string) => {
    const regex = /^\d{8}[A-Za-z]$/;
    return regex.test(number);
  };

  const handleIdNumberChange = (value: string) => {
    setIdNumber(value);
    setIsValid(validateIdNumber(value));
    if (value.length === 9 && !validateIdNumber(value)) {
      setIdNumberError("DNI incorrecto. Vuelve a intentarlo.");
    } else {
      setIdNumberError(undefined);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!isValid) return;

    setIsLoading(true);
    // Mimic an API request
    setTimeout(() => {
      setIsLoading(false);
      alert("ID number submitted: " + idNumber);
    }, 2000);
  };

  const getIconAndColor = (icon: string) => {
    switch (icon) {
      case "piggy-bank":
        return { icon: <PiggyBankIcon />, color: "#FFF6E6" };
      case "credit-card":
        return { icon: <CreditCardIcon />, color: "#ECE4FC" };
      case "compass":
        return { icon: <CompassIcon />, color: "white" };
      case "bill":
        return { icon: <BillIcon />, color: "#E8FCF9" };
      case "dashboard-card":
        return { icon: <DashboardCardImage />, color: "#FFF6E6" };
      case "plus":
        return { icon: <PlusIcon />, color: "#EAEFF5" };
      case "shield":
        return { icon: <ShieldIcon />, color: "#EBF4FF" };
      default:
        return { icon: <PiggyBankIcon />, color: "#FFF6E6" };
    }
  };

  const drawerCard = (icon: string, label: string, options: string[]) => (
    <div>
      <div className="flex">
        <div
          className={`ml-0 w-12 h-12 rounded-full flex items-center justify-center my-auto`}
          style={{ backgroundColor: getIconAndColor(icon).color }}
        >
          {getIconAndColor(icon).icon}
        </div>
        <span className="text-start my-auto ml-3 mr-auto text-BCP-dark-blue text-xl font-semibold">
          {label}
        </span>
      </div>
      <div className="flex flex-col gap-4 mt-2 p-1">
        {options.map((option) => (
          <button
            key={option}
            className="w-full px-4 py-3 rounded-full shadow-md text-left text-BCP-normal-text text-xs font-semibold hover:bg-[#EBF4FF]"
            onClick={() => onOptionClick(option)}
          >
            <span>{option}</span>
          </button>
        ))}
      </div>
    </div>
  );

  const userProductsComponent = () => {
    return (
      <>
        <div className="mx-10 mt-28 flex flex-col">
          <DashboardCardImage className="mx-auto h-full w-[35%]" />
          <span className="text-start mt-5 text-BCP-dark-blue text-xl font-semibold">
            Con tu DNI, te ofreceré más detalles y te diré cuáles son tus
            productos preaprobados
          </span>
        </div>
        <div className="mx-10 mt-10">
          <form onSubmit={handleSubmit} className="flex flex-col">
            <DniInput
              value={idNumber}
              onChange={handleIdNumberChange}
              error={idNumberError}
            />
            <button
              type="submit"
              disabled={!isValid || isLoading}
              className={`px-4 py-2 mt-10 h-10 rounded-full bg-BCP-orange disabled:opacity-50 text-white ${
                isLoading ? "opacity-50" : ""
              }`}
            >
              {isLoading ? "Cargando..." : "Confirmar"}
            </button>
            <div className="mt-5 text-xs text-gray-600">
              <p>
                (*) Al enviarnos esta información usted autoriza al BCP el
                tratamiento de sus datos personales. Conocé más{" "}
                <a href="#" className="text-BCP-orange">
                  aquí
                </a>
                .
              </p>
              <p className="mt-2">
                Este sitio está protegido por reCAPTCHA y se aplican las{" "}
                <a href="#" className="text-BCP-orange">
                  Políticas de privacidad
                </a>{" "}
                y{" "}
                <a href="#" className="text-BCP-orange">
                  Términos del servicio de Google
                </a>
                .
              </p>
            </div>
          </form>
        </div>
      </>
    );
  };

  return (
    <div className="overflow-y-auto mx-4 relative">
      <button
        onClick={onClose}
        className="absolute top-4 right-2 text-gray-500 hover:text-gray-800"
        aria-label="Close drawer"
      >
        <CloseDrawerIcon className="w-8 h-8" />
      </button>
      {/* Drawer content container */}
      {type === "questions" && (
        <div className="space-y-12 mt-8">
          {drawerCard("bill", "Préstamos", [
            "¿Cómo adelanto los pagos de mi préstamo?",
            "¿Qué hago si no recibí el código para pedir mi préstamo?",
          ])}
          {drawerCard("piggy-bank", "Cuentas de ahorro", [
            "¿Qué premios puedo ganar con mis ahorros?",
            "Conocer los beneficios de la Cuenta Premio",
          ])}
          {drawerCard("credit-card", "Tarjetas de crédito y débito", [
            "¿Por qué debería tener una tarjeta de crédito?",
            "¿Cómo elegir la tarjeta de crédito ideal para mí?",
          ])}
          {drawerCard("shield", "Seguros", [
            "Qué tipos de seguros ofrece el BCP?",
            "¿Cómo elegir el seguro vehicular para mí?",
          ])}
          {drawerCard("plus", "Otras preguntas", [
            "¿Cómo cambiar soles y dólares?",
            "¿Cómo controlar mis gastos mensuales?",
          ])}
        </div>
      )}
      {type === "user_products" && userProductsComponent()}
    </div>
  );
};
