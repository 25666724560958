import { FC } from "react";

interface CloseIconProps {
  className?: string;
}

export const CloseIcon: FC<CloseIconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="c/ic_close_circle">
        <path
          id="color&#240;&#159;&#142;&#168;"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 2C17.522 2 22 6.478 22 12C22 17.522 17.522 22 12 22C6.478 22 2 17.522 2 12C2 6.478 6.478 2 12 2ZM9.1129 7.7097C8.72061 7.40468 8.15338 7.43241 7.79289 7.79289L7.7097 7.8871C7.40468 8.27939 7.43241 8.84662 7.79289 9.20711L10.585 12L7.79289 14.7929C7.40237 15.1834 7.40237 15.8166 7.79289 16.2071C8.18342 16.5976 8.81658 16.5976 9.20711 16.2071L12 13.415L14.7929 16.2071L14.8871 16.2903C15.2794 16.5953 15.8466 16.5676 16.2071 16.2071L16.2903 16.1129C16.5953 15.7206 16.5676 15.1534 16.2071 14.7929L13.415 12L16.2071 9.20711C16.5976 8.81658 16.5976 8.18342 16.2071 7.79289C15.8166 7.40237 15.1834 7.40237 14.7929 7.79289L12 10.585L9.20711 7.79289L9.1129 7.7097Z"
          fill="#4D5B70"
        />
      </g>
    </svg>
  );
};

export default CloseIcon;
