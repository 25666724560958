import React, { useState } from "react";
import { DrawerContent } from "../components/molecules/DrawerContent";
import SearchBar from "../components/molecules/SearchBar";
import { useSearch } from "../context/SearchContext";
import { sendContextRequest } from "../utils/contextEvents";

const SearchPage: React.FC = () => {
  const { handleSearch, contextId, products } = useSearch();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerType, setDrawerType] = useState<"questions" | "user_products">(
    "questions",
  );

  const handleMoreQuestionsClick = () => {
    setDrawerType("questions");
    setIsDrawerOpen(true);
  };

  const handleProductosPreaprobadosClick = () => {
    if (products.length > 0) {
      console.log("Products found, sending context request");
      handleSearch("Quiero conocer mis productos preaprobados", "suggestion");
    } else {
      console.log("No products found, sending context request");
      console.log(contextId);
      sendContextRequest(contextId);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center w-full relative">
      <div className="flex flex-col items-center max-w-[1200px] w-full h-screen sm:h-full py-16 mx-auto">
        <div className="space-y-4 px-5 w-full">
          <div className="max-w-[680px] mx-auto text-start space-y-[16px]">
            <h1 className="space-y-[8px] md:text-[40px] text-[24px] md:leading-[48px] leading-[32px] text-BCP-dark-blue">
              <div className={`font-semibold text-[32px]`}>
                ¿En qué te puedo ayudar?
              </div>
            </h1>
          </div>

          <div className="max-w-[680px] mx-auto">
            <SearchBar
              queryDisplayMode="searchPage"
              isInternalSearch={false}
              onMoreQuestionsClick={handleMoreQuestionsClick}
              onProductosPreaprobadosClick={handleProductosPreaprobadosClick}
            />
          </div>
        </div>
      </div>

      {/* Overlay */}
      {isDrawerOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={() => setIsDrawerOpen(false)}
        />
      )}

      {/* Desktop Drawer */}
      <div
        className={`fixed top-0 right-0 md:w-[50%] xl:w-[40%] h-full 
          bg-white shadow-lg transition-transform duration-300 ease-in-out z-50 transform
          ${isDrawerOpen ? "translate-x-0" : "translate-x-full"}
          hidden md:block
        `}
      >
        <div className="w-full h-full overflow-y-auto">
          {/* Drawer content container */}
          <div className="px-6 py-10">
            <DrawerContent
              type={drawerType}
              onOptionClick={function (option: string): void {
                handleSearch(option, "suggestion");
                setIsDrawerOpen(false);
              }}
              onClose={() => setIsDrawerOpen(false)}
            />
          </div>
        </div>
      </div>

      {/* Mobile Drawer */}
      <div
        className={`fixed bottom-0 right-0 w-full h-[90%] 
          bg-white shadow-lg transition-transform duration-300 ease-in-out z-50 transform
          ${isDrawerOpen ? "translate-y-0" : "translate-y-full"}
          rounded-t-[20px] md:hidden
        `}
      >
        <div className="w-full h-full overflow-y-auto">
          {/* Mobile drawer handle */}
          <div className="h-[4px] w-[36px] bg-gray-300 rounded-full mx-auto mt-3 mb-4" />

          {/* Drawer content container */}
          <div className="px-6 py-4">
            <DrawerContent
              type={drawerType}
              onOptionClick={function (option: string): void {
                handleSearch(option, "suggestion");
                setIsDrawerOpen(false);
              }}
              onClose={() => setIsDrawerOpen(false)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchPage;
