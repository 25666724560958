import { logException } from "../utils/logger";
import jsonAutocomplete from "json-autocomplete";
import {
  ActionButton,
  AnswerComponent,
  AnswerComponentCollector,
  AnswerComponentsService,
  Tutorial,
} from "./AnswerComponentsService";

const streamStartTag = "[[START]]";
const streamEndTag = "[[END]]";

export interface Source {
  title: string;
  url: string;
  quote: string;
  label?: string;
}

export interface Banner {
  title: string;
  description: string;
  buttonTitle: string;
  buttonHref?: string;
  imgUrl?: string;
}

export interface PipelineResponse {
  text_response: string;
  sources: Source[];
  banners: Banner[];
  action_buttons: ActionButton[];
  tutorials: Tutorial[];
  related_queries: string[];
}

function isValidUrl(url?: string): boolean {
  if (!url) return false;
  try {
    new URL(url);
    return true;
  } catch {
    return false;
  }
}

export class PipelineService {
  static async ragStream(
    query: string,
    textCallback: (text: string) => void,
    sourcesCallback: (sources: Source[]) => void,
    relatedQueriesCallback: (relatedQueries: string[]) => void,
    answerComponentsCallback: (answerComponents: AnswerComponent[]) => void,
    actionButtonsCallback: (actionButtons: ActionButton[]) => void,
    tutorialsCallback: (tutorials: Tutorial[]) => void,
    requestIdCallback: (requestId: string) => void,
    agentIdCallback: (agentId: string) => void,
    fullResponseCallback: (fullResponse: PipelineResponse) => void,
    runningJsonResponse: (jsonResponse: string) => void,
    contextId: string,
    metadata: string,
  ): Promise<{ pipelineResponse: PipelineResponse }> {
    let requestId = "-1";
    let agentId = "";
    const componentCollector = new AnswerComponentCollector();

    try {
      const url = `${import.meta.env.VITE_SEARCH_SERVICE_URL}/projects/${
        import.meta.env.VITE_SEARCH_SERVICE_PROJECT
      }/ask`;

      const body = {
        query,
        metadata,
        context_id: contextId,
        stream: "true",
      };

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${import.meta.env.VITE_SEARCH_SERVICE_KEY}`,
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        throw new Error(`Failed ragStream: ${response.statusText}`);
      }

      agentId = response.headers.get("X-Agent-Id") ?? "";
      agentIdCallback(agentId);

      requestId = response.headers.get("x-request-id") ?? "-1";
      requestIdCallback(requestId);

      if (!response.body) {
        throw new Error("ReadableStream not yet supported in this browser.");
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let full_response = "";
      let done = false;
      let lastTextResponseLength = 0;
      AnswerComponentsService.fetchedAnswerComponents.clear();
      AnswerComponentsService.attemptCount.clear();

      do {
        const { value, done: chunkDone } = await reader.read();
        done = chunkDone;

        const chunk = decoder.decode(value, { stream: true });
        const lines = chunk.split("\n");

        for (const line of lines) {
          if (line.trim().startsWith("data:")) {
            const jsonLine = line.replace("data: ", "").trim();
            if (jsonLine) {
              try {
                const jsonChunk = JSON.parse(jsonLine);
                if (
                  jsonChunk.delta &&
                  jsonChunk.delta.message &&
                  jsonChunk.delta.message.content
                ) {
                  const content = jsonChunk.delta.message.content;
                  full_response += content;
                  const autocompleteResponse = jsonAutocomplete(full_response);
                  runningJsonResponse(autocompleteResponse);
                  try {
                    const jsonAutocompleteResponse =
                      JSON.parse(autocompleteResponse);

                    if (jsonAutocompleteResponse.text_response) {
                      if (
                        jsonAutocompleteResponse.text_response.length >
                        lastTextResponseLength
                      ) {
                        lastTextResponseLength =
                          jsonAutocompleteResponse.text_response.length;
                        const trimmedTextResponse =
                          jsonAutocompleteResponse.text_response
                            .replace(streamStartTag, "")
                            .replace(streamEndTag, "")
                            .trim();
                        textCallback(trimmedTextResponse);
                      }
                    }

                    if (jsonAutocompleteResponse.answer_components) {
                      answerComponentsCallback(
                        jsonAutocompleteResponse.answer_components,
                      );
                      await AnswerComponentsService.handleAnswerComponents(
                        jsonAutocompleteResponse.answer_components,
                        query,
                        componentCollector,
                        actionButtonsCallback,
                        tutorialsCallback,
                      );
                    }

                    if (jsonAutocompleteResponse.sources) {
                      const validSources =
                        jsonAutocompleteResponse.sources.filter(
                          (source: { title?: string; url?: string }) =>
                            source.title &&
                            source.url &&
                            isValidUrl(source.url),
                        );

                      sourcesCallback(validSources);
                    }

                    if (jsonAutocompleteResponse.related_queries) {
                      relatedQueriesCallback(
                        jsonAutocompleteResponse.related_queries,
                      );
                    }
                  } catch (error) {
                    console.error("Error parsing JSON response:", error);
                  }
                }
              } catch (e) {
                console.error("Error parsing JSON: ", e);
              }
            }
          }
        }
      } while (!done);

      const pipelineResponse = JSON.parse(
        full_response || "",
      ) as PipelineResponse;

      const { action_buttons, tutorials } =
        componentCollector.getAnswerComponents();
      pipelineResponse.action_buttons = action_buttons;
      pipelineResponse.tutorials = tutorials;

      fullResponseCallback(pipelineResponse);
      return { pipelineResponse };
    } catch (error) {
      logException(`Failed ragStream: ${error}`);
      throw error;
    }
  }
}
