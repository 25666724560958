import { FC } from "react";

interface CompassIconProps {
  className?: string;
}

export const CompassIcon: FC<CompassIconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9993 2.66669C23.3631 2.66669 29.3327 8.63622 29.3327 16C29.3327 23.3638 23.3631 29.3334 15.9993 29.3334C8.63555 29.3334 2.66602 23.3638 2.66602 16C2.66602 8.63622 8.63555 2.66669 15.9993 2.66669ZM15.9993 5.33335C10.1083 5.33335 5.33268 10.109 5.33268 16C5.33268 21.8911 10.1083 26.6667 15.9993 26.6667C21.8904 26.6667 26.666 21.8911 26.666 16C26.666 10.109 21.8904 5.33335 15.9993 5.33335ZM21.7716 10.5259C21.8654 10.7136 21.8654 10.9345 21.7716 11.1222L18.4203 17.8247C18.2913 18.0828 18.0821 18.292 17.824 18.421L11.1215 21.7723C10.7922 21.9369 10.3918 21.8034 10.2271 21.4741C10.1333 21.2864 10.1333 21.0655 10.2271 20.8778L13.5784 14.1753C13.7074 13.9173 13.9166 13.7081 14.1746 13.579L20.8772 10.2278C21.2065 10.0631 21.6069 10.1966 21.7716 10.5259ZM15.0565 15.0572C14.5358 15.5779 14.5358 16.4221 15.0565 16.9428C15.5772 17.4635 16.4215 17.4635 16.9422 16.9428C17.4629 16.4221 17.4629 15.5779 16.9422 15.0572C16.4215 14.5365 15.5772 14.5365 15.0565 15.0572Z"
        fill="#3C5D86"
      />
    </svg>
  );
};

export default CompassIcon;
