import { useAuth0 } from "@auth0/auth0-react";
import { ErrorBoundary } from "@sentry/react";
import React from "react";
import { SearchProvider } from "./context/SearchContext";
import Auth0ErrorPage from "./pages/Auth0ErrorPage";
import { LoadingPage } from "./pages/LoadingPage";
import MainPage from "./pages/MainPage";

const App: React.FC = () => {
  const { isLoading, isAuthenticated, error, loginWithRedirect } = useAuth0();

  const env = import.meta.env.VITE_ENV || "prod";

  if (env === "stg" || env === "dev") {
    if (isLoading) {
      return <LoadingPage />;
    }

    if (error) {
      return <Auth0ErrorPage error={error} />;
    }

    if (!isAuthenticated) {
      loginWithRedirect();
      return null;
    }

    return (
      <ErrorBoundary>
        <SearchProvider>
          <MainPage />
        </SearchProvider>
      </ErrorBoundary>
    );
  }

  return (
    <ErrorBoundary>
      <SearchProvider>
        <MainPage />
      </SearchProvider>
    </ErrorBoundary>
  );
};

export default App;
