import { FC } from "react";

interface QuestionIconProps {
  className?: string;
}

export const QuestionIcon: FC<QuestionIconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="q/ic_question_circle">
        <path
          id="color&#240;&#159;&#142;&#168;"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 2.66669C23.3638 2.66669 29.3334 8.63622 29.3334 16C29.3334 23.3638 23.3638 29.3334 16 29.3334C8.63622 29.3334 2.66669 23.3638 2.66669 16C2.66669 8.63622 8.63622 2.66669 16 2.66669ZM16 5.33335C10.109 5.33335 5.33335 10.109 5.33335 16C5.33335 21.8911 10.109 26.6667 16 26.6667C21.8911 26.6667 26.6667 21.8911 26.6667 16C26.6667 10.109 21.8911 5.33335 16 5.33335ZM16 21.3334C16.7374 21.3334 17.3334 21.9307 17.3334 22.6667C17.3334 23.404 16.7374 24 16 24C15.2627 24 14.6667 23.404 14.6667 22.6667C14.6667 21.9307 15.2627 21.3334 16 21.3334ZM16 8.00002C19.105 8.00002 21.3334 10.1726 21.3334 12.6667C21.3334 14.734 20.4271 16.003 18.9377 16.9837L18.6978 17.1362C17.7772 17.7016 17.3831 18.176 17.3371 18.5371L17.3327 18.6251C17.3557 19.3611 16.7777 19.9764 16.0417 19.9994C15.3056 20.0224 14.6903 19.4443 14.6673 18.7083C14.6227 17.2811 15.4712 16.0672 17.0215 15.0427L17.3023 14.8638C18.2255 14.2968 18.6667 13.7295 18.6667 12.6667C18.6667 11.6607 17.6472 10.6667 16 10.6667C14.4252 10.6667 13.543 11.4286 13.2846 12.3574C13.0872 13.0669 12.352 13.4819 11.6426 13.2846C10.9332 13.0872 10.5181 12.352 10.7155 11.6426C11.2783 9.61988 13.1539 8.00002 16 8.00002Z"
          fill="#0A47F0"
        />
      </g>
    </svg>
  );
};

export default QuestionIcon;
