import { FC } from "react";

interface CreditCardIconProps {
  className?: string;
  color?: string;
}

export const CreditCardIcon: FC<CreditCardIconProps> = ({
  className,
  color,
}) => {
  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.9993 5.33331C28.7357 5.33331 29.3327 5.93027 29.3327 6.66665V25.3333C29.3327 26.0697 28.7357 26.6666 27.9993 26.6666H3.99935C3.26297 26.6666 2.66602 26.0697 2.66602 25.3333V6.66665C2.66602 5.93027 3.26297 5.33331 3.99935 5.33331H27.9993ZM26.666 14.6666H5.33268V24H26.666V14.6666ZM10.666 20C11.4024 20 11.9993 20.5969 11.9993 21.3333C11.9993 22.0697 11.4024 22.6666 10.666 22.6666H7.99935C7.26297 22.6666 6.66602 22.0697 6.66602 21.3333C6.66602 20.5969 7.26297 20 7.99935 20H10.666ZM26.666 7.99998H5.33268V12H26.666V7.99998Z"
        fill={color ?? "#6D3ACD"}
      />
    </svg>
  );
};

export default CreditCardIcon;
