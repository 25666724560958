import { FC } from "react";

interface DesktopIconProps {
  className?: string;
}

export const DesktopIcon: FC<DesktopIconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="d/ic_desktop">
        <path
          id="color&#240;&#159;&#142;&#168;"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28 4.5H4.00001C3.26363 4.5 2.66667 5.09695 2.66667 5.83333V21.8333C2.66667 22.5697 3.26363 23.1667 4.00001 23.1667H14.6667V25.8333H10.6667C9.93029 25.8333 9.33334 26.4303 9.33334 27.1667C9.33334 27.903 9.93029 28.5 10.6667 28.5H21.3333C22.0697 28.5 22.6667 27.903 22.6667 27.1667C22.6667 26.4303 22.0697 25.8333 21.3333 25.8333H17.3333V23.1667H28C28.7364 23.1667 29.3333 22.5697 29.3333 21.8333V5.83333C29.3333 5.09695 28.7364 4.5 28 4.5ZM26.6667 20.5H5.33334V7.16667H26.6667V20.5Z"
          fill="#0A47F0"
        />
      </g>
    </svg>
  );
};

export default DesktopIcon;
