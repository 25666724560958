import { FC } from "react";

interface HandSuitcaseMoneyProps {
  className?: string;
}

export const HandSuitcaseMoney: FC<HandSuitcaseMoneyProps> = ({
  className,
}) => {
  return (
    <svg
      className={className}
      width="66"
      height="66"
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_16182_5134)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M57.723 48.2315C57.4752 48.6303 57.2197 49.0253 56.9564 49.4125C56.6118 49.9159 56.2517 50.4077 55.8723 50.8879C55.0437 51.9489 54.1416 52.9479 53.1775 53.8811C50.0647 56.8899 46.282 59.2093 42.0734 60.5956C41.0358 60.9364 39.9672 61.2229 38.8792 61.4436C36.9782 61.8347 35.0113 62.04 32.9942 62.04C29.467 62.04 26.087 61.4127 22.9625 60.2587C16.8141 57.9935 11.6415 53.7069 8.25372 48.2044C5.53189 43.7861 3.95996 38.5741 3.95996 33.0019C3.95996 16.9591 16.9613 3.95996 32.998 3.95996C46.5995 3.95996 58.0172 13.3115 61.1727 25.9428C61.2617 26.3029 61.3469 26.6669 61.4244 27.0309C61.827 28.9593 62.04 30.9535 62.04 33.0019C62.04 38.5896 60.4642 43.8055 57.7268 48.2354L57.723 48.2315Z"
          fill="#FBF3C8"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.8668 31.7473L14.7252 17.9803C14.8813 17.226 15.7933 16.6714 16.5988 16.7973L23.2146 17.8322C24.0201 17.9581 24.4141 18.7171 24.2576 19.4715L21.654 32.8401C21.4975 33.5945 20.7181 34.1039 19.9125 33.9777L13.2967 32.9427C12.4916 32.8168 11.9651 32.1033 12.1212 31.349"
          fill="black"
          fillOpacity="0.1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.1452 31.0519L13.8223 16.2699C13.9685 15.46 14.8752 14.8754 15.6844 15.022L22.3305 16.2267C23.1397 16.3732 23.545 17.1957 23.3984 18.0057L20.9713 32.3622C20.8248 33.1722 20.0504 33.71 19.2411 33.563L12.595 32.3583C11.7862 32.2117 11.2486 31.4364 11.3948 30.6265"
          fill="#48CAD4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.0708 30.034L14.4203 17.0231C14.5446 16.3347 15.2028 15.8779 15.8907 16.0025L21.5398 17.0265C22.2277 17.151 22.6846 17.81 22.56 18.4985L20.2108 31.5093C20.0862 32.1978 19.428 32.6549 18.7401 32.53L13.0909 31.506C12.4035 31.3814 11.9465 30.7224 12.0708 30.034Z"
          fill="#8AE7F2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.2287 23.501C19.7829 26.3481 22.6495 17.9334 22.5235 18.6217L20.2857 31.4963C20.1598 32.1846 19.4975 32.6422 18.806 32.5181L13.1272 31.5008C12.4361 31.377 11.9774 30.7188 12.1031 30.0306C12.1031 30.0306 12.8396 25.6308 13.2287 23.501Z"
          fill="#A2EBE2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.894 26.4943C15.5081 26.2435 14.5875 24.9164 14.8382 23.5305C15.0889 22.1442 16.4158 21.2237 17.8021 21.4747C19.1883 21.7254 20.1085 23.0523 19.8578 24.4385C19.6071 25.8247 18.2799 26.7451 16.894 26.4943Z"
          fill="#48CAD4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.4317 19.4942L17.6268 18.4166C17.6864 18.0861 18.003 17.8665 18.3335 17.9265L18.4043 17.9392C18.7348 17.9992 18.9543 18.3154 18.8946 18.6458L18.6995 19.7235C18.6395 20.054 18.3233 20.2735 17.9929 20.2138L17.9217 20.2009C17.5912 20.1409 17.372 19.8246 17.4317 19.4942Z"
          fill="#48CAD4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.3667 34.2438L7.0455 20.3504C6.86303 19.5894 7.46303 18.7137 8.25917 18.511L14.7985 16.8469C15.5946 16.6442 16.2856 17.1915 16.4677 17.9527L19.8512 31.3758C20.0333 32.1371 19.5359 32.9183 18.7396 33.1207L12.2003 34.7847C11.4045 34.9872 10.6111 34.5347 10.4287 33.7737"
          fill="black"
          fillOpacity="0.1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.40157 33.8852L5.47457 19.123C5.25891 18.3144 5.84101 17.413 6.64948 17.228L13.2901 15.7093C14.0986 15.5242 14.8274 16.1259 15.0427 16.9347L19.0159 31.2034C19.2313 32.0122 18.7508 32.8177 17.9421 33.0024L11.3015 34.5212C10.4933 34.706 9.66311 34.2006 9.44745 33.392"
          fill="#48CAD4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.81366 32.5748L6.35092 19.584C6.16761 18.8967 6.57623 18.2123 7.26343 18.055L12.9079 16.7641C13.5951 16.6068 14.3008 17.0365 14.4839 17.724L17.9469 30.7145C18.1299 31.402 17.7215 32.0867 17.0341 32.2437L11.3896 33.5346C10.7027 33.6917 9.99698 33.2621 9.81366 32.5748Z"
          fill="#8AE7F2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.15409 26.1738C15.4203 26.2137 14.322 17.1646 14.5036 17.8524L18.0104 30.6726C18.192 31.3605 17.78 32.0472 17.0897 32.2064L11.4208 33.5152C10.7309 33.6745 10.0239 33.2464 9.84204 32.5587C9.84204 32.5587 8.71674 28.3021 8.15409 26.1738Z"
          fill="#A2EBE2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.7242 27.3815C11.3397 27.6986 9.91805 26.8336 9.54965 25.4496C9.18106 24.0654 10.0047 22.686 11.3897 22.369C12.7746 22.0517 14.1957 22.9166 14.5643 24.3009C14.9329 25.6851 14.1088 27.0644 12.7242 27.3815Z"
          fill="#48CAD4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.1924 20.6802L9.90602 19.604C9.81807 19.2741 10.0146 18.9451 10.3449 18.8696L10.4155 18.8533C10.7458 18.7778 11.0846 18.9839 11.1726 19.3138L11.4589 20.39C11.5466 20.7201 11.3504 21.0489 11.0203 21.1247L10.9492 21.1409C10.6189 21.2164 10.2803 21.0101 10.1924 20.6802Z"
          fill="#48CAD4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.0439 36.3127L2.57985 25.3646C2.06099 24.7651 2.16584 23.6983 2.75969 23.1454L7.63786 18.6048C8.2317 18.0518 9.08991 18.2224 9.60859 18.8221L18.9037 29.3183C19.4224 29.9181 19.3616 30.8522 18.7674 31.405L13.8893 35.9456C13.2956 36.4981 12.3936 36.4605 11.8748 35.8611"
          fill="black"
          fillOpacity="0.1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.7092 35.8204L0.636662 24.9921C0.0664696 24.3653 0.143643 23.2837 0.756556 22.741L5.79139 18.2838C6.40431 17.7411 7.32113 17.9428 7.89114 18.57L18.0981 29.5521C18.6681 30.1792 18.6336 31.1273 18.0203 31.6699L12.9854 36.127C12.3727 36.6694 11.4137 36.6014 10.8435 35.9746"
          fill="#48CAD4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.61465 24.9987C1.12999 24.466 1.15968 23.6603 1.68066 23.1989L5.96027 19.4104C6.48125 18.949 7.29645 19.0069 7.78096 19.5399L16.9399 29.6101C17.4244 30.1432 17.3951 30.949 16.8738 31.4102L12.5942 35.1988C12.0733 35.6598 11.2581 35.602 10.7735 35.0692L1.61465 24.9987Z"
          fill="#8AE7F2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.30103 30.0854C12.6168 26.7388 7.37551 19.1122 7.85898 19.6462L16.9751 29.5428C17.4585 30.0769 17.4271 30.8863 16.9042 31.3508L12.612 35.1668C12.0897 35.6312 11.274 35.5753 10.7904 35.0415C10.7904 35.0415 7.79749 31.7375 6.30103 30.0854Z"
          fill="#A2EBE2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.16714 28.7845C6.19154 27.7113 6.25147 26.0874 7.30147 25.1575C8.35117 24.2275 9.99273 24.3438 10.9683 25.417C11.9439 26.4903 11.8835 28.1143 10.834 29.044C9.78445 29.9737 8.14243 29.8576 7.16714 28.7845Z"
          fill="#48CAD4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.46328 24.1962L4.705 23.3617C4.47238 23.106 4.48673 22.7186 4.73719 22.497L4.79064 22.4494C5.0411 22.2278 5.43243 22.2554 5.66504 22.5111L6.42332 23.3456C6.65578 23.6017 6.64159 23.9888 6.39144 24.2105L6.33752 24.2582C6.08707 24.4798 5.69589 24.452 5.46328 24.1962Z"
          fill="#48CAD4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.4574 31.891L11.6991 31.0566C11.4662 30.8006 11.4807 30.4137 11.7311 30.1921L11.7847 30.1442C12.0352 29.9226 12.4264 29.9505 12.659 30.2063L13.4173 31.0407C13.6499 31.2965 13.6357 31.6836 13.3852 31.9052L13.3313 31.9529C13.0812 32.1746 12.69 32.1468 12.4574 31.891Z"
          fill="#48CAD4"
        />
        <mask
          id="mask0_16182_5134"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="5"
          y="3"
          width="58"
          height="58"
        >
          <path
            d="M62.04 32.34C62.04 48.0142 49.3343 60.72 33.66 60.72C17.9858 60.72 5.28003 48.0142 5.28003 32.34C5.28003 16.6657 17.9858 3.95996 33.66 3.95996C49.3343 3.95996 62.04 16.6657 62.04 32.34Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_16182_5134)">
          <path
            d="M52.4248 34.9205L14.799 25.9291C13.3104 25.5734 11.8154 26.4917 11.4597 27.9802L6.33995 49.4041C5.98423 50.8927 6.90257 52.3878 8.39112 52.7435L46.0169 61.735C47.5054 62.0907 49.0005 61.1724 49.3563 59.6838L54.4759 38.2599C54.8316 36.7714 53.9133 35.2762 52.4248 34.9205Z"
            fill="#6783F5"
          />
          <path
            d="M18.1161 25.6256L49.6068 33.1511C52.7873 33.9111 54.7538 37.1127 53.9938 40.2931L51.7411 49.7197L8.72144 39.4392L10.9741 30.0126C11.7342 26.8322 14.9358 24.8656 18.1161 25.6256Z"
            fill="#3B48E4"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M53.7821 46.5872C53.7821 46.5872 42.3477 47.1346 30.3036 44.256C18.2595 41.3774 8.30762 35.7209 8.30762 35.7209L10.6042 26.1154C10.9333 24.7437 12.3085 23.8984 13.6802 24.2276L54.1874 33.9093C55.5591 34.2383 56.4044 35.6135 56.0752 36.9852L53.7787 46.5907L53.7821 46.5872Z"
            fill="#6783F5"
          />
          <path
            d="M35.0777 41.9734L27.0863 40.0636C26.342 39.8858 25.5944 40.345 25.4166 41.0893L24.5141 44.866C24.3362 45.6103 24.7954 46.3578 25.5396 46.5356L33.531 48.4454C34.2754 48.6232 35.0229 48.1641 35.2007 47.4198L36.1033 43.6431C36.2811 42.8988 35.8219 42.1512 35.0777 41.9734Z"
            fill="#609AD1"
          />
          <path
            d="M41.9666 33.2303C41.1109 33.0259 40.581 32.1634 40.7854 31.3078L41.9077 26.6177L27.9307 23.2784L26.8084 27.9686C26.604 28.8241 25.7415 29.3541 24.8859 29.1498C24.0304 28.9454 23.5004 28.0829 23.7047 27.2273L25.1976 20.9853C25.4021 20.1297 26.2646 19.5997 27.1202 19.8041L44.2008 23.8846C45.0564 24.0889 45.5864 24.9515 45.382 25.8071L43.889 32.049C43.6847 32.9047 42.8222 33.4346 41.9666 33.2303Z"
            fill="#6783F5"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M37.6122 -13.1934C37.9481 -4.80029 36.5695 3.73832 34.8029 10.112C34.6436 10.7736 34.557 10.9572 34.4149 11.3417C33.0814 14.9822 28.3912 16.2362 28.308 16.2639C27.2896 16.6103 26.7458 17.7188 27.0922 18.7337C27.352 19.5027 28.0483 20.0049 28.8103 20.0534C29.0597 20.0708 29.3126 20.0396 29.5655 19.953C29.652 19.9218 30.4418 19.6447 31.5053 19.0766C30.9753 20.5869 30.4107 21.9586 29.9915 22.835C29.8495 23.1329 29.5343 23.3095 29.2052 23.2715L28.1037 23.1537C27.6222 26.486 31.0619 26.3959 31.3355 26.4132C31.7235 26.4375 32.1114 26.3439 32.444 26.15C32.6657 26.531 32.9705 27.0852 34.0755 27.3866C34.9519 27.688 35.8421 27.4939 36.1781 27.3C36.4517 27.6395 36.9471 28.0032 37.8408 28.2595C38.9042 28.5643 39.6801 28.353 40.0196 28.1521C40.3105 28.45 41.3325 29.1394 42.6037 29.2259C43.3415 29.2745 44.0759 28.9003 44.45 28.2075C45.5688 26.1396 47.6749 18.8792 47.6091 18.5743C49.6147 10.7528 51.5476 1.22004 52.4655 -7.75157C48.489 -10.8344 43.584 -12.8089 38.1837 -13.1692C37.9932 -13.183 37.8027 -13.183 37.6122 -13.1934Z"
            fill="#FFC8E9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M41.4815 24.896C41.5854 24.9272 41.6478 25.0276 41.6374 25.1315L41.6304 25.1697L41.0173 27.2411C40.955 27.4212 40.8268 27.6775 40.6155 27.9304C40.3973 28.1902 40.134 28.3842 39.8188 28.4881C39.7045 28.5262 39.5798 28.4638 39.5417 28.3495C39.5036 28.2352 39.5659 28.1106 39.6802 28.0724C39.9123 27.9963 40.1098 27.8473 40.2795 27.6464C40.38 27.5286 40.4631 27.397 40.5289 27.2653L40.5497 27.2203L40.5809 27.1475L40.5982 27.106L41.2079 25.0415C41.239 24.9376 41.3395 24.8752 41.44 24.8856L41.478 24.8925L41.4815 24.896Z"
            fill="#163BDA"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M37.5497 23.9816C37.5913 23.8673 37.716 23.8084 37.8303 23.85C37.9308 23.8846 37.9897 23.9885 37.9724 24.0925L37.962 24.1306L37.1687 26.351L37.1583 26.3821L37.1064 26.5103C37.1064 26.5103 37.0925 26.5449 37.0821 26.5622C37.0128 26.7146 36.9262 26.8671 36.8223 27.0091C36.6075 27.3035 36.3478 27.5183 36.0325 27.6084C35.9147 27.643 35.7936 27.5737 35.7624 27.4594C35.7277 27.3416 35.797 27.2203 35.9114 27.1892C36.1192 27.1303 36.3028 26.9779 36.4655 26.7528C36.5487 26.635 36.6214 26.5103 36.6803 26.3821L36.715 26.2989L36.7392 26.2367L37.5428 23.9851L37.5497 23.9816Z"
            fill="#163BDA"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M34.1378 22.7173C34.2383 22.7519 34.2972 22.8559 34.2833 22.9598L34.2729 22.9979L33.4901 25.2286C33.4173 25.4192 33.2649 25.6893 33.019 25.9561C32.818 26.1743 32.5791 26.3475 32.3054 26.4618C32.1946 26.5103 32.0664 26.4583 32.0179 26.344C31.9694 26.2332 32.0214 26.105 32.1357 26.0565C32.3504 25.9665 32.5375 25.8279 32.6968 25.6547C32.8146 25.5265 32.9116 25.388 32.9912 25.2494L33.0155 25.2009L33.0536 25.1247L33.0744 25.0762L33.8572 22.849C33.8919 22.7484 33.9958 22.6896 34.0997 22.7034L34.1378 22.7138V22.7173Z"
            fill="#163BDA"
          />
        </g>
        <path
          d="M52.628 34.894L14.5729 25.8172C13.0673 25.4582 11.5552 26.3852 11.1955 27.8879L6.01734 49.515C5.65756 51.0176 6.58638 52.5269 8.09191 52.886L46.147 61.9627C47.6525 62.3219 49.1646 61.3948 49.5245 59.8921L54.7026 38.265C55.0623 36.7623 54.1335 35.2531 52.628 34.894Z"
          fill="#6783F5"
        />
        <path
          d="M17.9964 25.8999L49.5594 33.271C52.7472 34.0154 54.7182 37.1514 53.9565 40.2666L51.6986 49.5L8.58008 39.4303L10.838 30.1969C11.5997 27.0817 14.8087 25.1554 17.9964 25.8999Z"
          fill="#3B48E4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M53.7516 46.8184C53.7516 46.8184 42.3934 47.365 30.4295 44.4906C18.4657 41.6161 8.58008 35.9677 8.58008 35.9677L10.8614 26.376C11.1882 25.0063 12.5543 24.1622 13.9168 24.4909L54.1542 34.1587C55.5168 34.4872 56.3564 35.8604 56.0295 37.2302L53.7482 46.8219L53.7516 46.8184Z"
          fill="#6783F5"
        />
        <path
          d="M35.2165 42.084L27.0783 40.2958C26.3204 40.1293 25.5591 40.5592 25.3779 41.2561L24.4589 44.7925C24.2778 45.4894 24.7453 46.1894 25.5033 46.356L33.6415 48.1442C34.3995 48.3107 35.1607 47.8807 35.3418 47.1838L36.261 43.6475C36.4421 42.9505 35.9744 42.2506 35.2165 42.084Z"
          fill="#609AD1"
        />
      </g>
      <defs>
        <clipPath id="clip0_16182_5134">
          <rect width="66" height="66" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HandSuitcaseMoney;
