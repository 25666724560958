import { FC } from "react";

interface WhatsappIconProps {
  className?: string;
}

export const WhatsappIcon: FC<WhatsappIconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="s/ic_social_whatsapp">
        <path
          id="color-&#240;&#159;&#142;&#168;"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.0895 2.5C16.9505 2.5 21.1875 6.05 21.8975 11C22.6805 16.457 18.8835 21.548 13.4175 22.373C12.9105 22.449 12.4075 22.486 11.9105 22.486C10.8525 22.486 9.82353 22.318 8.85553 22.004C8.51253 21.893 8.18453 21.744 7.85053 21.617C7.81353 21.603 7.76053 21.596 7.69453 21.596C7.26853 21.596 6.24753 21.887 4.63153 22.471C4.57653 22.49 4.51953 22.5 4.46153 22.5C4.42153 22.5 4.38153 22.495 4.34153 22.485C4.10353 22.427 3.94753 22.206 3.96253 21.969L3.97653 21.88L4.04253 21.605C4.39753 20.081 4.43053 19.124 4.14153 18.734L3.77553 18.237C3.71453 18.153 3.65653 18.068 3.60053 17.982C2.83853 16.813 2.31353 15.461 2.10253 13.986C1.31853 8.529 5.11553 3.437 10.5825 2.613C11.0895 2.537 11.5915 2.5 12.0895 2.5ZM12.0895 4.5C11.6885 4.5 11.2815 4.53 10.8805 4.591C6.50553 5.251 3.45553 9.338 4.08253 13.702C4.24753 14.854 4.64953 15.928 5.27653 16.891L5.40253 17.076L5.75053 17.546C6.17653 18.12 6.36853 18.832 6.33353 19.815C6.98253 19.644 7.37653 19.596 7.69453 19.596C8.00953 19.596 8.29353 19.646 8.56253 19.748L8.95853 19.905C9.12853 19.975 9.29753 20.045 9.47153 20.102C10.2595 20.356 11.0795 20.486 11.9105 20.486C12.3115 20.486 12.7185 20.455 13.1195 20.396C17.4945 19.735 20.5445 15.648 19.9185 11.284C19.3625 7.417 15.9975 4.5 12.0895 4.5ZM9.25613 7.657L11.1531 9.554C11.3631 9.763 11.3631 10.103 11.1531 10.312L10.8942 10.5971C10.8478 10.647 10.7998 10.698 10.7501 10.75C10.0341 11.5 10.7501 12.25 11.5001 13L11.7046 13.2027C12.3862 13.8685 13.0665 14.4018 13.7501 13.75C13.9061 13.601 14.0521 13.467 14.1881 13.347C14.3971 13.138 14.7371 13.138 14.9461 13.347L16.8431 15.243C17.0521 15.453 17.0521 15.793 16.8431 16.002L15.7051 17.14C14.5671 18.278 11.2681 16.496 9.63613 14.864C8.00013 13.229 6.22213 9.933 7.36013 8.795L8.49813 7.657C8.70813 7.447 9.04713 7.447 9.25613 7.657Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default WhatsappIcon;
