import { FC } from "react";

interface AngleRightProps {
  className?: string;
}

export const AngleRight: FC<AngleRightProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="a/ic_angle_right">
        <path
          id="color&#240;&#159;&#142;&#168;"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.40822 14.5C5.58841 14.5 5.76859 14.4304 5.90584 14.2919L11.1263 9.02017C11.4015 8.74313 11.4015 8.29346 11.1263 8.0157L5.86713 2.70778C5.59263 2.43074 5.1471 2.43074 4.87189 2.70778C4.59739 2.98554 4.59739 3.43521 4.87189 3.71225L9.63342 8.51794L4.9106 13.2874C4.6354 13.5651 4.6354 14.0148 4.9106 14.2919C5.04785 14.4304 5.22804 14.5 5.40822 14.5Z"
          fill="#868F9E"
        />
      </g>
    </svg>
  );
};

export default AngleRight;
