import { FC } from "react";

interface BillIconProps {
  className?: string;
  color?: string;
}

export const BillIcon: FC<BillIconProps> = ({ className, color }) => {
  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.99935 23.9995C3.26202 23.9995 2.66602 23.4035 2.66602 22.6662V9.33284C2.66602 8.59685 3.26202 7.99951 3.99935 7.99951H27.9993C28.7367 7.99951 29.3327 8.59685 29.3327 9.33284V22.6662C29.3327 23.4035 28.7367 23.9995 27.9993 23.9995L25.3327 24H22.666L3.99935 23.9995ZM23.2211 10.6674H8.77765C8.10573 12.2048 6.87075 13.4398 5.33339 14.1117V17.8884C6.87075 18.5603 8.10573 19.7953 8.77765 21.3327H23.2211C23.8932 19.7949 25.1287 18.5596 26.6666 17.8878V14.1123C25.1287 13.4405 23.8932 12.2052 23.2211 10.6674ZM15.9993 11.9995C18.2087 11.9995 19.9993 13.7915 19.9993 15.9995C19.9993 18.2088 18.2087 19.9995 15.9993 19.9995C13.79 19.9995 11.9993 18.2088 11.9993 15.9995C11.9993 13.7915 13.79 11.9995 15.9993 11.9995ZM15.9993 14.6662C15.2633 14.6662 14.666 15.2635 14.666 15.9995C14.666 16.7355 15.2633 17.3328 15.9993 17.3328C16.7353 17.3328 17.3327 16.7355 17.3327 15.9995C17.3327 15.2635 16.7353 14.6662 15.9993 14.6662Z"
        fill={color ?? "#1F9183"}
      />
    </svg>
  );
};

export default BillIcon;
