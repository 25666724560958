import { FC } from "react";

interface HandCardMoneyProps {
  className?: string;
}

export const HandCardMoney: FC<HandCardMoneyProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="66"
      height="66"
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_16182_4968)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M57.723 48.2315C57.4752 48.6303 57.2197 49.0253 56.9564 49.4125C56.6118 49.9159 56.2517 50.4077 55.8723 50.8879C55.0437 51.9489 54.1416 52.9479 53.1775 53.8811C50.0647 56.8899 46.282 59.2093 42.0734 60.5956C41.0358 60.9364 39.9672 61.2229 38.8792 61.4436C36.9782 61.8347 35.0113 62.04 32.9942 62.04C29.467 62.04 26.087 61.4127 22.9625 60.2587C16.8141 57.9935 11.6415 53.7069 8.25372 48.2044C5.53189 43.7861 3.95996 38.5741 3.95996 33.0019C3.95996 16.9591 16.9613 3.95996 32.998 3.95996C46.5995 3.95996 58.0172 13.3115 61.1727 25.9428C61.2617 26.3029 61.3469 26.6669 61.4244 27.0309C61.827 28.9593 62.04 30.9535 62.04 33.0019C62.04 38.5896 60.4642 43.8055 57.7268 48.2354L57.723 48.2315Z"
          fill="#8882F5"
        />
        <mask
          id="mask0_16182_4968"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="3"
          y="3"
          width="60"
          height="60"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M57.723 48.2316C57.4752 48.6305 57.2197 49.0254 56.9564 49.4126C56.6118 49.916 56.2517 50.4078 55.8723 50.888C55.0437 51.949 54.1416 52.948 53.1775 53.8812C50.0647 56.89 46.282 59.2095 42.0734 60.5957C41.0358 60.9365 39.9672 61.223 38.8792 61.4438C36.9782 61.8349 35.0113 62.0401 32.9942 62.0401C29.467 62.0401 26.087 61.4128 22.9625 60.2589C16.8141 57.9936 11.6415 53.707 8.25372 48.2045C5.53189 43.7863 3.95996 38.5742 3.95996 33.002C3.95996 16.9593 16.9613 3.96008 32.998 3.96008C46.5995 3.96008 58.0172 13.3116 61.1727 25.9429C61.2617 26.303 61.3469 26.667 61.4244 27.031C61.827 28.9594 62.04 30.9536 62.04 33.002C62.04 38.5897 60.4642 43.8056 57.7268 48.2355L57.723 48.2316Z"
            fill="#8882F5"
          />
        </mask>
        <g mask="url(#mask0_16182_4968)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M57.695 61.1862C43.2695 52.6604 40.4684 47.5027 40.1346 35.237C40.0079 33.4217 39.5455 31.9542 38.112 31.6402C36.6155 31.3119 35.4999 31.8441 34.9637 33.2013C34.8455 33.5002 33.4966 36.6082 34.338 41.9392C31.6742 40.307 29.7475 38.0112 29.5348 33.9796C33.0611 33.1389 34.538 32.0781 34.9671 31.7046C34.9671 31.7046 37.2315 29.9059 35.5362 27.7168C35.3871 27.5499 35.2278 27.4224 35.0646 27.3118C35.9589 26.5333 36.4494 25.756 36.5981 25.5496C37.4545 24.3637 37.0827 22.6669 35.8227 21.7997C34.561 20.9332 32.7814 21.1442 31.9236 22.329C30.4103 24.4206 27.2659 28.0757 25.2737 28.9764C24.9322 29.1315 24.635 29.3021 24.3576 29.4787C21.7223 29.9932 19.8408 31.7515 19.3771 34.1939C18.9199 34.4018 18.4836 34.6676 18.0791 35.0042C16.1378 36.6213 15.9382 38.7031 16.1565 40.3239C16.1639 40.3766 16.1709 40.4286 16.1793 40.4804C14.0813 43.9313 17.3535 50.8148 17.3535 50.8148C21.0803 58.6754 23.5311 62.4297 27.6632 68.5552C41.1692 73.8919 51.6925 68.8283 51.6925 68.8283"
            fill="#FFC8E9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M34.5213 41.7433C34.6303 41.7729 34.6952 41.8841 34.6663 41.9914L34.5626 42.3987L34.4392 42.9166L34.3115 43.4868C34.0666 44.6183 33.8646 45.7665 33.7366 46.8607C33.5922 48.0932 33.5517 49.1783 33.6358 50.0637C33.6463 50.1747 33.564 50.2722 33.4516 50.2815C33.3395 50.2908 33.2399 50.2081 33.2294 50.0972C33.1422 49.1795 33.1837 48.0681 33.3311 46.81C33.4608 45.7017 33.6649 44.5414 33.9124 43.3983L34.0412 42.8224L34.2185 42.0892L34.2718 41.8839C34.3006 41.7765 34.4123 41.7136 34.5213 41.7433Z"
            fill="#003CE5"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.0166 34.5766C20.4914 31.9043 22.5907 29.9905 25.4956 29.5826C30.2422 28.9154 31.8308 27.7694 31.8459 27.7579C31.7823 27.805 31.7554 27.8331 31.7554 27.8331C31.7554 27.8331 34.4346 25.9743 36.0396 27.8115C37.692 29.9982 35.442 31.8286 35.442 31.8286C34.8858 32.3223 32.2728 34.0671 26.036 34.9446"
            fill="#FFC8E9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29.7972 34.0217C29.9028 33.9951 30.0116 34.058 30.0402 34.1624C30.0687 34.2668 30.0064 34.373 29.9008 34.3996C28.7401 34.6931 27.4639 34.9416 26.0668 35.1379C25.9588 35.1532 25.8574 35.079 25.8404 34.9724C25.8235 34.8657 25.8974 34.767 26.0055 34.7519C27.3885 34.5573 28.6507 34.3116 29.7972 34.0217Z"
            fill="#003CE5"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M35.5422 27.119C35.6354 27.1766 35.6643 27.2972 35.607 27.3881C35.5496 27.4791 35.4277 27.5059 35.3346 27.4482C34.5196 26.9427 33.7639 27.009 32.7606 27.5083L32.5192 27.6342L31.6661 28.1063L31.3451 28.2771L31.0309 28.4352C30.4062 28.7394 29.7941 28.9739 29.0348 29.1727L28.6422 29.2692C28.5744 29.2848 28.5056 29.3003 28.4353 29.3156L27.9975 29.4049C27.846 29.4341 27.6883 29.4627 27.5241 29.4907L27.0106 29.5739L26.4527 29.6554L25.8457 29.7362C25.7401 29.7495 25.6324 29.763 25.5225 29.7765C25.414 29.7897 25.3141 29.7138 25.2991 29.6069C25.2841 29.5 25.3598 29.4026 25.4681 29.3893L26.0915 29.3099L26.6637 29.2306L27.1892 29.1502L27.6725 29.0678L28.1179 28.9826C28.6886 28.8667 29.1702 28.7405 29.6103 28.5942L29.9334 28.4804C29.9862 28.4608 30.0385 28.4407 30.0905 28.4204L30.3985 28.2935C30.4493 28.2715 30.5 28.2491 30.5505 28.2262L30.8535 28.084L31.1587 27.9304C31.2099 27.9037 31.2616 27.8767 31.3135 27.849L31.7651 27.6033L32.3888 27.2569C33.595 26.6154 34.5302 26.4913 35.5422 27.119Z"
            fill="#003CE5"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M28.1646 44.12C28.7573 44.1709 29.6156 44.4437 30.4939 43.8134C31.6982 42.9483 32.1273 41.3777 31.2004 40.1052C30.542 39.2013 27.1952 34.568 23.1778 34.0132C21.5403 33.7874 20.0101 34.2341 18.7546 35.3041L21.3501 41.2222L28.1646 44.12Z"
            fill="#FFC8E9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.2025 33.8199C23.9346 33.9211 24.6694 34.1562 25.4045 34.5123C26.8498 35.2127 28.2353 36.343 29.5383 37.754C29.9537 38.2037 30.3399 38.66 30.7025 39.12L31.0268 39.5402L31.2391 39.8265C31.2774 39.8786 31.3175 39.9336 31.3608 39.9929C32.3243 41.3158 31.9838 42.9301 30.6161 43.9685C29.8248 44.5688 28.8955 44.6177 28.0931 44.3007C27.9911 44.2604 27.9405 44.1468 27.98 44.047C28.0195 43.947 28.1341 43.8987 28.236 43.939C28.9175 44.2082 29.7011 44.1669 30.3715 43.6582C31.5802 42.7406 31.8716 41.359 31.0401 40.2173L30.8118 39.906L30.6099 39.638L30.3911 39.3564C30.0346 38.9043 29.655 38.4557 29.2471 38.0142C27.9753 36.6371 26.6268 35.5368 25.2337 34.8619C24.5353 34.5235 23.8407 34.3012 23.1531 34.2062C22.24 34.0808 21.3609 34.1707 20.5442 34.4712C20.4422 34.5087 20.3275 34.4573 20.2879 34.3563C20.2484 34.2554 20.299 34.1431 20.401 34.1056C21.2796 33.7824 22.2249 33.6855 23.2025 33.8199Z"
            fill="#003CE5"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.9235 40.6614C17.6079 39.6179 18.6762 39.0015 19.9322 38.9263C22.945 38.7448 26.3839 42.102 28.1644 44.12C29.1578 45.2441 29.0478 46.9331 27.9196 47.8915C26.7916 48.8494 25.3989 48.8079 24.0795 47.5913C23.2208 46.678 22.2085 45.6607 21.6708 45.2766C21.133 44.8931 20.261 45.5206 20.7702 46.2553"
            fill="#FFC8E9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.9172 38.7317C22.4879 38.577 25.4437 40.7428 28.3136 43.9935C29.3788 45.1994 29.261 47.012 28.0501 48.04C26.8591 49.052 25.4115 48.9952 24.0913 47.8628L23.9504 47.7373C23.6652 47.4919 23.3753 47.2239 23.0638 46.9208L21.7982 45.6576L21.5921 45.4599C21.1704 45.0817 20.5706 45.6239 20.9333 46.1469C20.9954 46.2364 20.9727 46.3577 20.8827 46.4175C20.7925 46.4773 20.6693 46.4534 20.6072 46.3638C19.9837 45.4644 21.0781 44.4752 21.8563 45.1731L22.0687 45.3764L23.3401 46.6452C23.5695 46.8685 23.7866 47.0721 24.0007 47.2629L24.2138 47.4499C25.4322 48.574 26.713 48.6574 27.7891 47.743C28.8351 46.8549 28.9368 45.2891 28.0162 44.2471C25.228 41.0888 22.3456 38.9767 19.9469 39.121C18.7863 39.1907 17.7964 39.7446 17.1446 40.6887C17.0835 40.7772 16.9604 40.7991 16.8697 40.7377C16.7791 40.6762 16.7551 40.5546 16.8161 40.4662C17.5357 39.4241 18.6356 38.8086 19.9172 38.7317Z"
            fill="#003CE5"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M47.4887 36.5956C47.4978 36.4973 47.555 35.6189 46.619 35.5193C46.4615 35.5025 46.3476 35.3611 46.3643 35.2036C46.3811 35.0461 46.5223 34.9316 46.6798 34.9483C47.6146 35.0478 47.7444 34.1793 47.7565 34.0783C47.7738 33.9214 47.9146 33.8077 48.0719 33.8244C48.2281 33.841 48.3412 33.9796 48.3274 34.135L48.3271 34.1382C48.3173 34.2403 48.2614 35.1167 49.196 35.2161C49.3535 35.2329 49.468 35.3746 49.4512 35.5321C49.4345 35.6896 49.2928 35.8038 49.1352 35.7871C48.1901 35.6865 48.0697 36.5568 48.0591 36.6563C48.0424 36.8138 47.9009 36.9281 47.7434 36.9113C47.5859 36.8945 47.4719 36.7531 47.4887 36.5956Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.9887 32.6967C11.9941 32.638 12.0283 32.1141 11.47 32.0546C11.376 32.0447 11.308 31.9603 11.318 31.8663C11.328 31.7724 11.4123 31.7041 11.5062 31.7141C12.0639 31.7734 12.1413 31.2553 12.1485 31.1951C12.1588 31.1015 12.2428 31.0337 12.3366 31.0436C12.4298 31.0536 12.4973 31.1362 12.489 31.2289L12.4888 31.2308C12.483 31.2917 12.4497 31.8145 13.0071 31.8738C13.1011 31.8838 13.1694 31.9683 13.1594 32.0623C13.1494 32.1562 13.0649 32.2244 12.9709 32.2144C12.4071 32.1544 12.3353 32.6735 12.329 32.7329C12.319 32.8268 12.2346 32.895 12.1407 32.885C12.0467 32.875 11.9787 32.7906 11.9887 32.6967Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M46.9687 13.6311C46.9741 13.5725 47.0082 13.0485 46.45 12.9891C46.356 12.9791 46.288 12.8947 46.298 12.8008C46.308 12.7068 46.3923 12.6385 46.4862 12.6485C47.0438 12.7079 47.1213 12.1898 47.1285 12.1296C47.1388 12.036 47.2228 11.9681 47.3166 11.9781C47.4097 11.988 47.4773 12.0707 47.469 12.1634L47.4688 12.1653C47.463 12.2262 47.4296 12.7489 47.9871 12.8083C48.0811 12.8183 48.1494 12.9028 48.1394 12.9967C48.1294 13.0907 48.0448 13.1588 47.9509 13.1488C47.3871 13.0888 47.3153 13.608 47.309 13.6673C47.299 13.7613 47.2146 13.8294 47.1206 13.8194C47.0267 13.8094 46.9587 13.7251 46.9687 13.6311Z"
            fill="white"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.3426 21.3962L56.5662 17.9742C57.6192 17.786 58.8051 18.6755 59.0586 19.7789L61.1397 28.842C61.3932 29.9454 60.6181 30.8451 59.5648 31.0328L40.9864 34.5777C39.9331 34.7654 38.874 34.0236 38.6209 32.9199L36.5398 23.8568C36.2865 22.7538 36.9345 21.7068 37.9875 21.5186"
          fill="black"
          fillOpacity="0.1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.8667 20.107L58.3051 15.9273C59.4246 15.6976 60.6463 16.5647 60.8751 17.6835L62.7531 26.8733C62.9819 27.9922 62.1308 28.9393 61.0109 29.1685L41.25 33.4509C40.1302 33.6801 39.0372 32.9594 38.8088 31.8403L36.9309 22.6505C36.7024 21.5321 37.4243 20.4389 38.5439 20.2092"
          fill="#48CAD4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.6567 20.7747L57.6428 17.087C58.5944 16.8918 59.5231 17.5046 59.7176 18.4556L61.3138 26.267C61.5083 27.218 60.8946 28.1472 59.9428 28.3421L41.9569 32.0302C41.0051 32.225 40.076 31.6125 39.8819 30.6612L38.2857 22.8498C38.0914 21.8992 38.7051 20.97 39.6567 20.7747Z"
          fill="#8AE7F2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M48.6766 18.895C48.3898 28.8142 60.7175 28.1657 59.7652 28.3586L42.0125 32.1201C41.0601 32.313 40.1283 31.6954 39.9313 30.7399L38.3111 22.8938C38.114 21.939 38.7257 21.0079 39.6778 20.8146C39.6778 20.8146 45.73 19.4928 48.6766 18.895Z"
          fill="#A2EBE2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M46.7099 25.1654C46.3177 23.2493 47.5534 21.3773 49.4695 20.9851C51.386 20.5927 53.2577 21.8279 53.6498 23.7447C54.0422 25.6612 52.8067 27.5325 50.8902 27.925C48.9737 28.3174 47.1021 27.0815 46.7099 25.1654Z"
          fill="#48CAD4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M56.0118 22.2448L57.5017 21.94C57.9585 21.8463 58.405 22.141 58.4983 22.5981L58.5185 22.6959C58.6118 23.1529 58.3174 23.5991 57.8606 23.6928L56.3707 23.9976C55.9136 24.0909 55.4674 23.7965 55.3738 23.3397L55.3536 23.2413C55.2604 22.7843 55.555 22.3384 56.0118 22.2448Z"
          fill="#48CAD4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.35 20.7514L49.5463 9.29757C50.4332 8.66949 51.8718 8.99092 52.5442 9.91263L58.0652 17.4838C58.7375 18.4055 58.3887 19.5788 57.5013 20.2066L41.9435 31.498C41.0562 32.1257 39.7922 31.8878 39.1202 30.9656L33.5992 23.3945C32.9273 22.4731 33.1011 21.2167 33.988 20.5887"
          fill="black"
          fillOpacity="0.1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.3141 19.3221L50.3176 6.63877C51.2487 5.94332 52.7112 6.22842 53.3671 7.17515L58.7532 14.9519C59.4091 15.8987 59.0097 17.1489 58.0782 17.844L41.7346 30.3322C40.803 31.0273 39.5165 30.8237 38.861 29.8765L33.4749 22.0997C32.8195 21.1533 33.0425 19.8221 33.9736 19.1266"
          fill="#48CAD4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.2161 19.1769L50.1757 8.00653C50.9671 7.4154 52.0604 7.58887 52.6179 8.3936L57.196 15.0039C57.7535 15.8086 57.564 16.9401 56.7721 17.531L41.8129 28.7016C41.0211 29.2925 39.9276 29.1194 39.3704 28.3143L34.7922 21.704C34.2351 20.8995 34.4247 19.768 35.2161 19.1769Z"
          fill="#8AE7F2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M42.6113 13.733C46.3108 23.1209 57.4098 17.0335 56.6167 17.6228L41.8998 28.7618C41.1067 29.351 40.0087 29.1745 39.4471 28.3667L34.8333 21.7342C34.2719 20.9271 34.4589 19.7947 35.2515 19.2051C35.2515 19.2051 40.158 15.557 42.6113 13.733Z"
          fill="#A2EBE2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.4136 20.2481C42.2901 18.6269 42.6713 16.3476 44.2653 15.1583C45.8595 13.9685 48.0629 14.3182 49.1866 15.9401C50.3106 17.5616 49.9292 19.8402 48.335 21.0299C46.7407 22.2197 44.5372 21.8693 43.4136 20.2481Z"
          fill="#48CAD4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50.7457 13.5247L51.9852 12.6C52.3651 12.3163 52.8907 12.3998 53.1585 12.7866L53.216 12.8692C53.4838 13.256 53.3931 13.7993 53.0131 14.083L51.7736 15.0077C51.3933 15.2911 50.8681 15.2079 50.6001 14.8214L50.5424 14.7382C50.2746 14.3513 50.3657 13.8084 50.7457 13.5247Z"
          fill="#48CAD4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.5486 23.9816L40.7769 6.12912C41.2818 5.15079 42.7112 4.73523 43.7306 5.21536L52.1025 9.16007C53.1219 9.6402 53.3451 10.8346 52.8396 11.8129L44.1068 29.2143C43.6013 30.1926 42.3658 30.5965 41.3464 30.1159L32.9745 26.1711C31.9557 25.6911 31.5386 24.5088 32.0435 23.5305"
          fill="black"
          fillOpacity="0.1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.4943 22.0068L40.2539 3.43176C40.7676 2.37312 42.2017 1.91426 43.2179 2.42422L51.563 6.61401C52.5791 7.12396 52.7923 8.41005 52.2781 9.46863L43.3896 28.2965C42.8753 29.3551 41.6353 29.7999 40.6192 29.2894L32.2741 25.0996C31.2585 24.5897 30.8511 23.3182 31.3648 22.2596"
          fill="#48CAD4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.7502 4.69597C41.1869 3.79613 42.2408 3.41849 43.1045 3.85195L50.1979 7.41327C51.0616 7.84673 51.4079 8.92752 50.9708 9.82731L42.7169 26.8333C42.2798 27.7331 41.2257 28.1113 40.3621 27.6773L33.2687 24.116C32.4055 23.6826 32.0592 22.6018 32.4958 21.7019L40.7502 4.69597Z"
          fill="#8AE7F2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.6119 13.3634C44.1867 19.7779 51.3129 9.08381 50.874 9.98279L42.8217 26.8439C42.3828 27.7429 41.3232 28.1201 40.4544 27.6859L33.3191 24.1225C32.4508 23.6888 32.1019 22.6085 32.5403 21.7095C32.5403 21.7095 35.2547 16.1455 36.6119 13.3634Z"
          fill="#A2EBE2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.7361 13.8084C39.6161 11.9967 41.7404 11.2356 43.4812 12.1092C45.2221 12.9823 45.9196 15.1586 45.0397 16.9703C44.1597 18.7821 42.035 19.5426 40.2946 18.6695C38.5542 17.7965 37.8562 15.6197 38.7361 13.8084Z"
          fill="#48CAD4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.7716 9.2432L44.4559 7.83486C44.6655 7.40295 45.1724 7.22147 45.5873 7.42996L45.6763 7.47429C46.0912 7.68278 46.2577 8.20149 46.0481 8.63341L45.3638 10.0417C45.1537 10.4736 44.6473 10.6551 44.2323 10.4471L44.1429 10.4023C43.728 10.1938 43.562 9.67511 43.7716 9.2432Z"
          fill="#48CAD4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.4623 22.2321L38.1466 20.8237C38.3563 20.3913 38.8626 20.2103 39.2776 20.4188L39.367 20.4632C39.782 20.6716 39.948 21.1903 39.7384 21.6222L39.054 23.0306C38.8444 23.4625 38.3381 23.644 37.9231 23.4355L37.8337 23.3906C37.4187 23.1826 37.2527 22.664 37.4623 22.2321Z"
          fill="#48CAD4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.9876 41.3933L14.4741 14.9982C13.9162 14.1062 14.182 12.93 15.0683 12.3706L30.5157 2.62447C31.4018 2.06551 32.5724 2.33532 33.1307 3.22759L49.644 29.622C50.2021 30.5144 49.9363 31.6909 49.0502 32.2499L33.6026 41.9961C32.7165 42.5554 31.5458 42.2853 30.9876 41.3933Z"
          fill="#3749EF"
        />
        <mask
          id="mask1_16182_4968"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="14"
          y="2"
          width="37"
          height="41"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M50.1081 29.3308L33.569 2.93081C33.0186 2.05228 31.8644 1.78684 30.9914 2.33826L15.4515 12.1567C14.5786 12.7084 14.317 13.8678 14.8678 14.7466L31.4071 41.1463C31.9576 42.0254 33.1115 42.2906 33.9844 41.7389L49.5244 31.9207C50.1995 31.4943 50.5086 30.7044 50.3596 29.9616C50.316 29.744 50.2328 29.53 50.1081 29.3308Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask1_16182_4968)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.335 46.9962L10.9166 12.8086L33.0022 -1.14533L54.4206 33.0423L32.335 46.9962Z"
            fill="#005CFF"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.4803 21.2489L28.106 17.4587C27.9163 17.156 28.0061 16.757 28.3066 16.5673L29.7954 15.6268C30.0959 15.4371 30.493 15.5283 30.6826 15.8307L33.0569 19.6209C33.2463 19.9234 33.1564 20.3224 32.856 20.5121L31.3672 21.4529C31.0671 21.6426 30.6696 21.5511 30.4803 21.2489Z"
          fill="#0B7EFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.4251 14.5966L24.0508 10.8064C23.8611 10.5037 23.951 10.1047 24.2514 9.91498L25.7402 8.97443C26.0407 8.78473 26.4378 8.87595 26.6274 9.17839L29.0018 12.9686C29.1911 13.2711 29.1013 13.67 28.8008 13.8597L27.3121 14.8006C27.0119 14.9902 26.6144 14.8988 26.4251 14.5966Z"
          fill="#0B7EFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.7027 27.9883L32.3281 24.1982C32.1387 23.8954 32.2285 23.4964 32.529 23.3067L34.0178 22.3664C34.3183 22.1764 34.7153 22.2677 34.9047 22.5702L37.2793 26.3603C37.4687 26.6628 37.3788 27.0618 37.0783 27.2515L35.5896 28.1923C35.2894 28.3819 34.8921 28.2908 34.7027 27.9883Z"
          fill="#0B7EFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.9249 34.7279L36.5505 30.9377C36.3609 30.6353 36.4507 30.2363 36.7512 30.0463L38.24 29.1061C38.5404 28.9161 38.9375 29.0073 39.1272 29.3097L41.5016 33.1002C41.6909 33.4024 41.601 33.8014 41.3006 33.9914L39.8118 34.9319C39.5116 35.1216 39.1142 35.0304 38.9249 34.7279Z"
          fill="#0B7EFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.8267 21.8052L18.5086 16.509C18.2589 16.1106 18.3776 15.5847 18.7736 15.3344L19.9708 14.5778C20.3668 14.3276 20.8902 14.448 21.1399 14.8464L24.458 20.1426C24.7075 20.5413 24.5891 21.0672 24.1934 21.3174L22.9956 22.0741C22.5998 22.324 22.0765 22.2039 21.8267 21.8052Z"
          fill="#0B7EFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.0166 34.5765C20.4914 31.9042 22.5907 29.9904 25.4956 29.5825C30.2422 28.9153 31.8308 27.7693 31.8459 27.7577C31.7823 27.8049 31.7554 27.8329 31.7554 27.8329C31.7554 27.8329 34.4346 25.9742 36.0396 27.8114C37.692 29.9981 35.442 31.8285 35.442 31.8285C34.8858 32.3221 32.2728 34.067 26.036 34.9445"
          fill="#FFC8E9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.7972 34.0216C29.9028 33.9949 30.0116 34.0579 30.0402 34.1622C30.0687 34.2666 30.0064 34.3728 29.9008 34.3995C28.7401 34.693 27.4639 34.9414 26.0668 35.1378C25.9588 35.1531 25.8574 35.0788 25.8404 34.9723C25.8235 34.8656 25.8974 34.7668 26.0055 34.7517C27.3885 34.5572 28.6507 34.3115 29.7972 34.0216Z"
          fill="#003CE5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.5422 27.1187C35.6354 27.1764 35.6643 27.297 35.607 27.3878C35.5496 27.4788 35.4277 27.5056 35.3346 27.448C34.5196 26.9425 33.7639 27.0087 32.7606 27.5081L32.5192 27.6339L31.6661 28.1061L31.3451 28.2769L31.0309 28.4349C30.4062 28.7392 29.7941 28.9736 29.0348 29.1724L28.6422 29.269C28.5744 29.2846 28.5056 29.3001 28.4353 29.3153L27.9975 29.4046C27.846 29.4339 27.6883 29.4624 27.5241 29.4905L27.0106 29.5737L26.4527 29.6552L25.8457 29.7359C25.7401 29.7493 25.6324 29.7627 25.5225 29.7763C25.414 29.7895 25.3141 29.7136 25.2991 29.6067C25.2841 29.4997 25.3598 29.4023 25.4681 29.3891L26.0915 29.3097L26.6637 29.2303L27.1892 29.15L27.6725 29.0675L28.1179 28.9824C28.6886 28.8664 29.1702 28.7403 29.6103 28.5939L29.9334 28.4801C29.9862 28.4606 30.0385 28.4404 30.0905 28.4202L30.3985 28.2932C30.4493 28.2713 30.5 28.2489 30.5505 28.226L30.8535 28.0837L31.1587 27.9301C31.2099 27.9035 31.2616 27.8764 31.3135 27.8488L31.7651 27.6031L32.3888 27.2567C33.595 26.6152 34.5302 26.491 35.5422 27.1187Z"
          fill="#003CE5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.1646 44.1199C28.7573 44.1708 29.6156 44.4436 30.4939 43.8133C31.6982 42.9482 32.1273 41.3776 31.2004 40.1051C30.542 39.2012 27.1952 34.5679 23.1778 34.013C21.5403 33.7872 20.0101 34.2339 18.7546 35.304L21.3501 41.222L28.1646 44.1199Z"
          fill="#FFC8E9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.2027 33.8198C23.9349 33.921 24.6697 34.1561 25.4047 34.5122C26.8501 35.2125 28.2355 36.3429 29.5385 37.7538C29.9539 38.2036 30.3401 38.6599 30.7027 39.1199L31.027 39.5401L31.2394 39.8264C31.2776 39.8784 31.3178 39.9335 31.361 39.9928C32.3246 41.3157 31.9841 42.93 30.6163 43.9684C29.8251 44.5687 28.8957 44.6176 28.0934 44.3006C27.9914 44.2603 27.9408 44.1467 27.9802 44.0468C28.0197 43.9469 28.1343 43.8986 28.2363 43.9388C28.9177 44.2081 29.7013 44.1668 30.3718 43.6581C31.5805 42.7405 31.8719 41.3588 31.0404 40.2172L30.812 39.9059L30.6102 39.6378L30.3913 39.3563C30.0348 38.9042 29.6552 38.4556 29.2473 38.0141C27.9755 36.637 26.627 35.5366 25.234 34.8618C24.5356 34.5234 23.841 34.3011 23.1533 34.2061C22.2403 34.0807 21.3612 34.1706 20.5444 34.4711C20.4424 34.5086 20.3277 34.4572 20.2882 34.3562C20.2486 34.2553 20.2992 34.143 20.4012 34.1055C21.2798 33.7822 22.2251 33.6854 23.2027 33.8198Z"
          fill="#003CE5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.9235 40.6612C17.6079 39.6176 18.6762 39.0013 19.9322 38.926C22.945 38.7446 26.3839 42.1018 28.1644 44.1198C29.1578 45.2439 29.0478 46.9329 27.9196 47.8913C26.7916 48.8491 25.3989 48.8077 24.0795 47.5911C23.2208 46.6777 22.2085 45.6604 21.6708 45.2764C21.133 44.8929 20.261 45.5204 20.7702 46.255"
          fill="#FFC8E9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.9172 38.7315C22.4879 38.5769 25.4437 40.7427 28.3136 43.9933C29.3788 45.1993 29.261 47.0118 28.0501 48.0398C26.8591 49.0519 25.4115 48.9951 24.0913 47.8627L23.9504 47.7372C23.6652 47.4918 23.3753 47.2237 23.0638 46.9207L21.7982 45.6575L21.5921 45.4598C21.1704 45.0816 20.5706 45.6238 20.9333 46.1468C20.9954 46.2363 20.9727 46.3575 20.8827 46.4173C20.7925 46.4772 20.6693 46.4533 20.6072 46.3637C19.9837 45.4643 21.0781 44.4751 21.8563 45.173L22.0687 45.3763L23.3401 46.6451C23.5695 46.8684 23.7866 47.072 24.0007 47.2628L24.2138 47.4498C25.4322 48.5738 26.713 48.6572 27.7891 47.7429C28.8351 46.8548 28.9368 45.289 28.0162 44.247C25.228 41.0887 22.3456 38.9765 19.9469 39.1208C18.7863 39.1906 17.7964 39.7445 17.1446 40.6885C17.0835 40.7771 16.9604 40.7989 16.8697 40.7376C16.7791 40.6761 16.7551 40.5545 16.8161 40.4661C17.5357 39.424 18.6356 38.8085 19.9172 38.7315Z"
          fill="#003CE5"
        />
      </g>
      <defs>
        <clipPath id="clip0_16182_4968">
          <rect width="66" height="66" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HandCardMoney;
