import { logException } from "../utils/logger";

export interface ActionButton {
  icon?: string;
  title: string;
  description?: string;
  url: string;
}

export interface Tutorial {
  name: string;
  steps: TutorialStep[];
}

export interface TutorialStep {
  section: string;
  steps: SectionStep[];
}

export interface SectionStep {
  image: string;
  position: number;
  title: string;
  text: string;
}

export interface AnswerComponent {
  answerComponentId: string;
  answerComponentType: string;
}

export interface AnswerComponentResponse {
  ID: number;
  CreatedAt: string;
  UpdatedAt: string;
  DeletedAt: null | string;
  answerComponentId: string;
  answerComponentType: string;
  projectId: number;
  content: any;
}

export class AnswerComponentCollector {
  private actionButtons: ActionButton[] = [];
  private tutorials: Tutorial[] = [];

  addActionButton(button: ActionButton) {
    this.actionButtons.push(button);
    return this.actionButtons;
  }

  addTutorial(tutorial: Tutorial) {
    this.tutorials.push(tutorial);
    return this.tutorials;
  }

  getActionButtons() {
    return this.actionButtons;
  }

  getTutorials() {
    return this.tutorials;
  }

  getAnswerComponents() {
    return {
      action_buttons: this.actionButtons,
      tutorials: this.tutorials,
    };
  }

  clearAnswerComponents() {
    this.actionButtons = [];
    this.tutorials = [];
  }
}

export class AnswerComponentsService {
  static fetchedAnswerComponents: Map<string, AnswerComponentResponse> =
    new Map();
  static attemptCount: Map<string, number> = new Map();

  static replaceUserQuery(
    text: string | undefined,
    query: string,
    shouldEncode = false,
  ): string {
    if (!text) return "";
    if (!text.includes("<user-query>")) return text;

    const replacement = shouldEncode ? encodeURIComponent(query) : query;
    return text.replace("<user-query>", replacement);
  }

  static async fetchAnswerComponent(
    componentId: string,
    attempt = 1,
    maxAttempts = 5,
    baseDelay = 200,
  ): Promise<AnswerComponentResponse> {
    const cachedAnswerComponent = this.fetchedAnswerComponents.get(componentId);
    if (cachedAnswerComponent) {
      return cachedAnswerComponent;
    }

    const currentAttempt = this.attemptCount.get(componentId) || 0;
    if (currentAttempt >= maxAttempts) {
      logException(
        `Maximum attempts (${maxAttempts}) reached for component ${componentId}`,
      );
      throw new Error(`Maximum attempts reached for component ${componentId}`);
    }

    this.attemptCount.set(componentId, currentAttempt + 1);

    try {
      const url = `${
        import.meta.env.VITE_SEARCH_SERVICE_URL
      }/answerComponents/${componentId}`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${import.meta.env.VITE_SEARCH_SERVICE_KEY}`,
        },
      });

      if (!response.ok) {
        throw new Error(
          `Failed to fetch answer component: ${response.statusText}`,
        );
      }

      const answerComponentData = await response.json();
      this.fetchedAnswerComponents.set(componentId, answerComponentData);
      return answerComponentData;
    } catch (error) {
      const delay = baseDelay * Math.pow(2, currentAttempt);
      await new Promise((resolve) => setTimeout(resolve, delay));

      return this.fetchAnswerComponent(
        componentId,
        attempt + 1,
        maxAttempts,
        baseDelay,
      );
    }
  }

  static async handleAnswerComponents(
    answerComponents: AnswerComponent[],
    query: string,
    collector: AnswerComponentCollector,
    actionButtonsCallback: (actionButtons: ActionButton[]) => void,
    tutorialsCallback: (tutorials: Tutorial[]) => void,
  ) {
    console.log("fetchedAnswerComponents", this.fetchedAnswerComponents);
    for (const ac of answerComponents) {
      if (ac.answerComponentId && ac.answerComponentType) {
        try {
          if (!this.fetchedAnswerComponents.has(ac.answerComponentId)) {
            const acData = await this.fetchAnswerComponent(
              ac.answerComponentId,
            );

            if (!acData.content) continue;

            // collector.clearAnswerComponents();

            switch (acData.answerComponentType) {
              case "actionButton": {
                const actionButton: ActionButton = {
                  icon: acData.content.icon,
                  title: acData.content.title,
                  description: this.replaceUserQuery(
                    acData.content.description,
                    query,
                  ),
                  url: this.replaceUserQuery(acData.content.url, query, true),
                };
                collector.addActionButton(actionButton);
                actionButtonsCallback(collector.getActionButtons());
                break;
              }
              case "tutorial": {
                const tutorial: Tutorial = {
                  name: acData.content.name,
                  steps: acData.content.steps,
                };

                if (tutorial.steps[0].steps[0].image === null) {
                  break;
                }
                collector.addTutorial(tutorial);
                tutorialsCallback(collector.getTutorials());
              }
            }
          }
        } catch (error) {
          console.error("Error processing component:", error);
        }
      }
    }
  }
}
